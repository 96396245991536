/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import Brushaway from '@components/Brushaway';
// import Accordion from '@components/Accordion';
import InputTextArea, { StyleVariant } from '@components/InputTextArea/InputTextArea';
import Select from '@components/Select/Select';
import { Controller, useForm } from 'react-hook-form';
import { translate } from '@utils/i18n';
import { AIDeficiencyReviewStatus, IPDeficiencyStatus } from '../types';
import DeficiencyInfo from './DeficiencyInfo';
import { namespace, StatusIcons } from '../constants';
import { snakeToCapitalize } from '../utils/utils';
import BrushawaySteps from './BrushawaySteps';
// function NotesSection({ notes }: { notes: string }): JSX.Element {
//   return (
//     <section className="notesSection">
//       <Accordion
//         defaultExpanded
//         title={
//           <span className="header" id="work-order-visits">
//             {translate('notes', { namespace })}
//           </span>
//         }>
//         <p>{notes}</p>
//       </Accordion>
//     </section>
//   );
// }
export default function DeficiencyDetail({ deficiency, title, onClose, onChangeDeficiency }) {
    var _a, _b;
    const [isEditing, setIsEditing] = React.useState(false);
    const [initialDeficiency, setInitialDeficiency] = React.useState(deficiency);
    const { control, register, reset, getValues } = useForm({
        defaultValues: {
            description: (deficiency === null || deficiency === void 0 ? void 0 : deficiency.description) || '',
            status: deficiency === null || deficiency === void 0 ? void 0 : deficiency.status
        }
    });
    useEffect(() => {
        reset({
            description: (deficiency === null || deficiency === void 0 ? void 0 : deficiency.description) || '',
            status: deficiency === null || deficiency === void 0 ? void 0 : deficiency.status
        });
    }, [deficiency, reset]);
    useEffect(() => {
        setInitialDeficiency(deficiency);
        if (deficiency === null) {
            setIsEditing(false);
        }
    }, [deficiency]);
    const onChangeStatus = (newStatus) => {
        if (initialDeficiency === null)
            return;
        if (newStatus === AIDeficiencyReviewStatus.Removed) {
            onClose();
            setIsEditing(false);
        }
        void onChangeDeficiency(Object.assign(Object.assign({}, initialDeficiency), { reviewStatus: newStatus, description: getValues('description'), status: getValues('status') }));
    };
    const statuses = Object.keys(IPDeficiencyStatus).map((key) => ({
        label: snakeToCapitalize(IPDeficiencyStatus[key]),
        value: IPDeficiencyStatus[key]
    }));
    return (React.createElement(Brushaway, { open: deficiency !== null, disableCloseOnClickOutside: true, title: isEditing ? 'Edit Deficiency' : title || '', isForm: false, wrapperContentClassName: "deficiencyDetailBrushawayWrapper", onClose: () => {
            setIsEditing(false);
            onClose();
        } }, deficiency && (React.createElement("section", { className: "deficiencyDetailBrushaway" },
        !isEditing && React.createElement(DeficiencyInfo, { deficiency: deficiency }),
        isEditing && (React.createElement("section", { className: "deficiencyDetailBrushawayForm" },
            React.createElement(Controller, { name: "description", control: control, render: ({ field }) => {
                    return (React.createElement(InputTextArea, { id: "description", value: field.value, name: field.name, onChange: field.onChange, buttonControlled: false, variant: StyleVariant.Light, label: translate('description', { namespace }) }));
                } }),
            React.createElement(Select, Object.assign({}, register('status'), { label: translate('status', { namespace }), id: "status", options: statuses })))),
        React.createElement("section", { className: "deficiencyDetailBrushawayFooter" },
            React.createElement(BrushawaySteps, { deficiency: deficiency, onChangeStatus: onChangeStatus, onStartEditing: () => setIsEditing(true), onStopEditing: () => setIsEditing(false), isConfirming: false }),
            React.createElement("div", { className: "deficiencyStatus" },
                React.createElement("span", { className: "deficiencyStatusText" },
                    React.createElement("i", { className: StatusIcons[(_a = deficiency.reviewStatus) !== null && _a !== void 0 ? _a : ''] }),
                    snakeToCapitalize((_b = deficiency.reviewStatus) !== null && _b !== void 0 ? _b : ''))))))));
}
