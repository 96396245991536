import React, { useContext, useMemo } from 'react';
import { EventContext } from './EventContext';
import ResizeHandles from './ResizeHandles';
function EventVisit() {
    var _a, _b;
    const { event } = useContext(EventContext);
    const { clusterInfo, state, metadata, handleMouseDown } = useContext(EventContext);
    const dynamicStyles = useMemo(() => {
        let appendColor;
        switch (event.status.label) {
            case 'Scheduled':
                appendColor = '1f';
                break;
            case 'Started':
                appendColor = '3d';
                break;
            default:
                appendColor = '26';
        }
        return {
            left: `${metadata.startTimeAsPercentage}%`,
            width: `${metadata.endTimeAsPercentage - metadata.startTimeAsPercentage}%`,
            height: `${(100 / clusterInfo.subdivision) * clusterInfo.span}%`,
            top: `calc((${100 / clusterInfo.subdivision}% ) * ${clusterInfo.offset})`,
            borderTop: clusterInfo.offset === 0 ? 'unset' : 'transparent',
            '--custom-status-color': `${event.status.color}${appendColor}`
        };
    }, [event, clusterInfo, metadata]);
    const dynamicClass = useMemo(() => {
        const classes = ['event__item--custom'];
        if (state === 1 /* EventState.REPOSITIONING */)
            classes.push('--dragging');
        return classes.join('');
    }, [state]);
    return (React.createElement("div", { className: dynamicClass, style: dynamicStyles, onMouseDown: (e) => handleMouseDown(e.nativeEvent) },
        React.createElement("div", { className: "event__card" },
            React.createElement(ResizeHandles, null),
            React.createElement("div", { className: "event__crux" },
                React.createElement("span", { className: "event__type" },
                    React.createElement(Icon, { type: event.visitable.type })),
                React.createElement("span", { className: "event__time" }, event.start.format('hh:mm a')),
                React.createElement("span", { className: 'event__status' },
                    React.createElement("span", { className: " qmb-status" },
                        React.createElement("i", { title: event.status.label, className: event.status.icon, style: { color: event.status.color } })),
                    React.createElement("span", { style: { paddingLeft: '4px', color: event.status.color } }, event.status.label)),
                React.createElement("span", { className: "event__title" }, (_a = event.building) === null || _a === void 0 ? void 0 : _a.name)),
            React.createElement("div", { className: "event__context" }, (_b = event.building) === null || _b === void 0 ? void 0 : _b.address))));
}
function Icon({ type }) {
    switch (type) {
        case "WorkOrder" /* VisitableType.SERVICE */:
            return React.createElement("i", { className: "fa-light fa-screwdriver-wrench" });
        case "Inspection" /* VisitableType.INSPECTION */:
            return React.createElement("i", { className: "fa-light fa-clipboard-check" });
        default:
            return React.createElement("i", { className: "fa-light fa-square-question" });
    }
}
export default EventVisit;
