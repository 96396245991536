import React, { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import I18n, { translate } from '@utils/i18n';
import { IntlProvider, load } from '@progress/kendo-react-intl';
import weekData from 'cldr-core/supplemental/weekData.json';
import esCaGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import esDateFields from 'cldr-dates-full/main/es/dateFields.json';
import frCaGregorian from 'cldr-dates-full/main/fr/ca-gregorian.json';
import frDateFields from 'cldr-dates-full/main/fr/dateFields.json';
import api from '@utils/axios';
import { massUpdateInspectionsPath } from 'routes';

load(weekData, esCaGregorian, esDateFields, frCaGregorian, frDateFields); // to i18n DateTimePicker component

const namespace = 'features.inspections_index.mass_update_modal';
const dateTimeFormat = 'MM/dd/yyyy h:mm a';

function MassUpdateModal({
  inspectionIds,
  inspectionsCount,
  disabled,
  statusesForSelect,
  techniciansForSelect,
  queryParams,
  onDataChanged,
  inspectionSeriesId
}) {
  const [visible, setVisible] = useState(false);
  const defaultTech = { label: translate('technician_placeholder', { namespace }), value: '' };
  const defaultStatus = { label: translate('status_placeholder', { namespace }), value: '' };
  const [tech, setTech] = useState(defaultTech);
  const [status, setStatus] = useState(defaultStatus);
  const [scheduledDate, setScheduledDate] = useState();
  const [inspectionsProcessed, setInspectionsProcessed] = useState(0);
  const [errors, setErrors] = useState([]);
  const shouldContinueRef = useRef(true);

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const clearForm = () => {
    shouldContinueRef.current = false;
    toggleDialog();
    setTech(defaultTech);
    setStatus(defaultStatus);
    setScheduledDate();
    setErrors([]);
    setInspectionsProcessed(0);
  };

  const onDropDownChange = (setState) => {
    return (event) => {
      setState(event.target.value);
    };
  };

  const fetchIdsPath = useMemo(() => `${massUpdateInspectionsPath({ all: true })}&${queryParams}`, [queryParams]);

  const onSubmit = () => {
    shouldContinueRef.current = true;
    setErrors([]);

    const formData = {
      technician_id: tech.value,
      status_code: status.value,
      scheduled_date: scheduledDate,
      inspection_series_id: inspectionSeriesId
    };

    if (!inspectionIds) {
      api.post(fetchIdsPath).then(({ data }) => {
        batchUpdate(formData, data);
      });
    } else {
      api.post(massUpdateInspectionsPath(), { ...formData, ids: inspectionIds }).then(({ data }) => {
        onDataChanged(I18n.t('inspections.mass_update.success'), data);
      });
    }
  };

  const batchUpdate = (formData, ids, prevData = null) => {
    if (!shouldContinueRef.current) {
      console.log('Loop aborted by the user.');
      return;
    }

    if (!ids.length) {
      onDataChanged(I18n.t('inspections.mass_update.success'), prevData);
      return;
    }

    api.post(massUpdateInspectionsPath(), { ...formData, ids: ids.splice(0, 10) }).then(({ data }) => {
      setInspectionsProcessed((prev) => prev + data.ids.length);
      batchUpdate(formData, ids, data);
    });
  };

  const statusItemRender = (element, itemProps) => {
    const { icon, color } = itemProps.dataItem;
    return React.cloneElement(element, element.props, statusRenderTemplate(element, icon, color));
  };

  const statusValueRender = (element, value) => {
    if (!value || !value.icon) {
      return element;
    }

    return React.cloneElement(element, element.props, statusRenderTemplate(element, value.icon, value.color));
  };

  const statusRenderTemplate = (element, icon, color) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span
          className="qmb-avatar--24--status-icon"
          style={{ display: 'flex', borderRadius: '50%', alignItems: 'center', justifyContent: 'center' }}>
          <i className={`fa-lg ${icon}`} style={{ color }} />
        </span>
        <div style={{ marginLeft: '0.8rem' }}>{element.props.children}</div>
      </div>
    );
  };

  return (
    <IntlProvider locale={I18n.locale}>
      <button type="button" tabIndex="0" className="qmb-control" disabled={disabled} onClick={clearForm}>
        <i className="fa-light fa-pen-to-square" />
        {translate('button', { namespace })}
      </button>
      {visible && (
        <Dialog title={translate('title', { namespace })} className="qmb-dialog" onClose={toggleDialog}>
          <p>{translate('sub_title', { namespace, count: inspectionsCount })}</p>
          <table className="qmb-table--spreadsheet">
            <thead>
              <tr>
                <th>{translate('change', { namespace })}</th>
                <th>{translate('value', { namespace })}</th>
              </tr>
            </thead>
            <tbody>
              <tr className="nested_fields">
                <td className="table__cell">
                  <div className="qmb-input--text">
                    <input value={translate('technician', { namespace })} disabled />
                  </div>
                </td>
                <td className="table__cell">
                  <div className="qmb-select--blank">
                    <DropDownList
                      name="technician_id"
                      id="techForSel"
                      textField="label"
                      dataItemKey="value"
                      data={techniciansForSelect}
                      value={tech}
                      defaultItem={defaultTech}
                      onChange={onDropDownChange(setTech)}
                      size="large"
                    />
                  </div>
                </td>
              </tr>
              <tr className="nested_fields">
                <td className="table__cell">
                  <div className="qmb-input--text">
                    <input value={translate('scheduled_date', { namespace })} disabled />
                  </div>
                </td>
                <td className="table__cell">
                  <div className="qmb-input--date--blank">
                    <DateTimePicker
                      name="scheduled_date"
                      placeholder={translate('scheduled_date_placeholder', { namespace })}
                      value={scheduledDate}
                      onChange={onDropDownChange(setScheduledDate)}
                      format={dateTimeFormat}
                    />
                  </div>
                </td>
              </tr>
              <tr className="nested_fields">
                <td className="table__cell">
                  <div className="qmb-input--text">
                    <input value={translate('status', { namespace })} disabled />
                  </div>
                </td>
                <td className="table__cell">
                  <div className="qmb-select--blank">
                    <DropDownList
                      name="status"
                      id="statusForSel"
                      textField="label"
                      dataItemKey="value"
                      data={statusesForSelect}
                      itemRender={statusItemRender}
                      valueRender={statusValueRender}
                      value={status}
                      defaultItem={defaultStatus}
                      onChange={onDropDownChange(setStatus)}
                      size="large"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            {errors.map((error, i) => (
              <div key={i}>
                <i className="fa-solid fa-do-not-enter" style={{ color: '#DB2B39' }} />
                {error}
              </div>
            ))}
          </div>
          <div className="modal__footer pull-right" style={{ justifyContent: 'space-between' }}>
            <div className="mt-3">
              {inspectionsProcessed > 0 && (
                <div>
                  Processing: {inspectionsProcessed}/{inspectionsCount}
                </div>
              )}
              <button type="button" className="qmb-button" onClick={clearForm}>
                {I18n.t('generic.cancel')}
              </button>
              <button type="button" onClick={onSubmit} className="qmb-button--submit">
                {I18n.t('generic.update')}
              </button>
            </div>
          </div>
        </Dialog>
      )}
    </IntlProvider>
  );
}

MassUpdateModal.propTypes = {
  inspectionIds: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool.isRequired,
  queryParams: PropTypes.object.isRequired,
  inspectionsCount: PropTypes.number.isRequired,
  techniciansForSelect: PropTypes.arrayOf(PropTypes.object),
  statusesForSelect: PropTypes.arrayOf(PropTypes.object),
  onDataChanged: PropTypes.func.isRequired,
  inspectionSeriesId: PropTypes.number
};

MassUpdateModal.defaultProps = {
  techniciansForSelect: [],
  statusesForSelect: [],
  inspectionIds: null,
  inspectionSeriesId: null
};

export default MassUpdateModal;
