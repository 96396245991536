import { loadCacheFromLocalStorage, toRestoreGridPage } from '../../../helpers/localStorage';
import { INITIAL_SORT, ALL_COLUMNS, INITIAL_FILTERS, INITIAL_PAGE } from '../constants';

export const getInitialData = (cacheKey) => {
  const cache = loadCacheFromLocalStorage(cacheKey);
  if (cacheIsValid(cache, cacheKey)) {
    return { ...cache, page: toRestoreGridPage() && cache.page ? cache.page : INITIAL_PAGE };
  }

  return {
    page: INITIAL_PAGE,
    sort: INITIAL_SORT,
    filters: INITIAL_FILTERS,
    columns: ALL_COLUMNS
  };
};

const cacheIsValid = (cache, cacheKey) => {
  if (!cache) return false;

  if (
    cache.filters &&
    cache.filters.values &&
    cache.filters.active &&
    cache.filters.operators &&
    cache.sort &&
    typeof cache.columns[0] === 'string'
  )
    return true;

  localStorage.removeItem(cacheKey);
  return false;
};
