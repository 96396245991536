import React from 'react';
import { technicianAvatar } from '@utils/functions/technician.functions';
import { subcontractorIcon } from '@utils/functions/subcontractor.functions';
import { isSubcontractor, isTechnician } from '@utils/typeGuards/assignee.typeguard';
export function visitIcon(visit) {
    return React.createElement("i", { style: { color: visit.status.color }, className: visit.status.icon });
}
export function visitStatusAndTimeLabel(visit) {
    const adjustedTime = visit.start.format('M/D/YYYY, h:mm A');
    return `${visit.status.label} - ${adjustedTime}`;
}
export function visitAssigneeIcon(visit) {
    let icon;
    if (isTechnician(visit.assignee))
        icon = technicianAvatar(visit.assignee.info, 16);
    else if (isSubcontractor(visit.assignee))
        icon = subcontractorIcon(visit.assignee.info, 16);
    else
        icon = React.createElement("i", { className: "fa-light fa-circle-question" });
    return React.createElement("span", { style: { display: 'flex', alignItems: 'center' } }, icon);
}
export function visitAssigneeTitle(visit) {
    var _a, _b;
    return (_b = (_a = visit.assignee.info) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : 'Unassigned';
}
