import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useGetSignatureTypes from '@hooks/requests/useGetSignatureTypes';
import useGetTechnicians from '@hooks/requests/technicians.get.hook';
import usePatchSignature from '@hooks/requests/usePatchSignature';
import usePostSignature from '@hooks/requests/usePostSignature';
import { SignaturesListContext } from './SignaturesListContext';
import { ToastContext } from '../../contexts/toast-context';
export const SignatureFormContext = createContext(undefined);
export function SignatureFormContextProvider(params) {
    const [signature, setSignature] = useState();
    const techniciansQuery = useMemo(() => ({
        select: 'all',
        filter: {
            inspectionIds: [params.resourceId]
        }
    }), []);
    const { data: technicians, loading: techniciansLoading, error: techniciansError } = useGetTechnicians(techniciansQuery);
    const { signatureTypes, loading: signatureTypesLoading, error: typesError } = useGetSignatureTypes();
    const { save: postSignature, saving, result } = usePostSignature();
    const { save: patchSignature, saving: patchSaving, result: patchResult } = usePatchSignature();
    const { addSignature, replaceSignature } = useContext(SignaturesListContext);
    const { alertSuccess, alertError } = useContext(ToastContext);
    useEffect(() => {
        if (result) {
            addSignature(result);
            setSignature(undefined);
            alertSuccess('Signature added.');
        }
    }, [result]);
    useEffect(() => {
        if (patchResult) {
            replaceSignature(patchResult);
            setSignature(undefined);
            alertSuccess('Signature updated.');
        }
    }, [patchResult]);
    const openNewSignature = useCallback((type, technicianId) => {
        const newSignature = {
            signatureType: type.key,
            name: '',
            signedAt: new Date(),
            resourceType: params.resourceType,
            resourceId: params.resourceId,
            technicianId,
            certificationNumber: null,
            image: null,
            uniqueId: null
        };
        setSignature(newSignature);
    }, []);
    const openExistingSignature = useCallback((existingSignature) => {
        setSignature(existingSignature);
    }, []);
    const closeForm = useCallback(() => {
        setSignature(undefined);
    }, []);
    const contextData = useMemo(() => {
        const value = {
            signature,
            setSignature,
            signatureTypes,
            signatureTypesLoading,
            technicians,
            openNewSignature,
            openExistingSignature,
            closeForm,
            postSignature,
            patchSignature
        };
        return value;
    }, [signature, signatureTypes, signatureTypesLoading, technicians]);
    return React.createElement(SignatureFormContext.Provider, { value: contextData }, params.children);
}
