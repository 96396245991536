import React, { useContext, useMemo } from 'react';
import { technicianAvatar } from '@utils/functions/technician.functions';
import { CalendarContext } from '../../CalendarContext';
function TimeOff(params) {
    const { state: { detailed } } = useContext(CalendarContext);
    const rightSideIcon = useMemo(() => {
        return technicianAvatar(params.timeOff.technician, 16);
    }, [params.timeOff.technician]);
    return (React.createElement("div", { className: `cal__event ${detailed ? '--detailed' : ''}` },
        React.createElement("div", { className: "event__crux" },
            React.createElement("span", { className: "event__type" },
                React.createElement("i", { className: "fa-light fa-umbrella-beach" })),
            React.createElement("span", { className: "event__time" }, params.timeOff.start.format('hh:mm A'))),
        React.createElement("div", { className: "event__title" }, params.timeOff.label),
        React.createElement("div", { className: "event__indicate" }, rightSideIcon)));
}
export default TimeOff;
