import React from 'react';
import { translate } from '@utils/i18n';
import { getDateFormat } from '../../../utils/getDatetimeFormat';
import { Loader, LoaderSizes } from '../../../components/Loader/Loader';
import { ReviewStatus } from '../types';
import { StatusAllowedDefDetails } from '../constants';
const namespace = 'features.documents';
function getDocumentColumns({ onRequestDelete, onOpenDeficiencies, onRequestScan, idLoading, canDelete, aiEnabled }) {
    return [
        {
            label: translate('document', { namespace }),
            dataKey: 'name',
            width: 240,
            renderer: ({ item }) => {
                return (React.createElement("div", { className: "doc-name-container" },
                    item.type === 'Image' && React.createElement("i", { className: "fa-light fa-file-image" }),
                    item.type === 'Document' && React.createElement("i", { className: "fa-light fa-file-word" }),
                    React.createElement("a", { rel: "noreferrer", className: "qmb-link", href: item.url, target: "_blank" }, item.file_name)));
            }
        },
        {
            label: translate('document_type', { namespace }),
            align: 'left',
            dataKey: 'content_type',
            width: 416
        },
        {
            label: translate('uploaded', { namespace }),
            align: 'left',
            dataKey: 'createdAt',
            width: 416,
            renderer: ({ item }) => {
                const scheduledDate = new Date(item === null || item === void 0 ? void 0 : item.created_at);
                if (!Number.isNaN(scheduledDate.getTime())) {
                    return getDateFormat(item === null || item === void 0 ? void 0 : item.created_at);
                }
                return React.createElement("span", null, translate('n_a', { namespace: 'features.commons' }));
            }
        },
        aiEnabled && {
            label: translate('review_status', { namespace }),
            align: 'left',
            dataKey: 'status',
            renderer: ({ item }) => {
                return React.createElement("span", null, item.status && item.canAi ? translate(item.status, { namespace }) : '--');
            },
            width: 150
        },
        {
            label: '',
            align: 'left',
            dataKey: 'id',
            renderer: ({ item }) => {
                const buttons = [
                    aiEnabled &&
                        item.canAi &&
                        (item.status === ReviewStatus.Unscanned || item.status === ReviewStatus.Scanning) && (React.createElement("button", { type: "button", "aria-label": "Scan document", className: "qmb-control--icon", disabled: item.status === ReviewStatus.Scanning, "data-title": item.status === ReviewStatus.Scanning ? translate('processing', { namespace }) : undefined, onClick: () => {
                            void (onRequestScan === null || onRequestScan === void 0 ? void 0 : onRequestScan(item.id));
                        } },
                        React.createElement("i", { className: "fa-light fa-sparkles" }))),
                    aiEnabled && item.canAi && item.status && StatusAllowedDefDetails.includes(item.status) && (React.createElement("button", { type: "button", "aria-label": "Open Deficiencies", className: "qmb-control--icon", onClick: () => {
                            onOpenDeficiencies === null || onOpenDeficiencies === void 0 ? void 0 : onOpenDeficiencies(item);
                        } },
                        React.createElement("i", { className: "fa-light fa-file-magnifying-glass" }))),
                    canDelete && idLoading !== item.id && (React.createElement("button", { type: "button", "aria-label": "Delete", className: "qmb-control--icon", onClick: () => {
                            onRequestDelete === null || onRequestDelete === void 0 ? void 0 : onRequestDelete(item.id);
                        } },
                        React.createElement("i", { className: "fa-light fa-trash-can" }))),
                    idLoading === item.id && React.createElement(Loader, { size: LoaderSizes.small })
                ].filter(Boolean);
                return React.createElement("div", { className: "buttonContainer" }, ...buttons);
            }
        }
    ].filter(Boolean);
}
export default getDocumentColumns;
