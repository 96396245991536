import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from '@utils/i18n';
import { updateForm } from './Redux/taxItemFormSlice';

const namespace = 'features.settings.tax_items.form';

function TaxItemForm({ taxRates, taxItem }) {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.form.formData);
  const isTaxGroup = formData.is_tax_group;

  const [newRateFields, setNewRateFields] = useState({ name: '', rate: '' });
  const [isAddingRate, setIsAddingRate] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    dispatch(updateForm({ [name]: type === 'checkbox' ? checked : value }));
  };

  const handleRadioChange = (e) => {
    const value = e.target.value === 'group';
    dispatch(
      updateForm({
        is_tax_group: value
      })
    );
  };

  const handleNewRateFieldChange = (e) => {
    const { name, value } = e.target;
    setNewRateFields((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (id) => {
    const tax_rate_ids = formData.tax_rate_ids.includes(id)
      ? formData.tax_rate_ids.filter((rateId) => rateId !== id)
      : [...formData.tax_rate_ids, id];

    dispatch(updateForm({ tax_rate_ids }));
  };

  const handleAddNewRate = () => {
    const { name, rate } = newRateFields;
    if (!name || rate === '') {
      toast.error(translate('fill_in_fields', { namespace }));
      return;
    }

    if (Number.isNaN(parseFloat(rate)) || parseFloat(rate) <= 0) {
      toast.error(translate('positive_rate_required', { namespace }));
      return;
    }

    dispatch(
      updateForm({
        tax_rates_attributes: [...formData.tax_rates_attributes, { name, rate: parseFloat(rate) }],
        tax_rate_ids: [...formData.tax_rate_ids]
      })
    );

    handleCancelNewRate();
  };

  const handleCancelNewRate = () => {
    setNewRateFields({ name: '', rate: '' });
    setIsAddingRate(false);
  };

  const handleRemoveNewRate = (index) => {
    const updatedNewRates = formData.tax_rates_attributes.filter((_, i) => i !== index);
    const updatedRateIds = formData.tax_rate_ids.filter((id) => id !== `new-${index}`);

    dispatch(updateForm({ tax_rates_attributes: updatedNewRates, tax_rate_ids: updatedRateIds }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form className="form-horizontal pt-2">
      <section>
        <div className="w-100">
          {taxItem === null && (
            <div className="pb-1">
              <label className="qmb-radio">
                <input type="radio" name="tax_type" value="rate" checked={!isTaxGroup} onChange={handleRadioChange} />
                {translate('tax_rate', { namespace })}
              </label>
              <br />
              <br />
              <label className="qmb-radio">
                <input type="radio" name="tax_type" value="group" checked={isTaxGroup} onChange={handleRadioChange} />
                {translate('tax_group', { namespace })}
              </label>
            </div>
          )}
          <br />
          <div className="qmb-input--text --x-full">
            <input
              type="text"
              name="name"
              placeholder={translate('name', { namespace })}
              value={formData.name ?? ''}
              onChange={handleChange}
              required
            />
            <label className="qmb-label">{translate('name', { namespace })}</label>
          </div>
          <br />
          <br />
          <div className="qmb-input--text --x-full">
            <input
              type="text"
              name="tax_agency"
              placeholder={translate('agency', { namespace })}
              value={formData.tax_agency ?? ''}
              onChange={handleChange}
              required
            />
            <label className="qmb-label">{translate('agency', { namespace })}</label>
          </div>
          <br />
          <br />
          {((taxItem === null && !isTaxGroup) || (taxItem !== null && taxItem.type === 'TaxRate')) && (
            <div className="qmb-input--number --x-full">
              <input
                type="number"
                name="rate"
                placeholder={`${translate('rate', { namespace })} (%)`}
                value={formData.rate ?? ''}
                onChange={handleChange}
                step="0.00001"
                min="0.00001"
                required
              />
              <label className="qmb-label">{`${translate('rate', { namespace })} (%)`}</label>
            </div>
          )}
          {((taxItem === null && isTaxGroup) || (taxItem !== null && taxItem.type === 'TaxGroup')) && (
            <div>
              <ul className="qmb-list--unlist--options">
                <h4 className="k-window-title my-1" style={{ marginLeft: '-0.9rem' }}>
                  {translate('associated_tax_rates', { namespace })}
                </h4>
                {taxRates.map((rate) => (
                  <li key={rate.id} onClick={() => handleCheckboxChange(rate.id)}>
                    <div className="qmb-checkbox">
                      <input
                        type="checkbox"
                        className="qmb-checkbox"
                        checked={formData.tax_rate_ids.includes(rate.id)}
                        readOnly
                      />
                      <label className="ml-2 qmb-label qmb-checkbox-label">{`${rate.rate}% - ${rate.name}`}</label>
                    </div>
                  </li>
                ))}

                {formData.tax_rates_attributes.map((rate, index) => (
                  <li key={`new-${index}`} style={{ display: 'flex' }}>
                    <div className="qmb-checkbox">
                      <input type="checkbox" checked disabled className="qmb-checkbox" />
                      <label className="ml-3 qmb-checkbox-label">{`${rate.rate}% - ${rate.name}`}</label>
                    </div>
                    <button
                      type="button"
                      onClick={() => handleRemoveNewRate(index)}
                      className="qmb-control--icon"
                      style={{ display: 'flex', marginLeft: 'auto' }}
                      title="Remove Tax Rate">
                      <i className="fa fa-light fa-trash" />
                    </button>
                  </li>
                ))}
              </ul>

              {!isAddingRate && (
                <div className="content-actions__group qmb-control mt-1 qmb-label">
                  <a
                    style={{ color: 'rgba(0,0,0,0.7)' }}
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsAddingRate(true);
                    }}>
                    <i className="fa fa-light fa-circle-plus" /> {translate('add_rate', { namespace })}
                  </a>
                </div>
              )}

              {isAddingRate && (
                <div style={{ width: '370px', display: 'flex' }} className="mt-2">
                  <div className="qmb-input--text --x-half pr-2" style={{ alignSelf: 'center' }}>
                    <input
                      type="text"
                      name="name"
                      placeholder={translate('name', { namespace })}
                      value={newRateFields.name ?? ''}
                      onChange={handleNewRateFieldChange}
                      required
                    />
                    <label className="qmb-label">{translate('name', { namespace })}</label>
                  </div>
                  <div className="qmb-input--number --x-quarter mt-0" style={{ alignSelf: 'center' }}>
                    <input
                      type="number"
                      name="rate"
                      placeholder={`${translate('rate', { namespace })} (%)`}
                      value={newRateFields.rate ?? ''}
                      onChange={handleNewRateFieldChange}
                      step="0.00001"
                      min="0.00001"
                      required
                    />
                    <label className="qmb-label">{`${translate('rate', { namespace })} (%)`}</label>
                  </div>
                  <div className="v-align-middle" style={{ display: 'flex', marginLeft: 'auto' }}>
                    <button
                      type="button"
                      onClick={handleAddNewRate}
                      className="qmb-control--icon"
                      title={translate('add_new_rate', { namespace })}
                      style={{ alignSelf: 'center' }}>
                      <i className="fa fa-light fa-check" />
                    </button>
                    <button
                      type="button"
                      onClick={handleCancelNewRate}
                      className="qmb-control--icon"
                      title={translate('remove_rate', { namespace })}
                      style={{ alignSelf: 'center' }}>
                      <i className="fa fa-light fa-trash" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </section>
    </form>
  );
}

TaxItemForm.propTypes = {
  taxRates: PropTypes.array,
  taxItem: PropTypes.object
};

TaxItemForm.defaultProps = {
  taxRates: null,
  taxItem: null
};

export default TaxItemForm;
