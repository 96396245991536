export var ReviewStatus;
(function (ReviewStatus) {
    ReviewStatus["Unscanned"] = "unscanned";
    ReviewStatus["Scanning"] = "scanning";
    ReviewStatus["NeedsReview"] = "needs_review";
    ReviewStatus["InProgress"] = "in_progress";
    ReviewStatus["Completed"] = "completed";
})(ReviewStatus || (ReviewStatus = {}));
export var PDFViewerStatus;
(function (PDFViewerStatus) {
    PDFViewerStatus["Normal"] = "NORMAL";
    PDFViewerStatus["Expanded"] = "EXPANDED";
    PDFViewerStatus["Minimized"] = "MINIMIZED";
})(PDFViewerStatus || (PDFViewerStatus = {}));
export var IPDeficiencyStatus;
(function (IPDeficiencyStatus) {
    IPDeficiencyStatus["NoStatus"] = "no_status";
    IPDeficiencyStatus["Impairment"] = "impairment";
    IPDeficiencyStatus["Critical"] = "critical";
    IPDeficiencyStatus["NonCritical"] = "non_critical";
})(IPDeficiencyStatus || (IPDeficiencyStatus = {}));
export var AIDeficiencyReviewStatus;
(function (AIDeficiencyReviewStatus) {
    AIDeficiencyReviewStatus["WaitingReview"] = "waiting_review";
    AIDeficiencyReviewStatus["Incorrect"] = "incorrect";
    AIDeficiencyReviewStatus["Correct"] = "correct";
    AIDeficiencyReviewStatus["Removed"] = "removed";
})(AIDeficiencyReviewStatus || (AIDeficiencyReviewStatus = {}));
