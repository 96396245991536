import { loadCacheFromLocalStorage } from '../../../helpers/localStorage';

export const INITIAL_FILTER_STATE = {
  values: {
    building: '',
    technicianTeam: '',
    territory: '',
    tags: [],
    type: '',
    inspectionStatus: '',
    inspectionType: '',
    visitStatus: '',
    asset: '',
    inspectionStatusKeys: [],
    visitStatusKeys: []
  },
  active: {
    building: true,
    technicianTeam: false,
    territory: false,
    tags: false,
    type: false,
    inspectionStatus: false,
    inspectionType: false,
    visitStatus: false,
    asset: false,
    statuses: false
  }
};

export const getInitialData = ({ cacheKey, technicians }) => {
  const cache = loadCacheFromLocalStorage(cacheKey);
  if (cacheIsValid(cache, cacheKey)) {
    return {
      ...cache,
      date: new Date(cache.date),
      events: [],
      technicians,
      errorMessage: '',
      loading: true,
      selectedTechnicianIds: cache.selectedTechnicianIds === 'all' ? [] : cache.selectedTechnicianIds,
      selectedSubcontractorIds: cache.selectedSubcontractorIds === 'all' ? [] : cache.selectedSubcontractorIds
    };
  }

  return {
    events: [],
    technicians,
    selectedTechnicianIds: [],
    selectedSubcontractorIds: [],
    errorMessage: '',
    loading: true,
    detailed: false,
    filters: INITIAL_FILTER_STATE,
    unselectedTechs: {},
    view: 'dayGridMonth',
    statuses: { Inspectioncompleted: true, Inspectionpending: true, Visitcomplete: true, Visitpending: true },
    modalOpen: false
  };
};

const cacheIsValid = (cache, cacheKey) => {
  if (!cache) return false;

  if (cache.unselectedTechs && cache.view && cache.selectedTechnicianIds && cache.selectedSubcontractorIds) return true;

  localStorage.removeItem(cacheKey);
  return false;
};
