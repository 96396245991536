/* global $ */

import React, { useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import shuffle from 'lodash/shuffle';
import { startDragging, types, status as draggingStatuses } from '../Redux/draggingSlice';
import {
  actions,
  addEvent,
  deleteEvent,
  pushToSaveQueue,
  setStatus,
  status as schedulerStatuses,
  stopRepositioningEvent,
  toggleTechnician
} from '../Redux/schedulerSlice';
import { assignVisitToTechnician } from '../Redux/visitsSlice';
import { TruckSvg } from '../FleetDropdown';
import { showFleetTrackingModal } from '../Redux/fleetTrackingSlice';

function TechnicianCard({ technician }) {
  const dispatch = useDispatch();
  const cardRef = useRef(null);
  const schedulerStatus = useSelector((state) => state.scheduler.status);
  const draggingType = useSelector((state) => state.dragging.type);
  const draggingData = useSelector((state) => state.dragging.data);
  const draggingStatus = useSelector((state) => state.dragging.status);
  const activeEvent = useSelector((state) => state.scheduler.activeEvent);
  const currentAction = useSelector((state) => state.scheduler.action);
  const fleetTrackingActive = useSelector((state) => state.fleet.active);
  const drivers = useSelector((state) => state.fleet.techs);

  let vehicle = drivers.find((tech) => tech.id === technician.id);
  if (!vehicle && technician.fleet_manager_vehicle_id) {
    vehicle = drivers.find((tech) => tech.vehicle_id === technician.fleet_manager_vehicle_id);
  }

  const events = useSelector(
    (state) =>
      state.scheduler.events.filter(
        (event) =>
          ['Inspection', 'Visit'].includes(event.type) && event.technicianId === technician.id
      ),
    shallowEqual
  );

  const HandleMouseDown = (e) => {
    if (e.target === cardRef.current) {
      dispatch(startDragging({ x: e.clientX, y: e.clientY, data: technician, type: types.TECHNICIAN }));
    }
  };

  const HandleMouseEnter = (e) => {
    if (schedulerStatus === schedulerStatuses.REPOSITIONING && activeEvent) {
      if (currentAction === actions.SAMPLING_VISIT)
        dispatch(startDragging({ data: { ...activeEvent }, x: e.clientX, y: e.clientY, type: types.VISIT }));
      else dispatch(startDragging({ data: { ...activeEvent }, x: e.clientX, y: e.clientY, type: types.EVENT }));

      dispatch(stopRepositioningEvent({ key: activeEvent.key, save: false }));
      dispatch(deleteEvent(activeEvent.key));
      dispatch(setStatus(schedulerStatuses.DRAGGING));
    }
  };

  const HandleMouseUp = () => {
    if (draggingStatus === draggingStatuses.DRAGGING && draggingType === types.EVENT) {
      const newEvent = {
        ...draggingData,
        technicianId: technician.id,
        startTime: draggingData.metadata.prevStartTime,
        endTime: draggingData.metadata.prevEndTime,
        assignee: { technician }
      };
      dispatch(addEvent({ data: newEvent }));
      dispatch(pushToSaveQueue(newEvent));
    } else if (draggingStatus === draggingStatuses.DRAGGING && draggingType === types.VISIT) {
      dispatch(assignVisitToTechnician({ visit: draggingData, technician }));
    }
  };

  const handleVehicleClick = () => {
    dispatch(showFleetTrackingModal(vehicle));
  };

  return (
    <div
      role="presentation"
      ref={cardRef}
      className="tech__item"
      onMouseDown={HandleMouseDown}
      onMouseEnter={HandleMouseEnter}
      onMouseUp={HandleMouseUp}
      style={{overflowX: 'hidden'}}>
      <div className="tech__card">
        <div className="tech__info">
          <Icon technician={technician} />
          <div className="tech__name">{technician.name}</div>
        </div>

        <div className="tech__visits">
          <span
            className="tech__statuses"
            title={`${events.length} Visit${events.length > 1 || events.length === 0 ? 's' : ''}`}>
            <StatusBadges events={events} />
            {events.length > 0 && <span className="tech__diff">{events.length}</span>}
          </span>

          {fleetTrackingActive && vehicle && (
            <button
              type="button"
              onClick={handleVehicleClick}
              className="qmb-control"
              style={{ border: 'none', zIndex: 10000000 }}>
              <TruckSvg vehicle={vehicle} />
            </button>
          )}

          {events.length > 0 && (
            <MapExclusionToggle id={technician.id} showOnMap={technician.showOnMap} statusCounts={events.length} />
          )}
        </div>
      </div>
    </div>
  );
}

function MapExclusionToggle({ id, showOnMap, statusCounts }) {
  const dispatch = useDispatch();
  const toggleRef = useRef(null);

  useEffect(() => {
    $(toggleRef.current).kendoTooltip({
      position: 'right'
    });
  }, []);

  useEffect(() => {
    const visitText = `${statusCounts} visit${statusCounts > 1 || statusCounts === 0 ? 's' : ''}`;
    $(toggleRef.current).data('kendoTooltip').options.content = `${
      !showOnMap ? `Show ${visitText} on` : `Hide ${visitText} from`
    } map`;
    $(toggleRef.current).data('kendoTooltip').refresh();
  }, [showOnMap]);

  const HandleClick = () => {
    dispatch(toggleTechnician(id));
  };

  return (
    <button
      type="button"
      className={`qmb-control--icon--toggle${showOnMap ? '--active' : '--dimmer'}`}
      onClick={HandleClick}
      ref={toggleRef}
      title={`${!showOnMap ? 'Show' : 'Hide'} on Map`}>
      <i className={`fa-light ${!showOnMap ? 'fa-location-dot-slash' : 'fa-location-dot'}`} />
    </button>
  );
}

function StatusBadges({ events }) {
  const badges = useMemo(
    () => shuffle(events.map((event) => [event.statusIcon, event.statusColor])).slice(0, 3),
    [events.length]
  );

  return badges.map((badge, i) => (
    <span key={i} className="qmb-status">
      <i className={badge[0]} style={{ color: badge[1] }} />
    </span>
  ));
}

function Icon({ technician }) {
  if (!technician.photoUrl) {
    return (
      <span className="qmb-avatar--32">
        <svg viewBox="0 0 16 16">
          <circle cx="8" cy="8" r="8" style={{ fill: technician.color }} />
          <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle">
            {technician.name[0]}
          </text>
        </svg>
      </span>
    );
  }
  return (
    <span className="qmb-avatar--32">
      <img
        style={{ borderRadius: '50%', border: `2px solid ${technician.color}`, boxSizing: 'border-box' }}
        src={technician.photoUrl}
        alt={technician.name}
      />
    </span>
  );
}

export default TechnicianCard;

TechnicianCard.propTypes = {
  technician: PropTypes.object
};

TechnicianCard.defaultProps = {
  technician: null
};

MapExclusionToggle.propTypes = {
  id: PropTypes.string,
  showOnMap: PropTypes.bool,
  statusCounts: PropTypes.number
};

MapExclusionToggle.defaultProps = {
  id: null,
  showOnMap: false,
  statusCounts: 0
};

Icon.propTypes = {
  technician: PropTypes.object
};

Icon.defaultProps = {
  technician: null
};
