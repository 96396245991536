/* eslint-disable react/require-default-props */
import React, { useEffect } from 'react';
import Button from '@components/Button/Button';
import './Modal.scss';
export default function FullPageModal(props) {
    useEffect(() => {
        const onKeyDown = (e) => {
            if (e.key === 'Escape') {
                props.onClose();
            }
        };
        window.addEventListener('keydown', onKeyDown);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, []);
    useEffect(() => {
        if (props.isOpen) {
            // Add a dummy entry to the browser's history
            window.history.pushState({ modalOpen: true }, '');
            const handlePopState = () => {
                // Close the modal when the back button is pressed
                props.onClose();
                // Re-add the dummy entry to keep the user on the same page
                window.history.pushState({ modalOpen: true }, '');
            };
            window.addEventListener('popstate', handlePopState);
            // Cleanup when the modal closes or component unmounts
            return () => {
                var _a;
                window.removeEventListener('popstate', handlePopState);
                // Remove the dummy history entry
                if ((_a = window.history.state) === null || _a === void 0 ? void 0 : _a.modalOpen) {
                    window.history.back();
                }
            };
        }
    }, [props.isOpen, props.onClose]);
    return (React.createElement("div", { role: "dialog", "aria-modal": "true", className: `fullPageModal ${props.isOpen ? 'open' : ''}` },
        React.createElement("header", { className: "header" },
            React.createElement("section", null, props.title),
            React.createElement(Button, { onClick: props.onClose },
                React.createElement("i", { className: "fa-light fa-xmark" }))),
        React.createElement("section", { className: "content" },
            " ",
            props.children),
        React.createElement("footer", { className: "footer" },
            React.createElement("section", null, props.footerText),
            React.createElement("section", { className: "action" }, props.actionButtons))));
}
