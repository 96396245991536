import React, { useMemo } from 'react';
import Visit from './Visit';
import TimeOff from './TimeOff';
import Holiday from './Holiday';
function CalendarEvent(params) {
    const content = useMemo(() => {
        var _a, _b, _c;
        switch ((_c = (_b = (_a = params.fcProps) === null || _a === void 0 ? void 0 : _a.event) === null || _b === void 0 ? void 0 : _b.extendedProps) === null || _c === void 0 ? void 0 : _c.type) {
            case 'visit':
                return (React.createElement(Visit, { visit: params.fcProps.event.extendedProps.data, highlightStatus: params.fcProps.event.extendedProps.highlightingStatus }));
            case 'timeoff':
                return React.createElement(TimeOff, { timeOff: params.fcProps.event.extendedProps.data });
            case 'holiday':
                return React.createElement(Holiday, { holiday: params.fcProps.event.extendedProps.data });
            default:
                return null;
        }
    }, [params.fcProps.event.extendedProps]);
    return content;
}
export default CalendarEvent;
