/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import Button, { ButtonVariant } from '@components/Button/Button';
import ConfirmDialog from '@components/ConfirmDialog/ConfirmDialog';
import { translate } from '@utils/i18n';
import { AIDeficiencyReviewStatus } from '../types';
var Steps;
(function (Steps) {
    Steps["WaitingReview"] = "waiting_review";
    Steps["InitialIncorrect"] = "incorrect";
    Steps["IncorrectToRemotion"] = "IncorrectToRemotion";
    Steps["IncorrectToEdit"] = "IncorrectToEdit";
    Steps["Correct"] = "correct";
    Steps["Removed"] = "removed";
})(Steps || (Steps = {}));
const StatusSteps = {
    [Steps.WaitingReview]: ({ onChangeStep }) => {
        return (React.createElement("section", { className: "footerContent" },
            React.createElement("span", { className: "footerTitle" }, translate('is_this_correct', { namespace: 'features.commons' })),
            React.createElement("div", { className: "options" },
                React.createElement(Button, { onClick: () => onChangeStep(Steps.InitialIncorrect), variant: ButtonVariant.Outline },
                    React.createElement("i", { className: "fa-light fa-xmark" }),
                    "No"),
                React.createElement(Button, { onClick: () => onChangeStep(Steps.Correct), variant: ButtonVariant.Outline },
                    React.createElement("i", { className: "fa-light fa-check" }),
                    "Yes"))));
    },
    [Steps.InitialIncorrect]: ({ onChangeStep }) => {
        return (React.createElement("section", { className: "footerContent dialogMode" },
            React.createElement("i", { role: "presentation", className: "fa-light fa-xmark", onClick: () => onChangeStep(Steps.WaitingReview) }),
            React.createElement("span", { className: "footerTitle" }, "Tell us more"),
            React.createElement("div", { className: "options" },
                React.createElement(Button, { onClick: () => onChangeStep(Steps.IncorrectToRemotion), variant: ButtonVariant.Outline }, "This is not a deficiency"),
                React.createElement(Button, { onClick: () => onChangeStep(Steps.IncorrectToEdit), variant: ButtonVariant.Outline }, "The details are incorrect"))));
    },
    [Steps.IncorrectToRemotion]: ({ onRequestRemove, onChangeStep }) => {
        return (React.createElement("section", { className: "footerContent dialogMode" },
            React.createElement("i", { role: "presentation", className: "fa-light fa-xmark", onClick: () => onChangeStep(Steps.WaitingReview) }),
            React.createElement("span", { className: "footerTitle" }, "This is not a deficiency. Do you want to remove it?"),
            React.createElement("div", { className: "options" },
                React.createElement(Button, { onClick: () => onRequestRemove === null || onRequestRemove === void 0 ? void 0 : onRequestRemove(), variant: ButtonVariant.Outline }, "Remove"))));
    },
    [Steps.IncorrectToEdit]: ({ onChangeStep }) => {
        return (React.createElement("section", { className: "footerContent dialogMode" },
            React.createElement("i", { role: "presentation", className: "fa-light fa-xmark", onClick: () => onChangeStep(Steps.InitialIncorrect) }),
            React.createElement("span", { className: "footerTitle" }, "The deficiency details are incorrect. Correct them above and update to mark it correct."),
            React.createElement("div", { className: "options" },
                React.createElement(Button, { type: "submit", onClick: () => onChangeStep === null || onChangeStep === void 0 ? void 0 : onChangeStep(Steps.Correct), variant: ButtonVariant.Outline }, "Update and Mark Correct"))));
    },
    [AIDeficiencyReviewStatus.Correct]: ({ onChangeStep }) => {
        return (React.createElement("section", { className: "footerContent" },
            React.createElement("span", { className: "footerTitle" }, translate('is_this_correct', { namespace: 'features.commons' })),
            React.createElement("div", { className: "options" },
                React.createElement(Button, { onClick: () => onChangeStep(Steps.InitialIncorrect), variant: ButtonVariant.Outline },
                    React.createElement("i", { className: "fa-light fa-xmark" }),
                    "No"),
                React.createElement(Button, { disabled: true, variant: ButtonVariant.Outline },
                    React.createElement("i", { className: "fa-light fa-check" }),
                    "Yes"))));
    },
    [AIDeficiencyReviewStatus.Removed]: () => {
        return React.createElement("div", null);
    }
};
export default function BrushawaySteps({ deficiency, onChangeStatus, onStartEditing, onStopEditing }) {
    const [currentStep, setCurrentStep] = useState(deficiency.reviewStatus);
    const StepComp = StatusSteps[currentStep];
    const [isConfirmRemotionModalOpen, setIsConfirmRemotionModalOpen] = React.useState(false);
    useEffect(() => {
        setCurrentStep(deficiency.reviewStatus);
    }, [deficiency.reviewStatus]);
    const onChangeStep = (nextStep) => {
        setCurrentStep(nextStep);
        if (nextStep === Steps.InitialIncorrect) {
            onChangeStatus(AIDeficiencyReviewStatus.Incorrect);
        }
        else if (nextStep === Steps.Correct) {
            onChangeStatus(AIDeficiencyReviewStatus.Correct);
        }
        else if (nextStep === Steps.IncorrectToEdit) {
            onStartEditing();
        }
        else if (nextStep === Steps.WaitingReview) {
            onChangeStatus(AIDeficiencyReviewStatus.WaitingReview);
        }
        if (nextStep !== Steps.IncorrectToEdit) {
            onStopEditing();
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(StepComp, { onChangeStep: onChangeStep, onRequestRemove: () => setIsConfirmRemotionModalOpen(true) }),
        isConfirmRemotionModalOpen && (React.createElement(ConfirmDialog, { title: "Remove Deficiency", description: "Do you want to remove this deficiency?", onConfirm: () => onChangeStatus(AIDeficiencyReviewStatus.Removed), onCancel: () => setIsConfirmRemotionModalOpen(false) }))));
}
