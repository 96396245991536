import { useEffect, useState } from 'react';
import { apiInternalTechniciansPath } from '../../routes';
import apiClient from '@utils/api-client';
import TechnicianSortableAttributes from '@enums/sortable-attributes/technician-sortable-attributes.enum';
const useGetTechnicians = (queryParams) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    useEffect(() => {
        if (!queryParams)
            return;
        const abortController = new AbortController();
        setLoading(true);
        const defaultParams = {
            select: 'all',
            sort: { attribute: TechnicianSortableAttributes.NAME, direction: 'asc' }
        };
        apiClient
            .get(apiInternalTechniciansPath(), {
            params: queryParams !== null && queryParams !== void 0 ? queryParams : defaultParams,
            signal: abortController.signal
        })
            .then((response) => {
            setData(response.data.data);
        })
            .catch((err) => {
            if (!abortController.signal.aborted) {
                setData([]);
                setError(err);
            }
        })
            .finally(() => {
            if (!abortController.signal.aborted) {
                setLoading(false);
            }
        });
        return () => {
            abortController.abort();
        };
    }, [queryParams]);
    return { data, loading, error };
};
export default useGetTechnicians;
