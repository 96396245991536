import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import I18n from '@utils/i18n';

const { ConfirmationModal } = window;

function Onboarding({ sections, skipOnboardingLink }) {
  useEffect(() => {
    new ConfirmationModal().init();
  }, []);

  const initialCardIndex = sections.findIndex((section) => !section.done);
  const [currentCard, setCurrentCard] = useState(initialCardIndex >= 0 ? initialCardIndex : 0); // 0 by default; set to section that isn't completed otherwise

  const handleCardClick = (index) => {
    setCurrentCard(index);
  };

  const handleNextCtrl = (e) => {
    e.stopPropagation();
    if (currentCard < sections.length - 1) {
      setCurrentCard(currentCard + 1);
    }
  };

  const handlePrevCtrl = (e) => {
    e.stopPropagation();
    if (currentCard > 0) {
      setCurrentCard(currentCard - 1);
    }
  };

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) console.log('swipe', isLeftSwipe ? 'left' : 'right');
    // add your conditional logic here

    if (isLeftSwipe) {
      setCurrentCard(currentCard + 1);
    }
    if (isRightSwipe) {
      setCurrentCard(currentCard - 1);
    }
  };

  const links = (section) => {
    return section.steps.map((step) => (
      <React.Fragment key={step.code}>
        {step.done ? (
          <li className="card__step--done">
            <i className="fa-regular fa-check" />
            <a href={step.path}>{I18n.t(`onboardings.processing_onboarding.steps.${step.code}`)}</a>
          </li>
        ) : (
          <li className="card__step">
            <a href={step.path}>{I18n.t(`onboardings.processing_onboarding.steps.${step.code}`)}</a>
          </li>
        )}
      </React.Fragment>
    ));
  };

  const sectionsList = sections.map((section, index) => {
    return (
      <div
        className={`walkthrough__card ${index === currentCard ? '--current' : ''} ${
          index < currentCard ? '--before' : ''
        } ${index > currentCard ? '--after' : ''}`}
        key={section.name}
        onClick={() => handleCardClick(index)}>
        <div className="card__header">
          <h3 className="card__title">{I18n.t(`onboardings.processing_onboarding.sections.${section.name}`)}</h3>
        </div>
        <div className="card__body">
          <ul>{links(section)}</ul>
        </div>
        <div className="card__footer">
          {index > 0 && (
            <button type="button" className="qmb-control--icon --prev" onClick={handlePrevCtrl}>
              <i className="fa-light fa-arrow-left" />
            </button>
          )}

          {section.done && (
            <i className={`fa-solid ${index === currentCard ? 'fa-circle-check' : 'fa-circle-check'}`} />
          )}

          {index < sections.length - 1 && (
            <button type="button" className="qmb-control--icon --next" onClick={handleNextCtrl}>
              <i className="fa-light fa-arrow-right" />
            </button>
          )}
        </div>
      </div>
    );
  });

  return (
    <>
      <p className="walkthrough__intro">{I18n.t('onboardings.current_step_instructions.welcome_text')}</p>
      <div className="walkthrough__contain">
        <div
          className="walkthrough__collection"
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}>
          {sectionsList}
        </div>
        <button
          className="qmb-control--sm--dim walkthrough__dismiss"
          type="button"
          data-js-confirmation-modal-trigger
          data-needs-confirm
          data-link={skipOnboardingLink.link}
          data-link-method="delete">
          {skipOnboardingLink.text}
        </button>
      </div>
    </>
  );
}

Onboarding.defaultProps = {
  sections: [],
  skipOnboardingLink: {}
};

Onboarding.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object),
  skipOnboardingLink: PropTypes.object
};

export default Onboarding;
