import React, { createContext, useMemo } from 'react';
import toast, { Toaster } from 'react-hot-toast';
const defaultContext = {
    alertSuccess: (message) => {
        alert('todo');
    },
    alertError: (message) => {
        alert('todo');
    }
};
export const ToastContext = createContext(defaultContext);
export function ToastContextProvider(params) {
    const contextData = useMemo(() => {
        const value = {
            alertSuccess: (message) => toast.success(message),
            alertError: (message) => toast.error(message)
        };
        return value;
    }, []);
    return (React.createElement(ToastContext.Provider, { value: contextData },
        params.children,
        " ",
        React.createElement(Toaster, { position: "bottom-right" })));
}
