var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { SignatureFormContext } from '../SignatureFormContext';
import TechnicianDropdown from '../../../components/Dropdowns/TechnicianDropdown';
import Brushaway from '../../../components/Brushaway';
import { TenantContext } from '../../../contexts/tenant-context';
function NewSignatureForm() {
    var _a, _b, _c, _d;
    const { signature, signatureTypes, technicians, closeForm, postSignature } = useContext(SignatureFormContext);
    const { tenant } = useContext(TenantContext);
    const [formData, setFormData] = useState(signature);
    const [isValid, setIsValid] = useState(false);
    const canvasRef = useRef(null);
    const [signatureData, setSignatureData] = useState(null);
    const type = useMemo(() => {
        return signatureTypes.find((x) => x.key === formData.signatureType);
    }, [formData]);
    useEffect(() => {
        var _a;
        const nameValid = formData.name.trim() !== '';
        const signatureValid = !((_a = canvasRef.current) === null || _a === void 0 ? void 0 : _a.isEmpty());
        const certificationValid = formData.signatureType === 'inspector' && formData.technicianId === null
            ? formData.certificationNumber !== null && formData.certificationNumber.trim() !== '' : true;
        setIsValid(nameValid && signatureValid && certificationValid);
    }, [formData, signatureData]);
    const handleNameChange = (value) => {
        setFormData((prevState) => (Object.assign(Object.assign({}, prevState), { name: value })));
    };
    const handleTechnicianChange = (technician) => {
        var _a;
        setFormData((prevState) => {
            var _a;
            return (Object.assign(Object.assign({}, prevState), { technicianId: (_a = technician === null || technician === void 0 ? void 0 : technician.id) !== null && _a !== void 0 ? _a : null, certificationNumber: null }));
        });
        handleNameChange((_a = technician === null || technician === void 0 ? void 0 : technician.name) !== null && _a !== void 0 ? _a : '');
    };
    const handleCertificationChange = (value) => {
        setFormData((prevState) => (Object.assign(Object.assign({}, prevState), { certificationNumber: value.trim() === '' ? null : value })));
    };
    const handleSubmitForm = () => __awaiter(this, void 0, void 0, function* () {
        if (!canvasRef.current)
            return false;
        const dataUrl = canvasRef.current.getCanvas().toDataURL('image/png');
        return fetch(dataUrl)
            .then((res) => res.blob())
            .then((blob) => __awaiter(this, void 0, void 0, function* () {
            const file = new File([blob], `signature_${formData.signedAt.getTime()}_${formData.resourceType}_${formData.resourceId}`, { type: 'image/png' });
            yield postSignature(Object.assign(Object.assign({}, formData), { image: file }));
            return true;
        }))
            .catch(() => {
            return false;
        });
    });
    const handleCanvasClear = () => {
        if (canvasRef.current) {
            canvasRef.current.clear();
            setSignatureData(null);
        }
    };
    return (React.createElement(Brushaway, { title: "", open: true, onClose: closeForm, isForm: true, submitButtonText: "Sign", onSubmit: handleSubmitForm, isValid: isValid },
        React.createElement("div", { className: "dialog__contain" },
            React.createElement("header", { className: "dialog__header", style: { paddingLeft: 0 } },
                React.createElement("h1", { className: "dialog__title" },
                    type.label,
                    " Signature")),
            React.createElement("section", { className: "dialog__section information-container" },
                React.createElement("h2", { className: "dialog__title" }, "Disclaimer"),
                React.createElement("p", null, type.blurb),
                React.createElement("div", { className: "horizontal-split" }, "\u00A0"),
                type.key === 'inspector' && (React.createElement(TechnicianDropdown, { disabled: false, onChange: (targetTechnician) => {
                        handleTechnicianChange(targetTechnician);
                    }, allowEmpty: (_a = tenant === null || tenant === void 0 ? void 0 : tenant.flags) === null || _a === void 0 ? void 0 : _a.isOtherSignatureEnabled, emptyLabel: "Other", emptyPosition: 'end', selectedId: (_b = formData.technicianId) !== null && _b !== void 0 ? _b : undefined, technicianList: technicians })),
                React.createElement("div", { className: "qmb-input--text--x-full form__field" },
                    React.createElement("input", { id: "name", value: formData.name, onChange: (e) => handleNameChange(e.target.value) }),
                    React.createElement("label", { className: "qmb-label--required", htmlFor: "name" }, "Name")),
                type.key === 'inspector' && formData.technicianId === null && ((_c = tenant === null || tenant === void 0 ? void 0 : tenant.flags) === null || _c === void 0 ? void 0 : _c.isOtherSignatureEnabled) && (React.createElement("div", { className: "qmb-input--text--x-full form__field" },
                    React.createElement("input", { id: "certification", value: (_d = formData.certificationNumber) !== null && _d !== void 0 ? _d : '', onChange: (e) => handleCertificationChange(e.target.value) }),
                    React.createElement("label", { className: "qmb-label--required", htmlFor: "certification" }, "Certification #"))),
                React.createElement("p", { className: "disclaimer" }, "By signing, you agree to the disclaimer."),
                React.createElement("div", { className: "qmb-select --x-full signature-pad" },
                    React.createElement(SignatureCanvas, { ref: canvasRef, canvasProps: { width: 400, height: 150, className: 'signature-pad-canvas' }, onEnd: () => { setSignatureData(canvasRef.current.toDataURL()); } }),
                    React.createElement("label", { className: "qmb-label--required" },
                        "Signature",
                        React.createElement("button", { className: "button fa-regular fa-eraser", onClick: () => handleCanvasClear() }))),
                React.createElement("div", { className: "horizontal-split" }, "\u00A0")))));
}
export default NewSignatureForm;
