import { useCallback, useState } from 'react';
import useVisitProcessor from '@hooks/processors/use-visit-processor';
import apiClient from '@utils/api-client';
import { apiInternalVisitPath } from '../../routes';
function useVisitPatch() {
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState();
    const [result, setResult] = useState();
    const { processVisit } = useVisitProcessor();
    const save = useCallback((visit) => {
        var _a, _b, _c, _d;
        const controller = new AbortController();
        setSaving(true);
        const patchData = {
            scheduledDate: visit.start.toISOString(),
            visitStatus: visit.status.key,
            assigneeType: visit.assignee.type,
            technicianId: visit.assignee.type === "technician" /* AssigneeType.TECHNICIAN */ ? (_b = (_a = visit.assignee.info) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null : null,
            subcontractorId: visit.assignee.type === "subcontractor" /* AssigneeType.SUBCONTRACTOR */ ? (_d = (_c = visit.assignee.info) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : null : null,
            duration: visit.durationMins,
            subcontractorBillingLimit: 'billingLimit' in visit ? visit.billingLimit : undefined,
            subcontractorPoNumber: 'poNumber' in visit ? visit.poNumber : undefined
        };
        apiClient
            .patch(apiInternalVisitPath(visit.id), undefined, {
            params: {
                visit: patchData
            },
            signal: controller.signal
        })
            .then((response) => {
            const pathResult = response.data.data;
            setResult(processVisit(pathResult));
        })
            .catch((err) => {
            if (!controller.signal.aborted) {
                setResult(undefined);
                setError(err);
            }
        })
            .finally(() => {
            if (!controller.signal.aborted)
                setSaving(false);
        });
    }, [processVisit]);
    return { save, saving, error, result };
}
export default useVisitPatch;
