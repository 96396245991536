/* eslint-disable react/require-default-props */
import React from 'react';
import { translate } from '@utils/i18n';
import { namespace } from '../constants';
import { snakeToCapitalize } from '../utils/utils';
export default function DeficiencyInfo({ deficiency }) {
    var _a, _b;
    return (React.createElement("div", { className: "deficiencyInfoList" },
        deficiency.status && (React.createElement("div", { className: "deficiencyTags" },
            React.createElement("span", null, snakeToCapitalize(deficiency.status)))),
        React.createElement("div", { className: "deficiencyAdditionalInfo" },
            React.createElement("div", null,
                React.createElement("span", { className: "deficiencyAdditionalInfoKey" }, translate('page_no', { namespace })),
                React.createElement("span", { className: "deficiencyAdditionalInfoValue" }, (_a = deficiency.page_no) !== null && _a !== void 0 ? _a : '--')),
            React.createElement("div", null,
                React.createElement("span", { className: "deficiencyAdditionalInfoKey" }, translate('description', { namespace })),
                React.createElement("span", { className: "deficiencyAdditionalInfoValue" }, (_b = deficiency.description) !== null && _b !== void 0 ? _b : '--')))));
}
