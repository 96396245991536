import React, { useEffect, useState } from 'react';
import Modal from '@components/Modal';
import Button, { ButtonVariant } from '@components/Button/Button';
import LoadableButton from '@components/Button/LoadableButton';
import InputTextArea, { StyleVariant } from '@components/InputTextArea/InputTextArea';
import { translate } from '@utils/i18n';
import { namespace } from '../constants';
export default function FeedbackModal(props) {
    const [feedbackText, setFeedbackText] = useState('');
    useEffect(() => {
        setFeedbackText('');
    }, [props.isOpen]);
    return (React.createElement(Modal, { isOpen: props.isOpen, title: translate('how_did_we_do', { namespace }), height: 380, width: 670 },
        React.createElement("div", { className: "feedbackModalContainer" },
            React.createElement("p", { className: "descriptionContainer" }, translate('feedback_description', { namespace })),
            React.createElement(InputTextArea, { id: "feedback", className: "feedbackTextArea", value: feedbackText, name: "feedback", onChange: setFeedbackText, buttonControlled: false, variant: StyleVariant.Light, label: translate('feedback', { namespace }) }),
            React.createElement("section", { className: "feedbackModalFooter" },
                React.createElement(Button, { variant: ButtonVariant.Outline, onClick: props.onClose }, translate('cancel', { namespace: 'features.commons' })),
                React.createElement(LoadableButton, { loading: props.isLoading, variant: ButtonVariant.Highlight, onClick: () => props.onSubmit(feedbackText) }, translate('submit', { namespace: 'features.commons' }))))));
}
