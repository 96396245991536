import React, { useCallback, useMemo, useState } from 'react';
import Chip from '@components/Chip/chip';
function StatusMultiSelectList(params) {
    const [searchValue, setSearchValue] = useState('');
    const handleStatusToggle = useCallback((selectedStatus) => {
        if (params.selectedStatuses.some((x) => x.key === selectedStatus.key))
            params.onChange(params.selectedStatuses.filter((x) => x.key !== selectedStatus.key));
        else
            params.onChange([...params.selectedStatuses, selectedStatus]);
    }, [params]);
    const handleReset = useCallback(() => {
        params.onChange(params.defaultOptions);
    }, [params]);
    const disabled = useMemo(() => {
        const selectedKeys = new Set(params.selectedStatuses.map((status) => status.key));
        const defaultKeys = new Set(params.defaultOptions.map((status) => status.key));
        return selectedKeys.size === defaultKeys.size && [...selectedKeys].every((key) => defaultKeys.has(key));
    }, [params.selectedStatuses, params.defaultOptions]);
    return (React.createElement("div", { className: "qmb-popup--actions--y-max" },
        React.createElement("div", { className: "popup__section" },
            React.createElement("input", { placeholder: params.searchPlaceholder, onChange: (e) => setSearchValue(e.target.value), style: { border: 'none', padding: '1.2rem' } })),
        React.createElement("hr", { className: "popup__split" }),
        React.createElement("div", { className: "popup__section--y-fill" },
            React.createElement("ul", { role: "presentation" }, params.statusList
                .filter((x) => x.label.toLowerCase().includes(searchValue === null || searchValue === void 0 ? void 0 : searchValue.toLowerCase()))
                .map((status) => (React.createElement("li", { key: `status-option-${status.key}` },
                React.createElement("label", { className: "qmb-checkbox" },
                    React.createElement("input", { type: "checkbox", checked: params.selectedStatuses.some((x) => x.key === status.key), onChange: () => handleStatusToggle(status) }),
                    React.createElement(Chip, { icon: React.createElement(StatusIcon, { status: status }), label: status.label }))))))),
        React.createElement("hr", { className: "popup__split" }),
        React.createElement("div", { className: "popup__section" },
            React.createElement("ul", { role: "presentation" },
                React.createElement("li", null,
                    React.createElement("div", { className: "messageContainer" },
                        React.createElement("button", { type: "button", disabled: disabled, className: "qmb-control--sm--reset", onClick: handleReset },
                            React.createElement("i", { className: "fa-light fa-arrow-rotate-left" }),
                            "Reset")))))));
}
function StatusIcon({ status }) {
    return (React.createElement("span", { className: " qmb-status" },
        React.createElement("i", { title: status.label, className: status.icon, style: { color: status.color } })));
}
export default StatusMultiSelectList;
