import React, { useState } from 'react';
import VisitModal from './index';
function VisitModalTmpButton(params) {
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleSave = () => {
        window.location.reload();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { onClick: handleClick }, "Visit Modal"),
        open && React.createElement(VisitModal, { id: params.id, onClose: handleClose, onSave: handleSave })));
}
export default VisitModalTmpButton;
