/* eslint-disable react/require-default-props */
import React, { useMemo } from 'react';
import { translate } from '@utils/i18n';
function TechnicianDropdown({ technicianList, selectedId, disabled, allowEmpty = true, emptyLabel = 'Unassigned', emptyPosition = 'beginning', onChange }) {
    const namespace = 'components.technician_dropdown';
    const technicianOptions = useMemo(() => {
        const options = [];
        if (allowEmpty && emptyPosition === 'beginning') {
            options.push(React.createElement("option", { key: "technician-unassigned" }, emptyLabel !== null && emptyLabel !== void 0 ? emptyLabel : 'Unassigned'));
        }
        options.push(...technicianList.map((tech) => (React.createElement("option", { key: `technician-${tech.id}`, value: tech.id }, tech.name))));
        if (allowEmpty && emptyPosition === 'end') {
            options.push(React.createElement("option", { key: "technician-unassigned" }, emptyLabel !== null && emptyLabel !== void 0 ? emptyLabel : 'Unassigned'));
        }
        return options;
    }, [technicianList, allowEmpty, emptyPosition]);
    return (React.createElement("div", { className: "qmb-select--x-full form__field" },
        React.createElement("select", { className: "tech-select additional-tech", value: selectedId, onChange: (e) => {
                const selectedTechId = Number(e.target.value);
                const selectedTech = technicianList.find((tech) => tech.id === selectedTechId);
                onChange === null || onChange === void 0 ? void 0 : onChange(selectedTech !== null && selectedTech !== void 0 ? selectedTech : null);
            }, disabled: disabled }, technicianOptions),
        React.createElement("label", { className: "qmb-label" }, translate('technician', { namespace }))));
}
export default TechnicianDropdown;
