import { useContext, useEffect, useState } from 'react';
import { TimeZoneContext } from '@contexts/timezone-context';
import apiClient from '@utils/api-client';
import { transformDateToDayJs } from '@utils/dateUtils';
import { apiInternalSignaturesPath } from '../../routes';
const useGetSignatures = (queryParams) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const { timezone } = useContext(TimeZoneContext);
    useEffect(() => {
        if (queryParams === null) {
            setData([]);
            return;
        }
        const controller = new AbortController();
        setLoading(true);
        setError(undefined);
        apiClient
            .get(apiInternalSignaturesPath(), {
            params: queryParams,
            signal: controller.signal
        })
            .then((response) => {
            const processedData = response.data.data.map((result) => (Object.assign(Object.assign({}, result), { signatureDate: transformDateToDayJs(result.signatureDate, timezone) })));
            setData(processedData);
        })
            .catch((err) => {
            console.log(err);
            if (!controller.signal.aborted) {
                setData([]);
                setError(err);
            }
        })
            .finally(() => {
            if (!controller.signal.aborted) {
                setLoading(false);
            }
        });
        return () => {
            controller.abort();
        };
    }, [queryParams]);
    return { signatures: data, loading, error };
};
export default useGetSignatures;
