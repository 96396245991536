import React, { useContext, useMemo } from 'react';
import { technicianAvatar } from '@utils/functions/technician.functions';
import { CalendarContext } from '../../CalendarContext';
function Holiday(params) {
    const rightSideIcon = useMemo(() => {
        return technicianAvatar(undefined, 16);
    }, [params]);
    const { state: { detailed } } = useContext(CalendarContext);
    return (React.createElement("div", { className: `cal__event --holiday --unassigned ${detailed ? '--detailed' : ''}` },
        React.createElement("div", { className: "event__crux" },
            React.createElement("span", { className: "event__type" },
                React.createElement("i", { className: "fa-light fa-umbrella-beach" })),
            React.createElement("span", { className: "event__time" }, params.holiday.name)),
        React.createElement("div", { className: "event__title" }, params.holiday.label),
        React.createElement("div", { className: "event__indicate" }, rightSideIcon)));
}
export default Holiday;
