var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { translate } from '@utils/i18n';
import { AIDeficiencyReviewStatus, ReviewStatus } from '../types';
import { deleteDocument, getDocuments, getDocumentsTypes, runAI } from '../services/DocumentService';
import { PollingInterval, ToastDuration } from '../constants';
const namespace = 'features.documents';
function useDocuments({ workOrderId, inspectionId, isPrime }) {
    const queryClient = useQueryClient();
    const [documentDetail, setDocumentDetail] = useState(null);
    const [confirmModalInfo, setConfirmModalInfo] = useState(null);
    const [addModalInfo, setAddModalInfo] = useState(null);
    const [idLoading, setIdLoading] = useState(null);
    const { data: documents } = useQuery({
        queryKey: ['documents', inspectionId || workOrderId],
        queryFn: () => getDocuments({ id: inspectionId || workOrderId || 0, isInspection: !!inspectionId }),
        enabled: !!inspectionId || !!workOrderId,
        onError: () => {
            toast.error(translate('load_document_error', { namespace }), {
                position: 'bottom-right',
                duration: ToastDuration
            });
            return false;
        },
        refetchInterval: isPrime && !documentDetail ? PollingInterval : undefined,
        refetchOnMount: isPrime && !documentDetail,
        refetchOnWindowFocus: isPrime && !documentDetail
    });
    const { data: documentsType } = useQuery({
        queryKey: ['documentsType', inspectionId || workOrderId],
        queryFn: () => getDocumentsTypes({ id: inspectionId || workOrderId || 0, isInspection: !!inspectionId }),
        enabled: !!inspectionId || !!workOrderId,
        onError: () => {
            toast.error(translate('load_document_types_error', { namespace }), {
                position: 'bottom-right',
                duration: ToastDuration
            });
            return false;
        },
        refetchOnMount: false,
        refetchOnWindowFocus: false
    });
    const deleteDocumentMutation = useMutation({
        mutationFn: ({ docId, parentId, isInspection }) => deleteDocument({ docId, parentId, isInspection }),
        onError: () => {
            toast.error(translate('delete_document_error', { namespace }), {
                position: 'bottom-right',
                duration: ToastDuration
            });
            setIdLoading(null);
        },
        onSuccess: ({ id }) => {
            queryClient.setQueryData(['documents', inspectionId || workOrderId], [...(documents || []).filter((doc) => doc.id !== id)]);
        }
    });
    const runAiMutation = useMutation({
        mutationFn: ({ docId }) => runAI({ docId, inspectionId: inspectionId || 0 }),
        onError: () => {
            toast.error(translate('run_ai_error', { namespace }), {
                position: 'bottom-right',
                duration: ToastDuration
            });
            setIdLoading(null);
        },
        onSuccess: (documentId) => {
            queryClient.setQueryData(['documents', inspectionId], documents === null || documents === void 0 ? void 0 : documents.map((doc) => (doc.id === documentId ? Object.assign(Object.assign({}, doc), { status: ReviewStatus.Scanning }) : doc)));
        }
    });
    useEffect(() => {
        if (documentDetail) {
            setDocumentDetail((documents === null || documents === void 0 ? void 0 : documents.find((doc) => doc.id === documentDetail.id)) || null);
        }
    }, [documents]);
    const onRequestAdd = (documentType) => {
        setAddModalInfo({ documentType });
    };
    const closeAddModal = () => {
        setAddModalInfo(null);
    };
    const toggleSendToPrimeBtn = (allowedSendForReview) => {
        var _a, _b;
        if (allowedSendForReview) {
            (_a = document.getElementById('submitToPrimeBtn')) === null || _a === void 0 ? void 0 : _a.removeAttribute('disabled');
        }
        else {
            (_b = document.getElementById('submitToPrimeBtn')) === null || _b === void 0 ? void 0 : _b.setAttribute('disabled', 'disabled');
        }
    };
    // eslint-disable-next-line camelcase
    const onAddDocument = (res) => {
        toggleSendToPrimeBtn(res.allowed_send_for_review);
        queryClient.setQueryData(['documents', inspectionId || workOrderId], [...(documents || []), ...res.documents]);
        closeAddModal();
    };
    const groupedDocuments = useMemo(() => {
        return (documentsType || []).reduce((acc, type) => {
            acc[type.id] = (documents || []).filter((document) => document.document_type_id === type.id);
            return acc;
        }, {});
    }, [documentsType, documents]);
    const groupedPrimeDocuments = useMemo(() => {
        const subs = (documents || [])
            .map((doc) => doc.uploader_tenant)
            .reduce((acc, cur) => {
            return acc.some((ten) => (ten === null || ten === void 0 ? void 0 : ten.id) === (cur === null || cur === void 0 ? void 0 : cur.id)) ? acc : [...acc, cur];
        }, []);
        return subs
            .map((sub) => {
            return {
                name: (sub === null || sub === void 0 ? void 0 : sub.name) || 'N/A',
                docs: (documentsType || [])
                    .map((type) => {
                    return {
                        title: `${type.title} ${type.is_required ? '*' : ''}`,
                        data: (documents || []).filter((document) => { var _a; return document.document_type_id === type.id && ((_a = document.uploader_tenant) === null || _a === void 0 ? void 0 : _a.id) === (sub === null || sub === void 0 ? void 0 : sub.id); })
                    };
                }, {})
                    .filter((type) => type.data.length > 0)
            };
        })
            .sort((a, b) => {
            if (a.name === 'N/A')
                return 1;
            if (b.name === 'N/A')
                return -1;
            return 0;
        });
    }, [documentsType, documents]);
    const onDeleteDocument = (documentId) => __awaiter(this, void 0, void 0, function* () {
        closeConfirmModal();
        setIdLoading(documentId);
        const result = yield deleteDocumentMutation.mutateAsync({
            docId: documentId,
            parentId: inspectionId || workOrderId || 0,
            isInspection: !!inspectionId
        });
        toggleSendToPrimeBtn(result.allowed_send_for_review);
        setIdLoading(null);
    });
    const onRequestScan = (documentId) => __awaiter(this, void 0, void 0, function* () {
        setIdLoading(documentId);
        yield runAiMutation.mutateAsync({
            docId: documentId,
            inspectionId: inspectionId || 0
        });
        setIdLoading(null);
    });
    const onRequestDelete = (documentId) => {
        setConfirmModalInfo({
            description: translate('confirm_delete_desc', { namespace }),
            title: translate('confirm_delete_title', { namespace }),
            onConfirm: () => {
                void onDeleteDocument(documentId);
            }
        });
    };
    const closeConfirmModal = () => {
        setConfirmModalInfo(null);
    };
    const isAIProcessing = useMemo(() => {
        return !!(documents === null || documents === void 0 ? void 0 : documents.some((doc) => doc.status === ReviewStatus.Scanning));
    }, [documents]);
    const pendingDocsForReview = useMemo(() => {
        var _a;
        return (_a = documents === null || documents === void 0 ? void 0 : documents.filter((doc) => doc.status !== ReviewStatus.Completed && doc.canAi).length) !== null && _a !== void 0 ? _a : 0;
    }, [documents]);
    const onFinishReviewDeficiencies = (document) => {
        setDocumentDetail(null);
        toast.success(translate('success_add', {
            namespace,
            addedCount: document.deficiencies.filter((def) => def.reviewStatus === AIDeficiencyReviewStatus.Correct).length
        }), {
            position: 'bottom-right',
            duration: ToastDuration
        });
    };
    return {
        addModalInfo,
        groupedDocuments,
        confirmModalInfo,
        idLoading,
        documentDetail,
        documentsType: documentsType || [],
        groupedPrimeDocuments,
        isAIProcessing,
        pendingDocsForReview,
        onFinishReviewDeficiencies,
        onAddDocument,
        onRequestScan,
        onRequestDelete,
        closeConfirmModal,
        setDocumentDetail,
        onRequestAdd,
        closeAddModal
    };
}
export default useDocuments;
