import { useEffect, useState } from 'react';
import apiClient from '@utils/api-client';
import { apiInternalInspectionStatusesPath, apiInternalServiceVisitStatusesPath } from '../../routes';
const useGetVisitStatuses = (queryParams) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    useEffect(() => {
        var _a;
        if (!queryParams)
            return;
        setLoading(true);
        const controller = new AbortController();
        let url;
        switch ((_a = queryParams.filter) === null || _a === void 0 ? void 0 : _a.type) {
            case "WorkOrder" /* VisitableType.SERVICE */:
                url = apiInternalServiceVisitStatusesPath();
                break;
            case "Inspection" /* VisitableType.INSPECTION */:
                url = apiInternalInspectionStatusesPath();
                break;
            default:
                console.error(`Unable to fetch visit statuses. Unknown visitable type.`);
                return;
        }
        apiClient
            .get(url, {
            params: queryParams,
            signal: controller.signal
        })
            .then((response) => {
            setData(response.data.data);
        })
            .catch((err) => {
            if (!controller.signal.aborted) {
                setData([]);
                setError(err);
            }
        })
            .finally(() => {
            if (!controller.signal.aborted) {
                setLoading(false);
            }
        });
        return () => {
            controller.abort();
        };
    }, [queryParams]);
    return { data, loading, error };
};
export default useGetVisitStatuses;
