import React from 'react';
import I18n from '@utils/i18n';
import PropTypes from 'prop-types';
import { isProduction } from '@utils/environment';

// written with jquery, deprecated
import DateRangePicker from '@components/Kendo/DateRangePicker';
import SearchSelect from '@components/Kendo/SearchSelect';
import OldCommonSelect from '@components/Kendo/CommonSelect';
import MultiSelect from '@components/Kendo/MultiSelect';

import MultiSelectWithAvatar from './nested/MultiSelectWithAvatar';
import MapTechFilter from './nested/MapTechFilter';
import GenericMultiSelect from '../Multiselects/GenericMultiSelect/GenericMultiSelect.tsx';
import TreeMultiSelect from '../Multiselects/TreeMultiSelect/TreeMultiSelect.tsx';
import FilterTypes from '../../constants/FilterTypes.ts';

import CommonSelect from './nested/CommonSelect';
import TextBox from './nested/TextBox';
import BooleanSelect from './nested/BooleanSelect';
import ReactMultiSelect from './nested/MultiSelect';
import PagedMultiSelect from './nested/PagedMultiSelect';
import CheckboxFilter from './nested/CheckboxFilter';
import TreeSelect from './nested/TreeSelect';
import FilterToggle from './nested/FilterToggle';
import AssigneeMultiSelect from '../Multiselects/AssigneeMultiselect/index.tsx';
import StatusMultiSelect from '../Multiselects/StatusMultiSelect';

const NAMESPACE = 'filters';

function FilterPanel({ filters, onResetFilters, onResetCache, children, toggleDisabled }) {
  const dataUrl = (filter) => {
    let queryUrl = `/lookup${filter.field === 'building' ? '/buildings' : ''}?type=${
      filter.backendField || filter.locale
    }`;

    if (filter.withUnassigned) {
      queryUrl += '&with_unassigned=true';
    }

    if (filter.onlyFullTech) {
      queryUrl += '&full_tech_only=true';
    }

    return queryUrl;
  };

  const valueMapUrl = (filter) => {
    let queryUrl = `/lookup/map?type=${filter.backendField || filter.locale}`;

    if (filter.withUnassigned) {
      queryUrl += '&with_unassigned=true';
    }

    if (filter.onlyFullTech) {
      queryUrl += '&full_tech_only=true';
    }

    return queryUrl;
  };

  return (
    <article id="filters" className="qmb-filters">
      <span className="filters__label">{I18n.t(`${NAMESPACE}.title`)}</span>
      <fieldset className="filters__group">
        {filters
          .filter((filter) => filter.active && filter.type !== 'hidden')
          .map((filter) => (
            <React.Fragment key={filter.field}>
              {filter.type === FilterTypes.SearchSelect && (
                <div className={`filters__option ${filter.value ? '--active' : ''}`}>
                  <SearchSelect
                    name={filter.field}
                    value={filter.value}
                    dataUrl={dataUrl(filter)}
                    valueMapUrl={valueMapUrl(filter)}
                    label={I18n.t(`${NAMESPACE}.${filter.locale}`)}
                    placeholder={I18n.t('generic.all')}
                    template={filter.template}
                    valueTemplate={filter.valueTemplate}
                    itemHeight={filter.itemHeight}
                    selectOptions={{
                      id: filter.field,
                      onChange: filter.onChange
                    }}
                  />
                </div>
              )}
              {filter.type === FilterTypes.GenericMultiSelect && (
                <div className={`filters__option ${(filter.value || []).length > 0 ? '--active' : ''}`}>
                  <GenericMultiSelect
                    name={filter.field}
                    allowLetterAvatar={filter.allowLetterAvatar}
                    additionalOptions={filter.additionalOptions}
                    value={filter.value || []}
                    dataUrl={dataUrl(filter)}
                    valueMapUrl={valueMapUrl(filter)}
                    fixedOptions={filter.optionsForSelect}
                    disableSorting={filter.disableSorting}
                    single={filter.single}
                    label={I18n.t(`${NAMESPACE}.${filter.locale}`)}
                    placeholder={`Search ${I18n.t(`${NAMESPACE}.plural_${filter.locale}`)}`}
                    template={filter.template}
                    valueTemplate={filter.valueTemplate}
                    itemHeight={filter.itemHeight}
                    onChange={filter.onChange}
                    boolean={filter.boolean}
                  />
                </div>
              )}

              {filter.type === 'commonSelect' && (
                <div className={`filters__option ${filter.value ? '--active' : ''}`}>
                  <CommonSelect
                    name={filter.field}
                    value={filter.value}
                    elements={filter.optionsForSelect}
                    label={I18n.t(`${NAMESPACE}.${filter.locale}`)}
                    placeholder={filter.placeholder}
                    onChange={filter.onChange}
                  />
                </div>
              )}

              {filter.type === 'oldCommonSelect' && (
                <div className={`filters__option--native ${filter.value ? '--active' : ''}`}>
                  <OldCommonSelect
                    name={filter.field}
                    value={filter.value}
                    elements={filter.optionsForSelect}
                    label={I18n.t(`${NAMESPACE}.${filter.locale}`)}
                    placeholder={filter.placeholder ? I18n.t(filter.placeholder) : I18n.t('generic.all')}
                    selectOptions={{ id: filter.field, onChange: filter.onChange }}
                  />
                </div>
              )}

              {filter.type === 'booleanSelect' && (
                <div className={`filters__option ${filter.value ? '--active' : ''}`}>
                  <BooleanSelect
                    name={filter.field}
                    value={filter.value}
                    label={I18n.t(`${NAMESPACE}.${filter.locale}`)}
                    trueLabel={filter.trueLabel}
                    falseLabel={filter.falseLabel}
                    onChange={filter.onChange}
                  />
                </div>
              )}

              {filter.type === 'textBox' && (
                <div className={`filters__option--native ${filter.value ? '--active' : ''}`}>
                  <TextBox
                    name={filter.field}
                    value={filter.value}
                    label={I18n.t(`${NAMESPACE}.${filter.locale}`)}
                    onChange={filter.onChange}
                  />
                </div>
              )}

              {filter.type === 'multiSelectWithAvatar' && (
                <div
                  className={`filters__option--multi--avatars ${
                    filter.value && filter.value.length > 0 ? '--active' : ''
                  }`}>
                  <MultiSelectWithAvatar
                    name={filter.field}
                    value={filter.value || []}
                    dataUrl={dataUrl(filter)}
                    valueMapUrl={valueMapUrl(filter)}
                    label={I18n.t(`${NAMESPACE}.${filter.locale}`)}
                    placeholder={I18n.t('generic.all')}
                    selectOptions={{
                      id: filter.field,
                      onChange: filter.onChange
                    }}
                  />
                </div>
              )}

              {filter.type === 'dateRangePicker' && (
                <DateRangePicker
                  name={filter.field}
                  onAnyChange={filter.onChange}
                  values={filter.values || undefined}
                  label={I18n.t(`${NAMESPACE}.${filter.locale}`)}
                  reactOptions={{ id: filter.field }}
                  rangesOverride={filter.dateRanges || []}
                  message={filter.message}
                />
              )}

              {filter.type === 'multiSelect' && filter.field === 'technicians' && (
                <div
                  className={`filters__option--multi--avatars ${
                    filter.value && filter.value.length > 0 ? '--active' : ''
                  }`}>
                  <MapTechFilter
                    name={filter.field}
                    value={filter.value}
                    dataUrl="/lookup?type=technician"
                    valueMapUrl="/lookup/map?type=technician"
                    label={I18n.t(`${NAMESPACE}.technician`)}
                    placeholder={I18n.t(`${NAMESPACE}.all_placeholder`)}
                    selectOptions={{
                      id: 'technician',
                      className: '--avatars',
                      onChange: filter.onChange
                    }}
                  />
                </div>
              )}

              {filter.type === 'multiSelect' && filter.field !== 'technicians' && (
                <div
                  className={`filters__option--multi ${filter.withIcons ? '--avatars' : ''}
                                                        ${filter.value && filter.value.length > 0 ? '--active' : ''}`}>
                  <MultiSelect
                    name={filter.field}
                    value={filter.value}
                    dataUrl={dataUrl(filter)}
                    valueMapUrl={valueMapUrl(filter)}
                    label={I18n.t(`${NAMESPACE}.${filter.locale}`)}
                    placeholder={I18n.t('generic.all')}
                    tagTemplate={filter.tagTemplate}
                    itemTemplate={filter.itemTemplate}
                    selectOptions={{
                      id: filter.field,
                      className: filter.withIcons ? '--avatars' : '',
                      onChange: filter.onChange
                    }}
                  />
                </div>
              )}

              {filter.type === 'reactMultiSelect' && (
                <div className={`filters__option--multi ${filter.value && filter.value.length > 0 ? '--active' : ''}`}>
                  <ReactMultiSelect
                    name={filter.field}
                    value={filter.value}
                    backendField={filter.backendField || filter.locale}
                    label={I18n.t(`${NAMESPACE}.${filter.locale}`)}
                    placeholder={I18n.t('generic.all')}
                    onChange={filter.onChange}
                  />
                </div>
              )}

              {filter.type === 'pagedMultiSelect' && (
                <div className={`filters__option--multi ${filter.value && filter.value.length > 0 ? '--active' : ''}`}>
                  <PagedMultiSelect
                    name={filter.field}
                    value={filter.value}
                    backendField={filter.backendField || filter.locale}
                    label={I18n.t(`${NAMESPACE}.${filter.locale}`)}
                    placeholder={I18n.t('generic.all')}
                    onChange={filter.onChange}
                  />
                </div>
              )}

              {filter.type === 'checkbox' && (
                <div className="filters__option--plain--active">
                  <CheckboxFilter
                    name={filter.field}
                    value={filter.value}
                    label={I18n.t(`${NAMESPACE}.${filter.locale}`)}
                    onChange={filter.onChange}
                  />
                </div>
              )}
              {filter.type === 'treeSelect' && (
                <div className={`filters__option--multi ${filter.value && filter.value.length > 0 ? '--active' : ''}`}>
                  <TreeSelect
                    name={filter.field}
                    value={filter.value}
                    label={I18n.t(`${NAMESPACE}.${filter.locale}`)}
                    elements={filter.optionsForSelect}
                    onChange={filter.onChange}
                  />
                </div>
              )}
              {filter.type === FilterTypes.TreeMultiSelect && (
                <div className={`filters__option ${filter.value && filter.value.length > 0 ? '--active' : ''}`}>
                  <TreeMultiSelect
                    name={filter.field}
                    value={filter.value}
                    label={I18n.t(`${NAMESPACE}.${filter.locale}`)}
                    placeholder={`Search ${I18n.t(`${NAMESPACE}.plural_${filter.locale}`)}`}
                    options={filter.optionsForSelect}
                    onChange={filter.onChange}
                  />
                </div>
              )}
              {filter.type === 'assignee' && (
                <div
                  className={`filters__option--plain ${
                    filter.technicianIds.length + filter.subcontractorIds.length > 0 ? '--active' : ''
                  }`}>
                  <AssigneeMultiSelect
                    // eslint-disable-next-line react/style-prop-object
                    style="filter-panel"
                    teamIds={filter.teamIds}
                    selectedTechnicianIds={filter.technicianIds}
                    onTechChange={(selections) => {
                      filter.onTechChange(selections);
                    }}
                    selectedSubcontractorIds={filter.subcontractorIds}
                    onSubChange={(selections) => {
                      filter.onSubChange(selections);
                    }}
                  />
                </div>
              )}
              {filter.type === 'statuses' && (
                <div
                  className={`filters__option--plain ${
                    filter.inspectionStatusKeys.length + filter.visitStatusKeys.length > 0 ? '--active' : ''
                  }`}>
                  <StatusMultiSelect
                    selectedInspectionStatusKeys={filter.inspectionStatusKeys}
                    selectedVisitStatusKeys={filter.visitStatusKeys}
                    onInspectionStatusChange={(selections) => {
                      filter.onInspectionStatusChange(selections);
                    }}
                    onVisitStatusChange={(selections) => {
                      filter.onVisitStatusChange(selections);
                    }}
                    defaultInspectionStatusKeys={filter.defaultInspectionStatusKeys}
                    defaultVisitStatusKeys={filter.defaultVisitStatusKeys}
                  />
                </div>
              )}
            </React.Fragment>
          ))}
        <fieldset className="filters__actions">
          {!toggleDisabled && <FilterToggle filters={filters.filter((filter) => filter.type !== 'hidden')} />}
          <button type="button" className="qmb-control--sm--reset" onClick={onResetFilters}>
            <i className="fa-light fa-arrow-rotate-left" />
            {I18n.t(`${NAMESPACE}.reset`)}
          </button>
          {!isProduction && (
            <button type="button" tabIndex="-4" className="qmb-control--sm--reset" onClick={onResetCache}>
              {I18n.t(`${NAMESPACE}.reset_cache`)}
            </button>
          )}
        </fieldset>
      </fieldset>
      {children /* search, map toggle, etc */}
    </article>
  );
}

const onEventDefault = () => {
  console.error(`Please pass an event to the Filter component for this action.`);
};

FilterPanel.propTypes = {
  onResetFilters: PropTypes.func,
  toggleDisabled: PropTypes.bool,
  onResetCache: PropTypes.func,
  children: PropTypes.node,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      backendField: PropTypes.string,
      type: PropTypes.string,
      active: PropTypes.bool,
      locked: PropTypes.bool,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.bool,
        PropTypes.number,
        PropTypes.object
      ]),
      label: PropTypes.string,
      withUnassigned: PropTypes.bool,
      onlyFullTech: PropTypes.bool,
      optionsForSelect: PropTypes.arrayOf(PropTypes.object),
      onChange: PropTypes.func,
      onToggle: PropTypes.func,
      template: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      valueTemplate: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      itemHeight: PropTypes.number,
      tooltip: PropTypes.string
    })
  )
};

FilterPanel.defaultProps = {
  onResetFilters: onEventDefault,
  onResetCache: onEventDefault,
  toggleDisabled: false,
  children: null,
  filters: [
    {
      field: '',
      backendField: '',
      type: '',
      active: true,
      locked: false,
      value: '',
      label: '',
      withUnassigned: false,
      onlyFullTech: false,
      optionsForSelect: [],
      onChange: null,
      onToggle: null,
      template: undefined,
      valueTemplate: undefined,
      itemHeight: undefined,
      tooltip: undefined
    }
  ]
};

export default FilterPanel;
