import { useMemo } from 'react';
import useGetVisits from '@hooks/requests/visits.get.hook';
import { isSubcontractor, isTechnician } from '@utils/typeGuards/assignee.typeguard';
const useGetSchedulerVisits = (assignees, date) => {
    const visitsQuery = useMemo(() => {
        if (!assignees || assignees.length === 0) {
            return null;
        }
        const query = {
            select: 'all',
            filter: {
                type: ["WorkOrder" /* VisitableType.SERVICE */, "Inspection" /* VisitableType.INSPECTION */],
                range: {
                    start: date.startOf('day').toDate(),
                    end: date.endOf('day').toDate()
                },
                technicianIds: assignees.filter((a) => isTechnician(a)).map((tech) => tech.info.id),
                subcontractorIds: assignees.filter((a) => isSubcontractor(a)).map((sub) => sub.info.id)
            }
        };
        return query;
    }, [assignees, date]);
    const { data: visits, loading: visitsLoading, error: visitsError } = useGetVisits(visitsQuery);
    return { visits, visitsLoading };
};
export default useGetSchedulerVisits;
