import React, { createContext, useCallback, useMemo, useState } from 'react';
import VisitEditModal from '../features/Modals/VisitModal';
import TimeOffModal from '../features/Modals/TimeOffModal';
export const ModalContext = createContext(undefined);
export function ModalContextProvider(params) {
    const [modifiedEventWatch, setModifiedEventWatch] = useState(null);
    const [activeModal, setActiveModal] = useState(null);
    const openVisitModal = useCallback((id) => {
        setActiveModal(React.createElement(VisitEditModal, { id: id, onClose: () => {
                setActiveModal(null);
            }, onSave: (updatedVisit) => {
                setModifiedEventWatch(updatedVisit);
                setActiveModal(null);
            } }));
    }, []);
    const openTimeOffModal = useCallback((id) => {
        setActiveModal(React.createElement(TimeOffModal, { id: id, onClose: () => {
                setActiveModal(null);
            } }));
    }, []);
    const contextData = useMemo(() => {
        const value = {
            activeModal,
            openVisitModal,
            openTimeOffModal,
            modifiedEventWatch
        };
        return value;
    }, [activeModal, modifiedEventWatch]);
    return React.createElement(ModalContext.Provider, { value: contextData }, params.children);
}
