import { useCallback, useEffect, useState } from 'react';
import useVisitProcessor from '@hooks/processors/use-visit-processor';
import apiClient from '@utils/api-client';
import { apiInternalVisitPath } from '../../routes';
const useGetVisit = (id) => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const { processVisit } = useVisitProcessor();
    const fetchData = useCallback((fetchId, controller) => {
        setLoading(true);
        apiClient
            .get(apiInternalVisitPath(fetchId), {
            signal: controller.signal
        })
            .then((response) => {
            setData(processVisit(response.data.data));
        })
            .catch((err) => {
            if (!controller.signal.aborted) {
                setData(undefined);
                setError(err);
            }
        })
            .finally(() => {
            if (!controller.signal.aborted) {
                setLoading(false);
            }
        });
    }, []);
    const refetchData = useCallback(() => {
        const controller = new AbortController();
        fetchData(id, controller);
        return () => {
            controller.abort();
        };
    }, [id]);
    useEffect(() => {
        const controller = new AbortController();
        fetchData(id, controller);
        return () => {
            controller.abort();
        };
    }, [id]);
    return { data, loading, error, refetchData };
};
export default useGetVisit;
