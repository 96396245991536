import { useCallback, useContext, useState } from 'react';
import { TimeZoneContext } from '@contexts/timezone-context';
import apiClient from '@utils/api-client';
import { apiInternalSignaturesPath } from '../../routes';
import { transformDateToDayJs, transformDayJstoDate } from '@utils/dateUtils';
function usePostSignature() {
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState();
    const [result, setResult] = useState();
    const { timezone } = useContext(TimeZoneContext);
    const save = useCallback((signature) => {
        return new Promise((resolve, reject) => {
            const controller = new AbortController();
            setSaving(true);
            const formData = new FormData();
            Object.entries(signature).forEach(([key, value]) => {
                if (key === 'signatureDate') {
                    formData.append(`signature[${key}]`, transformDayJstoDate(value).toString());
                    return;
                }
                formData.append(`signature[${key}]`, value);
            });
            apiClient
                .post(apiInternalSignaturesPath(), formData, {
                signal: controller.signal,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                const postResult = response.data.data;
                setResult(Object.assign(Object.assign({}, postResult), { signatureDate: transformDateToDayJs(postResult.signatureDate, timezone) }));
            })
                .catch((err) => {
                if (!controller.signal.aborted) {
                    setResult(undefined);
                    setError(err);
                }
            })
                .finally(() => {
                if (!controller.signal.aborted)
                    setSaving(false);
            });
        });
    }, []);
    return { save, saving, error, result };
}
export default usePostSignature;
