import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { SchedulerContext } from '../../Contexts/SchedulerContext';
import CalculateClustering from './CalculateClustering';
const defaultContext = {
    assignee: { type: "technician" /* AssigneeType.TECHNICIAN */, key: `${"technician" /* AssigneeType.TECHNICIAN */}-null`, info: null }, // Default to unassigned technician
    showOnMap: true,
    events: [],
    clusteringData: {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    toggleMapExclusion: () => { }
};
export const SwimlaneContext = createContext(defaultContext);
export function SwimlaneContextProvider(params) {
    var _a, _b;
    const swimlaneKey = `${params.assignee.type}-${(_b = (_a = params.assignee.info) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 'unassigned'}`;
    const schedulerContext = useContext(SchedulerContext);
    if (!schedulerContext) {
        throw new Error('Scheduler Context not provided.');
    }
    const { eventsGroupedByAssignee, assigneesOnMap, setAssigneeShowOnMap } = schedulerContext;
    const showOnMap = assigneesOnMap === 'all' ? true : assigneesOnMap.some((x) => x === params.assignee.key);
    const { events, clusteringData } = useMemo(() => {
        var _a;
        const swimlaneEvents = (_a = eventsGroupedByAssignee[swimlaneKey]) !== null && _a !== void 0 ? _a : [];
        const swimlaneClusters = CalculateClustering(swimlaneEvents);
        return { events: swimlaneEvents, clusteringData: swimlaneClusters };
    }, [eventsGroupedByAssignee[swimlaneKey]]);
    const toggleMapExclusion = useCallback(() => {
        setAssigneeShowOnMap(params.assignee, !showOnMap);
    }, [showOnMap]);
    const contextData = useMemo(() => {
        const value = {
            assignee: params.assignee,
            showOnMap,
            events,
            clusteringData,
            toggleMapExclusion
        };
        return value;
    }, [params.assignee, events, clusteringData, showOnMap]);
    return React.createElement(SwimlaneContext.Provider, { value: contextData }, params.children);
}
