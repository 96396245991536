var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { dateClient } from '@utils/date-client';
import { transformDayJstoDate } from '@utils/dateUtils';
import Brushaway from '../../../components/Brushaway';
import TechnicianDropdown from '../../../components/Dropdowns/TechnicianDropdown';
import { SignatureFormContext } from '../SignatureFormContext';
import { TimeZoneContext } from '../../../contexts/timezone-context';
function EditSignatureForm() {
    var _a, _b;
    const { signature, signatureTypes, technicians, closeForm, patchSignature } = useContext(SignatureFormContext);
    const { timezone } = useContext(TimeZoneContext);
    const [formData, setFormData] = useState(signature);
    const [isValid, setIsValid] = useState(false);
    const type = useMemo(() => {
        return signatureTypes.find((x) => x.key === formData.signatureTypeKey);
    }, [formData, signatureTypes]);
    const handleDateChange = (e) => {
        const newDate = e.value;
        if (!newDate)
            return;
        const newDateProcessed = dateClient()
            .tz(timezone)
            .year(newDate.getFullYear())
            .month(newDate.getMonth())
            .date(newDate.getDate())
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0);
        setFormData((prevState) => (Object.assign(Object.assign({}, prevState), { signatureDate: newDateProcessed })));
    };
    const handleNameChange = (value) => {
        setFormData((prevState) => (Object.assign(Object.assign({}, prevState), { name: value })));
    };
    useEffect(() => {
        const nameValid = formData.name.trim() !== '';
        setIsValid(nameValid);
    }, [formData]);
    const handleSubmitForm = () => __awaiter(this, void 0, void 0, function* () {
        return patchSignature(formData)
            .then((result) => {
            return true;
        })
            .catch(() => {
            return false;
        });
    });
    const kendoDate = useMemo(() => {
        return transformDayJstoDate(formData.signatureDate);
    }, [formData.signatureDate]);
    return (React.createElement(Brushaway, { title: "", open: true, onClose: closeForm, isForm: true, submitButtonText: "Update", onSubmit: handleSubmitForm, isValid: isValid },
        React.createElement("div", { className: "dialog__contain" },
            React.createElement("header", { className: "dialog__header", style: { paddingLeft: 0 } },
                React.createElement("h1", { className: "dialog__title" }, type === null || type === void 0 ? void 0 :
                    type.label,
                    " Signature")),
            React.createElement("section", { className: "dialog__section information-container" },
                React.createElement("h2", { className: "dialog__title" }, "Disclaimer"),
                React.createElement("p", null, type === null || type === void 0 ? void 0 : type.blurb),
                React.createElement("div", { className: "horizontal-split" }, "\u00A0"),
                (type === null || type === void 0 ? void 0 : type.key) === 'inspector' && (React.createElement(TechnicianDropdown, { disabled: true, selectedId: (_a = formData.technicianId) !== null && _a !== void 0 ? _a : undefined, emptyLabel: "Other", technicianList: technicians })),
                React.createElement("div", { className: "qmb-input--text--x-full form__field" },
                    React.createElement("input", { id: "name", value: formData.name, onChange: (e) => handleNameChange(e.target.value) }),
                    React.createElement("label", { className: "qmb-label--required", htmlFor: "name" }, "Name")),
                (type === null || type === void 0 ? void 0 : type.key) === 'inspector' && formData.technicianId === null && (React.createElement("div", { className: "qmb-input--text--x-full form__field" },
                    React.createElement("input", { disabled: true, id: "certification", value: (_b = formData.certificationNumber) !== null && _b !== void 0 ? _b : '', onChange: undefined }),
                    React.createElement("label", { className: "qmb-label--required", htmlFor: "certification" }, "Certification #"))),
                React.createElement("p", { className: "disclaimer" }, "By signing, you agree to the disclaimer."),
                React.createElement("div", { className: "qmb-select --x-full" },
                    React.createElement("img", { className: "file-image", srcSet: formData.url, alt: "Signature", width: "400", height: "150" }),
                    React.createElement("label", { className: "qmb-label--required" }, "Signature")),
                React.createElement("fieldset", { className: "form__set--horizontal--compact" },
                    React.createElement("div", { className: "qmb-input--text--x-half form__field" },
                        React.createElement(DatePicker, { value: kendoDate, onChange: handleDateChange }),
                        React.createElement("label", { className: "qmb-label" }, "Signed at"))),
                React.createElement("div", { className: "horizontal-split" }, "\u00A0")))));
}
export default EditSignatureForm;
