import { useCallback, useContext, useState } from 'react';
import { TimeZoneContext } from '@contexts/timezone-context';
import apiClient from '@utils/api-client';
import { apiInternalSignaturePath } from '../../routes';
import { transformDateToDayJs, transformDayJstoDate } from '@utils/dateUtils';
function usePatchSignature() {
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState();
    const [result, setResult] = useState();
    const { timezone } = useContext(TimeZoneContext);
    const save = useCallback((signature) => {
        return new Promise((resolve, reject) => {
            const controller = new AbortController();
            setSaving(true);
            apiClient
                .patch(apiInternalSignaturePath(signature.id), undefined, {
                params: {
                    signature: {
                        signedAt: transformDayJstoDate(signature.signatureDate),
                        name: signature.name
                    }
                },
                signal: controller.signal
            })
                .then((response) => {
                const patchResult = response.data.data;
                setResult(Object.assign(Object.assign({}, patchResult), { signatureDate: transformDateToDayJs(patchResult.signatureDate, timezone) }));
            })
                .catch((err) => {
                if (!controller.signal.aborted) {
                    setResult(undefined);
                    setError(err);
                }
            })
                .finally(() => {
                if (!controller.signal.aborted)
                    setSaving(false);
            });
        });
    }, []);
    return { save, saving, error, result };
}
export default usePatchSignature;
