import React from 'react';
import './Banner.scss';
export var BannerVariant;
(function (BannerVariant) {
    BannerVariant["Info"] = "info";
    BannerVariant["Error"] = "error";
    BannerVariant["Warning"] = "warning";
    BannerVariant["Success"] = "success";
})(BannerVariant || (BannerVariant = {}));
export const BannerIcon = {
    [BannerVariant.Info]: 'fa-light fa-bell',
    [BannerVariant.Error]: 'fa-light fa-xmark',
    [BannerVariant.Warning]: 'fa-light fa-triangle-exclamation',
    [BannerVariant.Success]: 'fa-light fa-check'
};
export default function Banner({ text, variant }) {
    return (React.createElement("div", { className: `bannerContainer ${variant}` },
        React.createElement("i", { className: BannerIcon[variant] }),
        React.createElement("span", null, text)));
}
