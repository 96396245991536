import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CalendarContext } from '../CalendarContext';
import { subcontractorIcon } from '../../../utils/functions/subcontractor.functions.tsx';
import { technicianAvatar } from '../../../utils/functions/technician.functions.tsx';
import { blackOrWhiteForeground } from '../../../utils/colors.ts';

function Event({ fcProps }) {
  const {
    timeZone,
    state: { statuses, detailed, range }
  } = useContext(CalendarContext);

  const event = useMemo(
    () => ({
      ...fcProps.event.extendedProps,
      id: fcProps.event.id,
      title: fcProps.event.title,
      start: fcProps.event.startStr,
      end: fcProps.event.endStr
    }),
    [fcProps]
  );

  const status = useMemo(
    () => statuses[event.groupStatus] && event.statusObject,
    [statuses[event.groupStatus], event.statusObject]
  );
  const { technician, assignee } = event;

  const className = useMemo(
    () =>
      `cal__event ${detailed ? '--detailed' : ''} ${status ? '--status' : ''} ${
        event.type === 'TimeOff' ? '--holiday' : ''
      } ${!technician?.id ? '--unassigned' : ''}`,
    [detailed, status, event.technician]
  );

  const rightSideIcon = useMemo(() => {
    if (status) return <StatusIcon status={status} />;

    if (assignee.subcontractor !== undefined) {
      return subcontractorIcon(assignee.subcontractor, 16);
    }

    return technicianAvatar(assignee.technician, 16);
  }, [status, assignee]);

  return (
    <div
      className={className}
      style={{
        color: status ? '#000' : blackOrWhiteForeground(assignee?.technician?.color || assignee?.subcontractor?.color)
      }}>
      <div className="event__crux">
        <span className="event__type">
          {event.type === 'Inspection' && <i className="fa-light fa-clipboard-check" />}
          {event.type === 'Visit' && <i className="fa-light fa-screwdriver-wrench" />}
          {event.type === 'TimeOff' && <i className="fa-light fa-umbrella-beach" />}
        </span>
        <span className="event__time">
          {event.type === 'TimeOff' && event.title}
          {event.type !== 'TimeOff' &&
            new Date(event.start).toLocaleTimeString('en-US', {
              timeZone,
              hour: 'numeric',
              minute: '2-digit',
              hour12: true
            })}
        </span>
      </div>

      {(event.type !== 'TimeOff' ||
        (event.type === 'TimeOff' && !technician?.id && range?.view?.type === 'dayGridMonth')) && (
        <div className="event__title">{event.title}</div>
      )}

      <div className="event__indicate">{rightSideIcon}</div>

      {detailed && event.description && <div className="event__details">{event.description}</div>}
    </div>
  );
}

Event.propTypes = {
  fcProps: PropTypes.shape({
    event: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      startStr: PropTypes.string,
      endStr: PropTypes.string,
      extendedProps: PropTypes.object
    })
  }).isRequired
};

function TechIcon({ technician }) {
  if (!technician.photoUrl) {
    return (
      <span className="qmb-avatar--16">
        <svg viewBox="0 0 16 16">
          <circle cx="8" cy="8" r="8" style={{ fill: technician.color }} />
          <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle">
            {technician.name[0]}
          </text>
        </svg>
      </span>
    );
  }
  return (
    <span className="qmb-avatar--16">
      <img alt={technician.name} style={{ borderRadius: '50%' }} src={technician.photoUrl} />
    </span>
  );
}

TechIcon.propTypes = {
  technician: PropTypes.shape({
    photoUrl: PropTypes.string,
    color: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
};

function StatusIcon({ status }) {
  return (
    <span className="qmb-status" style={{ color: status.color }}>
      <i className={status.icon} />
    </span>
  );
}

StatusIcon.propTypes = {
  status: PropTypes.shape({
    color: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired
  }).isRequired
};

export default Event;
