import React, { createContext, useCallback, useContext, useMemo } from 'react';
import useGetSignatures from '@hooks/requests/useGetSignatures';
import useGreedyState from '@hooks/use-greedy-state.hook';
import apiClient from '@utils/api-client';
import toast from 'react-hot-toast';
import { apiInternalSignaturePath } from '../../routes';
import { ToastContext } from '../../contexts/toast-context';
export const SignaturesListContext = createContext(undefined);
export function SignaturesListContextProvider(params) {
    const { alertSuccess, alertError } = useContext(ToastContext);
    const signaturesQuery = useMemo(() => ({
        select: 'all',
        filter: {
            resourceType: params.signatureOwner,
            resourceId: params.signatureOwnerId
        }
    }), []);
    const { signatures: fetchedSignautres, loading: signaturesLoading, error: signaturesError } = useGetSignatures(signaturesQuery);
    const { data: signatures, setData: setSignatures } = useGreedyState(fetchedSignautres);
    const addSignature = useCallback((newSignature) => {
        const newState = [...(signatures !== null && signatures !== void 0 ? signatures : []), newSignature];
        setSignatures(newState);
    }, [signatures]);
    const replaceSignature = useCallback((updatedSignature) => {
        const updatedState = (signatures || []).map((signature) => signature.id === updatedSignature.id ? updatedSignature : signature);
        setSignatures(updatedState);
    }, [signatures]);
    const deleteSignature = useCallback((signature, setDeleting, isMountedRef) => {
        setDeleting(true);
        apiClient
            .delete(apiInternalSignaturePath(signature.id))
            .then(() => {
            if (signatures) {
                const newState = signatures.filter((x) => x.id !== signature.id);
                setSignatures(newState);
                alertSuccess('Signature deleted.');
            }
        })
            .catch((err) => {
            alertError('Unable to delete signature');
        })
            .finally(() => {
            if (isMountedRef.current) {
                setDeleting(false);
            }
        });
    }, [signatures, toast]);
    const contextData = useMemo(() => {
        const value = {
            signatures: signatures !== null && signatures !== void 0 ? signatures : [],
            signaturesLoading,
            addSignature,
            replaceSignature,
            deleteSignature,
            defaultTechnicianId: params.defaultTechnicianId
        };
        return value;
    }, [signatures, signaturesLoading]);
    return React.createElement(SignaturesListContext.Provider, { value: contextData }, params.children);
}
