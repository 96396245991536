import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useGetVisits from '@hooks/requests/visits.get.hook';
import useApiQuery from '@hooks/api-params.hook';
import useGreedyState from '@hooks/use-greedy-state.hook';
import { dateClient } from '@utils/date-client';
import { GlobalDragContext } from './GlobalDragContext';
import { ScheduleAndDispatchContext } from './ScheduleAndDispatchContext';
import { setVisits as setVisitsDispatch } from '../Redux/visitsSlice';
import { TenantContext } from '../../../contexts/tenant-context';
import { TimeZoneContext } from '@contexts/timezone-context';
export const VisitPaneContext = createContext(undefined);
export function VisitPaneContextProvider(params) {
    const { tenant } = useContext(TenantContext);
    const { timezone } = useContext(TimeZoneContext);
    const visitParams = useMemo(() => {
        var _a, _b, _c, _d;
        return ({
            select: { page: 1, size: (_b = (_a = tenant === null || tenant === void 0 ? void 0 : tenant.configurations) === null || _a === void 0 ? void 0 : _a.schedulerVisitsPanePageSize) !== null && _b !== void 0 ? _b : 250 },
            filter: {
                type: params.filters.visitType === 'WorkOrder'
                    ? ["WorkOrder" /* VisitableType.SERVICE */]
                    : params.filters.visitType === 'Inspection'
                        ? ["Inspection" /* VisitableType.INSPECTION */]
                        : [],
                range: {
                    start: params.filters.start,
                    end: params.filters.end
                },
                technicianIds: (_c = params.filters.assigneeTechIds) === null || _c === void 0 ? void 0 : _c.map((value) => value !== null && value !== void 0 ? value : 'unassigned'),
                subcontractorIds: (_d = params.filters.assigneeSubIds) === null || _d === void 0 ? void 0 : _d.map((value) => value !== null && value !== void 0 ? value : 'unassigned'),
                teamIds: params.filters.teamIds,
                assets: params.filters.assets,
                accountIds: params.filters.accountIds,
                buildingIds: params.filters.buildingIds,
                territoryIds: params.filters.territoryIds,
                tagIds: params.filters.tagIds,
                zipCode: params.filters.zipCode,
                workOrderStatuses: params.filters.workOrderStatuses,
                inspectionStatuses: params.filters.inspectionStatuses,
                visitStatuses: params.filters.visitStatuses,
                inspectionTypeIds: params.filters.inspectionTypeIds
            }
        });
    }, [params.filters]);
    const { apiParams: visitsQuery, nextPage, prevPage } = useApiQuery(visitParams);
    const { data: fetchedVisits, loading, error, totalPages } = useGetVisits(visitsQuery);
    const { data: visits, setData: setVisits } = useGreedyState(fetchedVisits);
    // @ts-ignore
    const filterValuesState = useSelector((state) => state.map.filters.values);
    // @ts-ignore
    const filterActiveState = useSelector((state) => state.map.filters.active);
    const activeVisit = useRef(undefined);
    const { modifiedEventWatch } = useContext(ScheduleAndDispatchContext);
    const { startDragging, stopDragging } = useContext(GlobalDragContext);
    const dispatch = useDispatch();
    const replaceVisit = useCallback((updatedVisit) => {
        if (visits === null || visits === void 0 ? void 0 : visits.some((v) => v.key === updatedVisit.key)) {
            if (matchesFilters(updatedVisit)) {
                setVisits(visits === null || visits === void 0 ? void 0 : visits.map((v) => (v.key === updatedVisit.key ? updatedVisit : v)));
            }
            else {
                setVisits(visits === null || visits === void 0 ? void 0 : visits.filter((v) => v.key !== updatedVisit.key));
            }
        }
        else if (matchesFilters(updatedVisit)) {
            setVisits([...(visits || []), updatedVisit]);
        }
    }, [visits]);
    const matchesFilters = useCallback((visit) => {
        const isDateValid = (checkVisit) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            const filterStart = dateClient(filterValuesState.visitDate.startDate).tz(timezone).startOf('day');
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            const filterEnd = dateClient(filterValuesState.visitDate.endDate).tz(timezone).endOf('day');
            return !checkVisit.end.isBefore(filterStart) && !checkVisit.start.isAfter(filterEnd);
        };
        const isStateValid = (checkVisit) => {
            if (filterValuesState.inspectionStatusKeys.length + filterValuesState.visitStatusKeys.length > 0) {
                if (checkVisit.visitable.type === "Inspection" /* VisitableType.INSPECTION */) {
                    return filterValuesState.inspectionStatusKeys.includes(visit.status.key);
                }
                if (checkVisit.visitable.type === "WorkOrder" /* VisitableType.SERVICE */) {
                    return filterValuesState.visitStatusKeys.includes(visit.status.key);
                }
            }
            return true;
        };
        const isAssigneeValid = (checkVisit) => {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            if (filterActiveState.assignee &&
                filterValuesState.assigneeTechIds.length + filterValuesState.assigneeSubIds.length > 0) {
                const id = checkVisit.assignee.info ? checkVisit.assignee.info.id : null;
                if (checkVisit.assignee.type === "technician" /* AssigneeType.TECHNICIAN */) {
                    return filterValuesState.assigneeTechIds.some((x) => x === id);
                }
                return filterValuesState.assigneeSubIds.some((x) => x === id);
            }
            return true;
        };
        const isTypeValid = (checkVisit) => {
            if (filterActiveState.type && filterValuesState.type !== '') {
                return checkVisit.visitable.type === filterValuesState.type;
            }
            return true;
        };
        return isDateValid(visit) && isStateValid(visit) && isAssigneeValid(visit) && isTypeValid(visit);
    }, [filterValuesState, filterActiveState, timezone]);
    useEffect(() => {
        if (modifiedEventWatch) {
            replaceVisit(modifiedEventWatch);
        }
    }, [modifiedEventWatch]);
    const handleComponentExit = useCallback(() => {
        if (activeVisit.current) {
            startDragging(activeVisit.current, () => {
                stopDragging();
            });
        }
    }, []);
    const contextData = useMemo(() => {
        const value = {
            activeVisit,
            handleComponentExit,
            visits: visits !== null && visits !== void 0 ? visits : [],
            error,
            loading,
            currentPage: visitsQuery.select === 'all' ? 1 : visitsQuery.select.page,
            totalPages: totalPages !== null && totalPages !== void 0 ? totalPages : 1,
            nextPage,
            prevPage,
            mapActive: params.mapActive
        };
        return value;
    }, [loading, totalPages, visitsQuery, visits, error, params.mapActive]);
    // Temporary solution until refactor GoogleMap.jsx
    useEffect(() => {
        const transformedVisitsForLegacy = (visits === null || visits === void 0 ? void 0 : visits.map((visit) => {
            var _a, _b, _c;
            return ({
                key: `$Visit_${visit.id}`,
                type: "Visit" /* VisitType.SERVICE_VISIT */,
                id: visit.id,
                startTime: visit.start.toISOString(),
                endTime: visit.end.toISOString(),
                technicianId: visit.assignee.type === "technician" /* AssigneeType.TECHNICIAN */ ? (_c = (_b = (_a = visit.assignee) === null || _a === void 0 ? void 0 : _a.info) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : null : null,
                building: visit.building.name,
                address: visit.building.address,
                title: visit.building.name,
                subtitle: visit.building.address,
                status: visit.status.key,
                statusObj: visit.status,
                statusColor: visit.status.color,
                statusIcon: visit.status.icon,
                humanizedStatus: visit.status.label,
                buildingId: visit.building.id,
                buildingObj: visit.building,
                lat: visit.building.latitude,
                lng: visit.building.longitude,
                description: null,
                assignee: visit.assignee,
                visitable: visit.visitable
            });
        })) || [];
        dispatch(setVisitsDispatch(transformedVisitsForLegacy));
    }, [visits]);
    return React.createElement(VisitPaneContext.Provider, { value: contextData }, params.children);
}
