import { inspectionStatusPath, mapPath } from 'routes';
import api from '@utils/axios';

export const fetchTechnicians = async (filters) => {
  const response = await fetch(`/map/technicians?team_id=${filters?.values?.technicianTeam}`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return await response.json();
};

export const fetchTechnicianLocations = async (filterValues) => {
  const queryParams = new URLSearchParams();

  if (filterValues && filterValues.technicians) {
    filterValues.technicians.forEach((technicianId) => {
      queryParams.append('technicians[]', technicianId);
    });
  }

  const response = await fetch(`/map/fleet_tracking?${queryParams.toString()}`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return await response.json();
};

export const updateFleetTrackingPassengers = async (techId, vehicleId) => {
  const params = { technician_id: techId, vehicle_id: vehicleId };

  const response = await fetch('/map/update_fleet_tracking_passengers', {
    method: 'post',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json'
    }
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return await response.json();
};

export const deleteFleetTrackingPassengers = async (techId) => {
  const params = { technician_id: techId };

  const response = await fetch('/map/delete_fleet_tracking_passengers', {
    method: 'post',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json'
    }
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return await response.json();
};

export const saveVisit = async (event) => {
  // TODORV: Check if depracated. Remove it or restore back-end implementation
  // const { data } = await api.put(
  //   mapPath({ id: event.id, type: event.type, format: 'json', _options: true }),
  //   eventData(event)
  // );

  // return data;
};

export const fetchVisitStatus = async (status) => {
  const response = await fetch(inspectionStatusPath(status));

  if (!response.ok) {
    throw new Error('Fetching visit status failed.');
  }

  return response.json();
};
