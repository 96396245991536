import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { translate } from '@utils/i18n';
import Modal from '@components/Modal';
import UploadZone from '../../components/UploadZone/UploadZone';

const namespace = 'features.documents';

function AddDocumentModal({ isOpen, onAddDocument, onClose, documentType, saveUrl }) {
  const onFileAdded = (res) => {
    onAddDocument(res);
    onClose();
  };

  const authToken = useMemo(() => document.querySelector('meta[name="csrf-token"]').getAttribute('content'), []);

  return (
    <Modal
      title={translate('add_document', { namespace })}
      className="qmb-dialog--modal documentListContainer"
      isOpen={isOpen}
      onClose={onClose}
      width={930}
      height={400}>
      <UploadZone
        id="add-document-modal-upload"
        saveUrl={saveUrl}
        onFileAdded={onFileAdded}
        acceptedFormats={documentType?.accepted_formats}
        maximumSize={documentType?.max_size}
        additionalData={{
          document_type_id: documentType?.id
        }}
        fieldName="files[]"
        additionalHeaders={{
          'x-csrf-token': authToken
        }}
      />
    </Modal>
  );
}

export default AddDocumentModal;

AddDocumentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onAddDocument: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  documentType: PropTypes.object,
  saveUrl: PropTypes.string.isRequired
};

AddDocumentModal.defaultProps = {
  documentType: null
};
