/* global $ */

import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import I18n, { translate } from '@utils/i18n';
import dayjs, { Dayjs } from 'dayjs';
import { startDragging, status as draggingStatuses, types } from '../Redux/draggingSlice';
import { assignVisitToTechnician } from '../Redux/visitsSlice';
import Chip from '../../../components/Chip/chip';
import { visitAssigneeIcon, visitAssigneeTitle } from '../../../utils/functions/visit.functions';
import { VisitPaneContext } from '../Contexts/VisitPaneContext';
import { ScheduleAndDispatchContext } from '../Contexts/ScheduleAndDispatchContext';
import { TimeZoneContext } from '../../../contexts/timezone-context';

const namespace = 'features.map_scheduler';

function VisitItem({ visit, onChangeSize, index }) {
  const dispatch = useDispatch();
  const draggingType = useSelector((state) => state.dragging.type);
  const draggingData = useSelector((state) => state.dragging.data);
  const draggingStatus = useSelector((state) => state.dragging.status);
  const mapActive = useSelector((state) => state.map.mapActive);
  const tooltipRef = useRef();
  const containerRef = useRef();

  const isDragging = useRef(false);

  const { activeVisit, handleComponentExit } = useContext(VisitPaneContext);
  const { openVisitModal } = useContext(ScheduleAndDispatchContext);
  const { timezone } = useContext(TimeZoneContext);

  useEffect(() => {
    $(tooltipRef.current).kendoTooltip({
      position: 'right'
    });
  }, []);

  const HandleMouseDown = (e) => {
    e.stopPropagation();
    // TODO: refactor visit panel into typescript and just pass self
    activeVisit.current = {
      key: `${visit.type}-${visit.id}`,
      type: visit.type,
      id: visit.id,
      assignee: visit.assignee,
      start: dayjs(visit.startTime),
      end: dayjs(visit.endTime),
      title: visit.title,
      subtitle: visit.subtitle,
      status: visit.statusObj || visit.status,
      building: visit.buildingObj
    };
    document.addEventListener('mousemove', HandleMouseMove);
  };

  const HandleMouseMove = (e) => {
    handleComponentExit(e);
    // isDragging.current = true;
    // dispatch(startDragging({ x: e.clientX, y: e.clientY, data: visit, type: types.VISIT }));
    document.removeEventListener('mousemove', HandleMouseMove);
  };

  const HandleMouseUp = () => {
    document.removeEventListener('mousemove', HandleMouseMove);

    if (isDragging.current) {
      if (draggingStatus === draggingStatuses.DRAGGING && draggingType === types.TECHNICIAN) {
        dispatch(assignVisitToTechnician({ visit, technician: draggingData }));
      }
    } else {
      openVisitModal(visit.type, visit.id);
      // dispatch(showEditModal(visit));
    }
    isDragging.current = false;
  };

  const typeTitle = (type) => {
    return translate(`visits.types.${type}`, { namespace }) || translate(`visits.types.default`, { namespace });
  };

  useEffect(() => {
    onChangeSize(index, containerRef.current.getBoundingClientRect().height);
  }, [onChangeSize, index]);

  if (mapActive) {
    return (
      <li
        ref={containerRef}
        className="list__option--visit"
        onMouseDown={HandleMouseDown}
        onMouseUp={HandleMouseUp}
        style={{ userSelect: 'none' }}>
        <button type="button">
          <span className="option__header">
            <strong className="option__title">{visit.building}</strong>
            <span className="option__type" ref={tooltipRef} title={typeTitle(visit.type)}>
              <TypeIcon type={visit.type} frequency={visit.details.frequency} />
            </span>
          </span>
          <span className="option__address">{visit.address}</span>
          <span className="option__footer">
            <span className="option__date">
              <i className={visit.statusIcon} style={{ color: visit.statusColor }} />
              &nbsp;
              {DateTime.fromISO(visit.startTime, { zone: timezone }).toFormat("M/d/yyyy' @ 'hh:mm a")}
            </span>
            <Chip icon={visitAssigneeIcon(visit)} label={visitAssigneeTitle(visit)} />
          </span>
        </button>
      </li>
    );
  }

  return (
    <li
      ref={containerRef}
      className="list__option--visit"
      onMouseDown={HandleMouseDown}
      onMouseUp={HandleMouseUp}
      style={{ userSelect: 'none' }}>
      <button type="button" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span className="option__type--fixed-width" title={typeTitle(visit.type)}>
          <TypeIcon type={visit.type} />
          {visit.type === 'Visit' ? 'Visit' : 'Inspection'}
        </span>
        <span className="option__title--fixed-width">{visit.building}</span>
        <span className="option__address--fixed-width">{visit.address}</span>
        <span className="option__date--fixed-width">
          {DateTime.fromISO(visit.startTime, { zone: timezone }).toFormat("M/d/yyyy' @ 'hh:mm a")}
        </span>
        <span className="option__status--fixed-width">
          <i className={visit.statusIcon} style={{ color: visit.statusColor }} />
          &nbsp;{visit.humanizedStatus}
        </span>
        <span className="option__freq--fixed-width">{visit.details.frequency ?? ''}</span>
        <span className="option__desc--fixed-width">{visit.description}</span>
        <span className="option__tech--fixed-width">
          <Chip icon={visitAssigneeIcon(visit)} label={visitAssigneeTitle(visit)} />
        </span>
        <span className="option__duration--fixed-width">{visit.humanized_event_duration}</span>
      </button>
    </li>
  );
}

function TypeIcon({ type, frequency }) {
  switch (type) {
    case 'Visit':
      return <i className="fa-light fa-screwdriver-wrench" />;
    case 'Inspection':
      return (
        <>
          {frequency && <small>{frequency}</small>}
          <i className="fa-light fa-clipboard-check" />
        </>
      );
    default:
      return <i className="fa-light fa-square-question" />;
  }
}

function TechnicianInfo({ technician, technicianId, fixedWidth }) {
  if (!technicianId) {
    return (
      <span className={`option__tech--unassigned${fixedWidth ? '--fixed-width' : ''}`}>
        <i className="fa-light fa-user-helmet-safety" /> {I18n.t('generic.unassigned')}
      </span>
    );
  }

  if (!technician.photoUrl) {
    return (
      <span className={`option__tech${fixedWidth ? '--fixed-width' : ''}`}>
        <span className="qmb-avatar--16">
          <svg viewBox="0 0 16 16">
            <circle cx="8" cy="8" r="8" style={{ fill: technician.color }} />
            <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle">
              {technician.name[0]}
            </text>
          </svg>
        </span>
        {technician.name}
      </span>
    );
  }

  return (
    <span className={`option__tech${fixedWidth ? '--fixed-width' : ''}`}>
      <span className="qmb-avatar--16">
        <img
          alt={technician.name}
          style={{ borderRadius: '50%', border: `1px solid ${technician.color}`, boxSizing: 'border-box' }}
          src={technician.photoUrl}
        />
      </span>
      {technician.name}
    </span>
  );
}

export default VisitItem;

VisitItem.propTypes = {
  visit: PropTypes.object.isRequired,
  onChangeSize: PropTypes.func,
  index: PropTypes.number
};

VisitItem.defaultProps = {
  onChangeSize: null,
  index: null
};

TypeIcon.propTypes = {
  type: PropTypes.string.isRequired,
  frequency: PropTypes.string
};

TypeIcon.defaultProps = {
  frequency: null
};

TechnicianInfo.propTypes = {
  technician: PropTypes.object,
  technicianId: PropTypes.number,
  fixedWidth: PropTypes.bool
};

TechnicianInfo.defaultProps = {
  technician: null,
  technicianId: null,
  fixedWidth: true
};
