import React, { useEffect, useRef, useState } from 'react';
import { translate } from '@utils/i18n';
import ConfirmDialog from '@components/ConfirmDialog/ConfirmDialog';
import { mapInspectionSignaturePath } from '../../routes';
const namespace = 'features.signatures_list';
const getColumnsDefinition = (params) => [
    {
        label: translate('name', { namespace }),
        dataKey: 'name',
        renderer: ({ item }) => {
            return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions
            React.createElement("a", { rel: "noreferrer", className: "qmb-link", target: "_blank", onClick: () => {
                    params.handleOnClick(item);
                } }, item.name));
        }
    },
    {
        label: translate('type', { namespace }),
        dataKey: 'signatureTypeLabel'
    },
    {
        label: translate('signature_date', { namespace }),
        align: 'left',
        dataKey: 'signatureDate',
        renderer: ({ item }) => {
            return React.createElement("span", null, item.signatureDate.format('MM/DD/YYYY'));
        }
    },
    {
        label: '',
        align: 'right',
        dataKey: 'id',
        renderer: ({ item }) => {
            const [deleting, setDeleting] = useState(false);
            const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
            const isMountedRef = useRef(true);
            useEffect(() => {
                return () => {
                    isMountedRef.current = false;
                };
            }, []);
            return (React.createElement("div", { style: { display: 'flex', justifyContent: 'flex-end' } },
                React.createElement("button", { type: "button", "aria-label": "Map location", className: "qmb-control--icon", disabled: item.latitude === null, onClick: () => {
                        window.location.href = mapInspectionSignaturePath(item.resourceId, item.id);
                    } },
                    React.createElement("i", { className: "fa-light fa-map-location-dot" })),
                React.createElement("button", { type: "button", "aria-label": "Save", className: "qmb-control--icon", onClick: () => setDeleteDialogVisible(true) },
                    React.createElement("i", { className: "fa-light fa-trash-can" })),
                deleteDialogVisible && (React.createElement(ConfirmDialog, { title: translate('confirm_delete.title', { namespace }), description: translate('confirm_delete.description', { namespace }), loading: deleting, onConfirm: () => params.handleOnDelete(item, setDeleting, isMountedRef), onCancel: () => setDeleteDialogVisible(false) }))));
        }
    }
    // {
    //   label: translate('signature_date', { namespace }),
    //   dataKey: 'signatureDate'
    // },
    // {
    //   label: translate('map', { namespace }),
    //   dataKey: 'map'
    // }
    // {
    //   label: translate('frequency', { namespace }),
    //   dataKey: 'frequencyString'
    // },
    // {
    //   label: '',
    //   align: 'left',
    //   dataKey: 'id',
    //   width: 70,
    //   renderer: ({ item }) => (
    //     <button
    //       type="button"
    //       title="Edit Details"
    //       className="qmb-control--icon"
    //       data-remote="true"
    //       onClick={(): void => onClickItem(item)}
    //     >
    //       <i className="fa-light fa-pencil" aria-hidden="true" />
    //     </button>
    //   )
    // }
];
export default getColumnsDefinition;
