import React from 'react';
import Button from '@components/Button/Button';
import { translate } from '@utils/i18n';
import { Loader, LoaderSizes } from '@components/Loader/Loader';
import { AIDeficiencyReviewStatus, PDFViewerStatus } from '../types';
import { snakeToCapitalize } from '../utils/utils';
import { namespace, StatusIcons } from '../constants';
import DeficiencyInfo from './DeficiencyInfo';
export default function DeficienciesListSection({ pdfViewerStatus, deficiencies, defIdLoading, onOpenDeficiency, bulkUpdateLoading, onBulkUpdate }) {
    return (React.createElement("section", { className: "deficienciesListContainer", style: pdfViewerStatus === PDFViewerStatus.Expanded ? { display: 'none' } : {} },
        React.createElement("section", { className: "deficienciesListHeader" },
            React.createElement(Button, { onClick: () => {
                    void onBulkUpdate(AIDeficiencyReviewStatus.Correct);
                } },
                React.createElement("i", { className: "fa-light fa-check" }),
                " ",
                translate('mark_all_correct', { namespace }),
                bulkUpdateLoading === AIDeficiencyReviewStatus.Correct && React.createElement(Loader, { size: LoaderSizes.small })),
            React.createElement(Button, { onClick: () => {
                    void onBulkUpdate(AIDeficiencyReviewStatus.Incorrect);
                } },
                React.createElement("i", { className: "fa-light fa-xmark" }),
                " ",
                translate('mark_all_incorrect', { namespace }),
                bulkUpdateLoading === AIDeficiencyReviewStatus.Incorrect && React.createElement(Loader, { size: LoaderSizes.small }))),
        React.createElement("section", { className: "defecienciesList" }, deficiencies.map((deficiency, idx) => deficiency.reviewStatus !== AIDeficiencyReviewStatus.Removed && (React.createElement("div", { role: "presentation", key: deficiency.deficiency_uid, className: "deficiencyCard", onClick: () => onOpenDeficiency(deficiency) },
            React.createElement("div", { className: "deficiencyHeader" },
                React.createElement("span", { className: "deficiencyTitle" }, `${translate('deficiency', { namespace })} ${idx + 1}`)),
            defIdLoading === deficiency.deficiency_uid ? (React.createElement("section", { className: "deficiencyInfoloading" },
                React.createElement(Loader, { size: LoaderSizes.small }))) : (React.createElement(DeficiencyInfo, { deficiency: deficiency })),
            React.createElement("div", { className: "deficiencyStatus" },
                React.createElement("span", { className: "deficiencyStatusText" },
                    React.createElement("i", { className: StatusIcons[deficiency.reviewStatus] }),
                    snakeToCapitalize(deficiency.reviewStatus || '')))))))));
}
