import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table, { TableSize } from '@components/Table';
import { deficienciesAiPath } from 'routes';
import api from '@utils/axios';
import I18n, { translate } from '@utils/i18n';
import toast, { Toaster } from 'react-hot-toast';
import RecipientModal from './RecipientModal';

const namespace = 'features.settings.deficiency_ai';

function DeficiencyAi({ users: initUsers, deficienciesAiAutomaticallyScan: initDeficienciesAiAutomaticallyScan }) {
  const [usersSelected, setUsersSelected] = useState(initUsers);
  const [users, setUsers] = useState(initUsers);
  const notificationRecipients = useMemo(() => users.filter((u) => u.subscribed));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deficienciesAiAutomaticallyScan, setDeficienciesAiAutomaticallyScan] = useState(
    initDeficienciesAiAutomaticallyScan
  );

  const onUserSwitch = (user) => () => {
    setUsersSelected((prevItems) =>
      prevItems.map((item) => (item.id === user.id ? { ...item, subscribed: !item.subscribed } : item))
    );
  };

  const onSelectAll = (subscribed) => {
    setUsersSelected((prevItems) => prevItems.map((item) => ({ ...item, subscribed })));
  };

  const onUsersUpdate = () => {
    setUsers(usersSelected);
    setIsModalOpen(false);
  };

  const onSave = () => {
    api
      .put(deficienciesAiPath(), {
        tenant: { deficiencies_ai_automatically_scan: deficienciesAiAutomaticallyScan },
        user_ids: notificationRecipients.map((user) => user.id)
      })
      .then(() => toast.success(translate('update_success', { namespace })))
      .catch((error) => {
        toast.error(error);
      });
  };

  useEffect(() => {
    if (deficienciesAiAutomaticallyScan && notificationRecipients.length === 0) setIsModalOpen(true);
  }, [deficienciesAiAutomaticallyScan]);

  return (
    <div className="workspace__major--form">
      <Toaster position="bottom-right" />
      <header className="workspace__header--alt--sticky">
        <div className="header__main">
          <h1 className="workspace__title">{translate('title', { namespace })}</h1>
        </div>
        <div className="qmb-actions">
          <button
            type="button"
            className="qmb-control--highlight"
            onClick={onSave}
            disabled={notificationRecipients.length === 0 && deficienciesAiAutomaticallyScan}>
            {I18n.t('generic.save')}
          </button>
        </div>
      </header>
      <section className="workspace__section">
        <fieldset className="form__set--vertical--space">
          <div className="form__field--x-full" style={{ width: '411px' }}>
            <span className="qmb-toggle-switch--check">
              <input
                readOnly
                id="enable"
                type="checkbox"
                className="qmb-checkbox"
                checked={deficienciesAiAutomaticallyScan}
                onChange={() => {
                  setDeficienciesAiAutomaticallyScan((old) => !old);
                }}
              />
              <label htmlFor="enable">&nbsp;</label>
              <label htmlFor="enable" className="qmb-label">
                {translate('automatically_scan', { namespace })}
              </label>
              <span className="toggle__note">{translate('description', { namespace })}</span>
            </span>
          </div>
        </fieldset>
      </section>
      {deficienciesAiAutomaticallyScan && (
        <section className="workspace__section">
          <header className="section__title">
            <h2 className="section__label">{translate('notification_recepients', { namespace })}</h2>
            <hr className="section__split" />
          </header>
          <button
            type="button"
            className="qmb-control"
            style={{ marginLeft: 'auto' }}
            onClick={() => {
              setIsModalOpen(true);
            }}>
            <i className="fa-light fa-pencil" aria-hidden="true" />
            {I18n.t('generic.edit')}
          </button>

          <Table
            columns={[{ label: translate('column_title', { namespace }), dataKey: 'email' }]}
            data={notificationRecipients}
            size={TableSize.Full}
            variant="listing"
          />
        </section>
      )}
      <RecipientModal
        isModalOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setUsersSelected(users);
        }}
        users={usersSelected}
        onUserSwitch={onUserSwitch}
        onSelectAll={onSelectAll}
        onUsersUpdate={onUsersUpdate}
      />
    </div>
  );
}

DeficiencyAi.propTypes = {
  users: PropTypes.array.isRequired,
  deficienciesAiAutomaticallyScan: PropTypes.bool.isRequired
};

export default DeficiencyAi;
