import React, { useContext } from 'react';
import { assigneeIcon, assigneeTitle } from '@utils/functions/assignee-functions';
import { GlobalDragContext } from '../../Contexts/GlobalDragContext';
import Chip from '../../../../components/Chip/chip';
function DraggableEvent() {
    var _a;
    const { activeGlobalDragItem } = useContext(GlobalDragContext);
    const event = activeGlobalDragItem.current;
    return (React.createElement("div", { className: `schedule__card--event--${event.status.key}` },
        React.createElement("svg", { className: "card__accent", role: "img", x: "0", y: "0", viewBox: "0 0 64 64" },
            React.createElement("polygon", { points: "64,0 0,64 0,0 " })),
        React.createElement("div", { className: "card__body" },
            React.createElement("div", { className: "card__text" },
                React.createElement(Icon, { type: event.visitable.type }),
                React.createElement("span", null, (_a = event.building) === null || _a === void 0 ? void 0 : _a.name)),
            React.createElement("div", { className: "card__context" },
                React.createElement("span", null,
                    React.createElement("i", { className: event.status.icon, style: { color: event.status.color } }),
                    "\u00A0",
                    event.start.format('M/d/YYYY @ hh:mm a')),
                React.createElement("span", null,
                    React.createElement(Chip, { icon: assigneeIcon(event.assignee, 16), label: assigneeTitle(event.assignee) }))))));
}
function Icon({ type }) {
    switch (type) {
        case "WorkOrder" /* VisitableType.SERVICE */:
            return React.createElement("i", { className: "fa-light fa-screwdriver-wrench" });
        case "Inspection" /* VisitableType.INSPECTION */:
            return React.createElement("i", { className: "fa-light fa-clipboard-check" });
        default:
            return React.createElement("i", { className: "fa-light fa-square-question" });
    }
}
export default DraggableEvent;
