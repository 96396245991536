/* eslint-disable react/require-default-props */
import React from 'react';
import { translate } from '@utils/i18n';
import FullPageModal from '@components/Modal/FullPageModal';
import Button, { ButtonVariant } from '@components/Button/Button';
import LoadableButton from '@components/Button/LoadableButton';
import ConfirmDialog from '@components/ConfirmDialog/ConfirmDialog';
import PDFSection from './PDFSection';
import { namespace } from '../constants';
import DeficienciesListSection from './DeficienciesListSection';
import useDeficienciesAI from '../hooks/useDeficienciesAI';
import DeficiencyDetail from './DeficiencyDetail';
import FeedbackModal from './FeedbackModal';
export default function DeficienciesAi({ document, inspectionId, onClose, onFinish }) {
    const { pdfViewerStatus, deficiencyDetail, defIdLoading, requestAddToInspectionModalOpen, bulkUpdateLoading, isEmpty, onAddFeedback, onAddToInspection, onBulkUpdate, setRequestAddToInspectionModalOpen, onChangeDeficiency, onFinishReview, setPdfViewerStatus, setDeficiencyDetail, isCompletingReview, deficiencies, pendingDeficiencies, openFeedbackModal, setOpenFeedbackModal } = useDeficienciesAI({
        inspectionId: inspectionId !== null && inspectionId !== void 0 ? inspectionId : 0,
        parentDocument: document,
        onFinish
    });
    return (React.createElement(FullPageModal, { title: translate('review_deficiencies', { namespace }), isOpen: !!document, onClose: onClose, footerText: pendingDeficiencies > 0 ? (React.createElement("span", null,
            React.createElement("b", null, translate('pending_review', { namespace, count: pendingDeficiencies })),
            ' ',
            translate('needs_to_be_corrected', { namespace }))) : null, actionButtons: [
            React.createElement(Button, { key: "feedbackButton", onClick: () => setOpenFeedbackModal(true) }, translate('how_did_we_do', { namespace })),
            React.createElement(Button, { key: "closeButton", onClick: onClose }, translate('close', { namespace: 'features.commons' })),
            isEmpty ? (React.createElement(LoadableButton, { loading: isCompletingReview, key: "completeButton", variant: ButtonVariant.Highlight, onClick: () => onFinishReview() }, translate('review_complete', { namespace }))) : (React.createElement(Button, { disabled: pendingDeficiencies > 0, key: "addButton", variant: ButtonVariant.Highlight, onClick: () => setRequestAddToInspectionModalOpen(true) }, translate('add_to_inspection', { namespace })))
        ].filter(Boolean) },
        React.createElement("div", { className: "deficienciesAIGeneral" },
            React.createElement(PDFSection, { pdfViewerStatus: pdfViewerStatus, document: document, onChangeViewerStatus: setPdfViewerStatus }),
            isEmpty ? (React.createElement("div", { className: "emptyViewContainer" },
                React.createElement("i", { className: "fa-light fa-triangle-exclamation fa-5x" }),
                React.createElement("p", null, translate('no_deficiencies', { namespace })))) : (React.createElement(DeficienciesListSection, { onBulkUpdate: onBulkUpdate, bulkUpdateLoading: bulkUpdateLoading, onOpenDeficiency: setDeficiencyDetail, pdfViewerStatus: pdfViewerStatus, deficiencies: deficiencies, defIdLoading: defIdLoading }))),
        React.createElement(DeficiencyDetail, { title: `${translate('deficiency', { namespace })} ${deficiencies.findIndex((def) => def.deficiency_uid === (deficiencyDetail === null || deficiencyDetail === void 0 ? void 0 : deficiencyDetail.deficiency_uid)) + 1}`, deficiency: deficiencyDetail, onClose: () => setDeficiencyDetail(null), onChangeDeficiency: onChangeDeficiency }),
        requestAddToInspectionModalOpen && (React.createElement(ConfirmDialog, { title: translate('add_defs_to_inspection', { namespace }), description: translate('add_confirmation', { namespace }), onConfirm: () => {
                void onAddToInspection();
            }, onCancel: () => setRequestAddToInspectionModalOpen(false), yesLabel: translate('add_to_inspection', { namespace }) })),
        React.createElement(FeedbackModal, { isLoading: isCompletingReview, isOpen: openFeedbackModal, onClose: () => setOpenFeedbackModal(false), onSubmit: (text) => {
                void onAddFeedback(text);
            } })));
}
