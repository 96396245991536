import React from 'react';
import clsx from 'classnames';
import PDFViewer from '@components/PDFViewer';
import Button from '@components/Button/Button';
import { PDFViewerStatus } from '../types';
export default function PDFSection({ pdfViewerStatus, document, onChangeViewerStatus }) {
    return pdfViewerStatus !== PDFViewerStatus.Minimized ? (React.createElement("section", { className: clsx('pdfViewer', {
            expanded: pdfViewerStatus === PDFViewerStatus.Expanded
        }) },
        React.createElement("section", { className: "pdfViewerHeader" },
            React.createElement("span", { className: "title" }, document === null || document === void 0 ? void 0 : document.file_name),
            React.createElement("div", { className: "pdfViewerHeaderActions" },
                pdfViewerStatus === PDFViewerStatus.Normal && (React.createElement(React.Fragment, null,
                    React.createElement(Button, { onClick: () => onChangeViewerStatus(PDFViewerStatus.Expanded) },
                        React.createElement("i", { className: "fa-light fa-expand" })),
                    React.createElement(Button, { onClick: () => onChangeViewerStatus(PDFViewerStatus.Minimized) },
                        React.createElement("i", { className: "fa-light fa-arrow-left-from-line" })))),
                pdfViewerStatus === PDFViewerStatus.Expanded && (React.createElement(Button, { onClick: () => onChangeViewerStatus(PDFViewerStatus.Normal) },
                    React.createElement("i", { className: "fa-light fa-compress" }))))),
        React.createElement(PDFViewer, { file: document === null || document === void 0 ? void 0 : document.url, className: "pdfViewerSection" }))) : (React.createElement("section", { className: "pdfViewerMinimized" },
        React.createElement(Button, { onClick: () => onChangeViewerStatus(PDFViewerStatus.Normal) },
            React.createElement("i", { className: "fa-light fa-arrow-right-from-line" })),
        React.createElement("div", { className: "textContainer" },
            React.createElement("span", { className: "title" }, document === null || document === void 0 ? void 0 : document.file_name))));
}
