import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '@utils/i18n';
import MassUpdateModal from './MassUpdateModal';
import GenerateReportsModal from './GenerateReportsModal';
import ExportCsvModal from './ExportCsvModal';
import PushToTceModal from './PushToTceModal';

const namespace = 'features.inspections_index.mass_actions';

function MassActions({
  state,
  onSelectAll,
  techniciansForSelect,
  statusesForSelect,
  queryParams,
  buildingId,
  optionalMassActions,
  onDataChanged,
  inspectionSeriesId
}) {
  const { data: inspections, selectAll, totalCount } = state;
  const selectedInspections = inspections.filter((inspection) => inspection.selected);
  const disabled = selectedInspections.length === 0;
  const inspectionsCount = selectAll ? totalCount : selectedInspections.length;
  const inspectionIds = selectedInspections.map((inspection) => inspection.id).join();

  return (
    <div className="qmb-actions">
      {!disabled && (
        <div className="actions__group--selection">
          <button type="button" className="actions__selection">
            <span className="actions__count">{inspectionsCount}</span>
          </button>
          {!selectAll && (
            <div className="actions__entire">
              <button type="button" tabIndex="0" className="qmb-control--sm" onClick={onSelectAll}>
                {translate('selected_global_counter', { namespace, count: totalCount })}
              </button>
            </div>
          )}
        </div>
      )}

      <div className="actions__group">
        {!buildingId && (
          <MassUpdateModal
            inspectionsCount={inspectionsCount}
            inspectionIds={!selectAll && inspectionIds}
            disabled={disabled}
            statusesForSelect={statusesForSelect}
            techniciansForSelect={techniciansForSelect}
            queryParams={queryParams}
            onDataChanged={onDataChanged}
            inspectionSeriesId={inspectionSeriesId}
          />
        )}
        {!buildingId && (
          <GenerateReportsModal
            inspectionsCount={inspectionsCount}
            inspectionIds={!selectAll && inspectionIds}
            disabled={disabled}
            queryParams={queryParams}
          />
        )}
        {!buildingId && optionalMassActions.pushToTCE && (
          <PushToTceModal
            inspectionsCount={inspectionsCount}
            inspectionIds={!selectAll && inspectionIds}
            disabled={disabled}
            queryParams={queryParams}
          />
        )}
        <ExportCsvModal disabled={disabled} inspectionIds={!selectAll && inspectionIds} queryParams={queryParams} />
      </div>
    </div>
  );
}

MassActions.propTypes = {
  state: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
    totalCount: PropTypes.number,
    selectAll: PropTypes.bool
  }).isRequired,
  onSelectAll: PropTypes.func.isRequired,
  techniciansForSelect: PropTypes.arrayOf(PropTypes.object),
  statusesForSelect: PropTypes.arrayOf(PropTypes.object),
  queryParams: PropTypes.object.isRequired,
  buildingId: PropTypes.number,
  onDataChanged: PropTypes.func.isRequired,
  optionalMassActions: PropTypes.shape({
    pushToTCE: PropTypes.bool
  }),
  inspectionSeriesId: PropTypes.number
};

MassActions.defaultProps = {
  techniciansForSelect: [],
  statusesForSelect: [],
  buildingId: null,
  optionalMassActions: {},
  inspectionSeriesId: null
};

export default MassActions;
