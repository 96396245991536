/* eslint-disable react/require-default-props */
import React, { useEffect, useRef, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import useDebounceFn from '@hooks/useDebounceFn';
import './PDFViewer.scss';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
export default function PDFViewer({ file, className, pageHeight, pageWidth }) {
    const [numPages, setNumPages] = useState();
    const [indexSelected, setIndexSelected] = useState(0);
    const pageRefs = useRef([]);
    const wrapperRef = useRef(null);
    const [autoPageWidth, setAutoPageWidth] = useState(0);
    const debouncedSetAutoPageWidth = useDebounceFn(setAutoPageWidth, 300);
    useEffect(() => {
        pageRefs.current = new Array(numPages).fill(null).map(() => React.createRef());
    }, [numPages]);
    useEffect(() => {
        const element = wrapperRef.current;
        const observer = new ResizeObserver((entries) => {
            const [newElement] = entries;
            const { width } = newElement.contentRect;
            debouncedSetAutoPageWidth(width);
        });
        if (!element)
            return;
        observer.observe(element);
        return () => observer.disconnect();
    }, [numPages]);
    const onDocumentLoadSuccess = ({ numPages: pageAmount }) => {
        setNumPages(pageAmount);
    };
    const onClickPage = (index) => {
        var _a, _b;
        setIndexSelected(index);
        (_b = (_a = pageRefs.current[index]) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.scrollIntoView();
    };
    return (React.createElement(Document, { file: file, onLoadSuccess: onDocumentLoadSuccess, className: `pdfViewerContent ${className !== null && className !== void 0 ? className : ''}` },
        React.createElement("section", { className: "pagesViewer" }, [...Array(numPages)].map((_, index) => (React.createElement("section", { role: "presentation", key: index, className: "itemSelector", onClick: () => onClickPage(index) },
            React.createElement(Page, { className: `pdfViewerPage ${index === indexSelected ? 'selected' : ''}`, pageNumber: index + 1, height: 93, renderAnnotationLayer: false, renderTextLayer: false }),
            React.createElement("span", { className: "pageIndex" }, index + 1))))),
        React.createElement("section", { ref: wrapperRef, className: "pageDetail pagesViewer" }, [...Array(numPages)].map((_, index) => (React.createElement(Page, { inputRef: pageRefs.current[index], key: index, className: "pdfViewerPage", pageNumber: index + 1, width: pageWidth !== null && pageWidth !== void 0 ? pageWidth : Math.floor(autoPageWidth), height: pageHeight, renderAnnotationLayer: false, renderTextLayer: false }))))));
}
