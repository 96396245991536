import React, { useContext, useEffect, useMemo, useState } from 'react';
import { translate } from '@utils/i18n';
import { DatePicker, TimePicker } from '@progress/kendo-react-dateinputs';
import { Duration } from 'luxon';
import apiClient from '@utils/api-client';
import { dateClient } from '@utils/date-client';
import { checkAvailableTechniciansPath } from '../../../../routes';
import VisitStatusDropdown from '../../../../components/Dropdowns/VisitStatusDropdown';
import { VisitModalContext } from '../VisitModalContext';
import Error from '../../../../components/Error';
import TechSubToggle from './tech-sub-toggle';
import SubcontractorDropdown from '../../../../components/Dropdowns/SubcontractorDropdown';
import TechnicianDropdown from '../../../../components/Dropdowns/TechnicianDropdown';
import { Loader, LoaderSizes } from '../../../../components/Loader/Loader';
import { TimeZoneContext } from '../../../../contexts/timezone-context';
function Form(params) {
    var _a, _b, _c;
    const namespace = 'features.modals.visit_modal.form';
    const { tenant, visit, visitStatusList, subcontractorList, technicianList, saving, closeModal, saveVisit } = useContext(VisitModalContext);
    const { timezone } = useContext(TimeZoneContext);
    if (!visit || !tenant)
        return React.createElement(Error, { error: "Unable to load form." });
    const [formData, setFormData] = useState(visit);
    const [availabilityNotification, setAvailabilityNotification] = useState();
    const defaultSubcontractor = useMemo(() => subcontractorList.find((sub) => { var _a; return sub.id === ((_a = visit.building) === null || _a === void 0 ? void 0 : _a.defaultSubcontractorId); }), [subcontractorList, (_a = visit.building) === null || _a === void 0 ? void 0 : _a.defaultSubcontractorId]);
    const defaultTech = useMemo(() => {
        var _a, _b;
        const idToCompare = visit.visitable.type === "WorkOrder" /* VisitableType.SERVICE */
            ? (_a = visit.building) === null || _a === void 0 ? void 0 : _a.defaultServiceTechId
            : (_b = visit.building) === null || _b === void 0 ? void 0 : _b.defaultTechId;
        return technicianList.find((tech) => tech.id === idToCompare);
    }, [technicianList, (_b = visit.building) === null || _b === void 0 ? void 0 : _b.defaultServiceTechId, (_c = visit.building) === null || _c === void 0 ? void 0 : _c.defaultTechId]);
    const handleStatusChange = (newStatus) => {
        setFormData((prevState) => (Object.assign(Object.assign({}, prevState), { status: newStatus })));
    };
    const handleAssigneeTypeChange = (type) => {
        var _a, _b, _c, _d, _e, _f;
        const newAssignee = type === "subcontractor" /* AssigneeType.SUBCONTRACTOR */
            ? {
                type: "subcontractor" /* AssigneeType.SUBCONTRACTOR */,
                key: `${"subcontractor" /* AssigneeType.SUBCONTRACTOR */}-${visit.assignee.type === "subcontractor" /* AssigneeType.SUBCONTRACTOR */ && ((_a = visit.assignee.info) === null || _a === void 0 ? void 0 : _a.id)
                    ? visit.assignee.info.id
                    : (_b = defaultSubcontractor === null || defaultSubcontractor === void 0 ? void 0 : defaultSubcontractor.id) !== null && _b !== void 0 ? _b : 'unassigned'}`,
                info: visit.assignee.type === "subcontractor" /* AssigneeType.SUBCONTRACTOR */ && ((_c = visit.assignee.info) === null || _c === void 0 ? void 0 : _c.id)
                    ? visit.assignee.info
                    : defaultSubcontractor !== null && defaultSubcontractor !== void 0 ? defaultSubcontractor : null
            }
            : {
                type: "technician" /* AssigneeType.TECHNICIAN */,
                key: `${"technician" /* AssigneeType.TECHNICIAN */}-${visit.assignee.type === "technician" /* AssigneeType.TECHNICIAN */ && ((_d = visit.assignee.info) === null || _d === void 0 ? void 0 : _d.id)
                    ? visit.assignee.info.id
                    : (_e = defaultTech === null || defaultTech === void 0 ? void 0 : defaultTech.id) !== null && _e !== void 0 ? _e : 'unassigned'}`,
                info: visit.assignee.type === "technician" /* AssigneeType.TECHNICIAN */ && ((_f = visit.assignee.info) === null || _f === void 0 ? void 0 : _f.id)
                    ? visit.assignee.info
                    : defaultTech !== null && defaultTech !== void 0 ? defaultTech : null
            };
        setFormData((prevState) => (Object.assign(Object.assign({}, prevState), { assignee: newAssignee })));
    };
    const handleSubcontractorChange = (subcontractor) => {
        setFormData((prevState) => {
            var _a;
            return (Object.assign(Object.assign({}, prevState), { assignee: {
                    type: "subcontractor" /* AssigneeType.SUBCONTRACTOR */,
                    key: `${"subcontractor" /* AssigneeType.SUBCONTRACTOR */}-${(_a = subcontractor === null || subcontractor === void 0 ? void 0 : subcontractor.id) !== null && _a !== void 0 ? _a : 'unassigned'}`,
                    info: subcontractor
                } }));
        });
    };
    const handleTechnicianChange = (technician) => {
        setFormData((prevState) => {
            var _a;
            return (Object.assign(Object.assign({}, prevState), { assignee: {
                    type: "technician" /* AssigneeType.TECHNICIAN */,
                    key: `${"technician" /* AssigneeType.TECHNICIAN */}-${(_a = technician === null || technician === void 0 ? void 0 : technician.id) !== null && _a !== void 0 ? _a : 'unassigned'}`,
                    info: technician
                } }));
        });
    };
    const handlePONumberChange = (value) => {
        setFormData((prevState) => (Object.assign(Object.assign({}, prevState), { poNumber: value })));
    };
    const handleBillingLimitChange = (value) => {
        setFormData((prevState) => (Object.assign(Object.assign({}, prevState), { billingLimit: value })));
    };
    const handleDateChange = (e) => {
        const newDate = e.value;
        if (!newDate)
            return;
        setFormData((prevState) => (Object.assign(Object.assign({}, prevState), { start: dateClient(newDate)
                .tz(timezone)
                .year(newDate.getFullYear())
                .month(newDate.getMonth())
                .date(newDate.getDate())
                .hour(prevState.start.hour())
                .minute(prevState.start.minute())
                .second(0)
                .millisecond(0) })));
    };
    const handleTimeChange = (e) => {
        const newTime = e.value;
        if (!newTime)
            return;
        setFormData((prevState) => (Object.assign(Object.assign({}, prevState), { start: dateClient(prevState.start)
                .tz(timezone)
                .year(prevState.start.year())
                .month(prevState.start.month())
                .date(prevState.start.date())
                .hour(newTime.getHours())
                .minute(newTime.getMinutes())
                .second(0)
                .millisecond(0) })));
    };
    const kendoDate = useMemo(() => {
        return dateClient()
            .year(formData.start.year())
            .month(formData.start.month())
            .date(formData.start.date())
            .hour(0)
            .minute(0)
            .toDate();
    }, [formData.start]);
    const kendoTime = useMemo(() => {
        // kendo timepicker only needs the hour and minute values - disregard date and timezone info.
        return dateClient().hour(formData.start.hour()).minute(formData.start.minute()).toDate();
    }, [formData.start]);
    const handleDurationChange = (newDuration) => {
        setFormData((old) => (Object.assign(Object.assign({}, old), { durationMins: Duration.fromObject(Object.assign({ days: duration.days, hours: duration.hours, minutes: duration.minutes }, newDuration)).as('minutes') })));
    };
    const duration = useMemo(() => Duration.fromObject({ minutes: formData.durationMins }).shiftTo('days', 'hours', 'minutes'), [formData.durationMins]);
    const assigneeDropdown = useMemo(() => {
        var _a, _b;
        if (formData.assignee.type === "subcontractor" /* AssigneeType.SUBCONTRACTOR */)
            return (React.createElement(SubcontractorDropdown, { subcontractorList: subcontractorList, selectedId: (_a = formData.assignee.info) === null || _a === void 0 ? void 0 : _a.id, disabled: params.readonly, onChange: handleSubcontractorChange }));
        return (React.createElement(TechnicianDropdown, { technicianList: technicianList, selectedId: (_b = formData.assignee.info) === null || _b === void 0 ? void 0 : _b.id, disabled: params.readonly, onChange: handleTechnicianChange }));
    }, [formData.assignee]);
    const subcontractorOnlyFields = useMemo(() => {
        if (formData.assignee.type === "subcontractor" /* AssigneeType.SUBCONTRACTOR */ && formData.visitable.type === "WorkOrder" /* VisitableType.SERVICE */) {
            return (React.createElement("fieldset", { className: "form__set--horizontal--compact" },
                React.createElement("div", { className: "qmb-input--text--x-half form__field" },
                    React.createElement("input", { id: "po_number", value: formData.poNumber, onChange: (e) => handlePONumberChange(e.target.value), disabled: params.readonly }),
                    React.createElement("label", { className: "qmb-label", htmlFor: "po_number" }, "PO #")),
                React.createElement("div", { className: "qmb-input--text--x-half form__field" },
                    React.createElement("input", { id: "billing_limit", type: "number", min: "0", value: formData.billingLimit, onChange: (e) => handleBillingLimitChange(Number(e.target.value)), disabled: params.readonly }),
                    React.createElement("label", { className: "qmb-label", htmlFor: "billing_limit" }, "Billing Limit"))));
        }
        return null;
    }, [formData.assignee]);
    const assignedTypeToggleValue = useMemo(() => {
        return formData.assignee.type;
    }, [formData.assignee]);
    const AvailabilityContent = useMemo(() => {
        if (availabilityNotification) {
            return (React.createElement("div", { style: { height: '40px', margin: 'auto' } },
                availabilityNotification.message,
                React.createElement("br", null),
                availabilityNotification.data && (React.createElement("a", { href: availabilityNotification.data.link, target: "_blank", rel: "noreferrer" }, availabilityNotification.data.textLink))));
        }
        return null;
    }, [availabilityNotification]);
    useEffect(() => {
        var _a;
        setAvailabilityNotification(undefined);
        if (formData.assignee.type === "technician" /* AssigneeType.TECHNICIAN */) {
            apiClient
                .get(checkAvailableTechniciansPath({ format: 'json' }), {
                params: {
                    defaultObjectName: 'Visit',
                    defaultObjectId: visit.id,
                    technicianId: (_a = formData.assignee.info) === null || _a === void 0 ? void 0 : _a.id,
                    scheduledDate: formData.start.toDate(),
                    duration: formData.durationMins
                }
            })
                .catch((error) => {
                setAvailabilityNotification({
                    message: error.response.data.message,
                    data: error.response.data.data
                });
            });
        }
    }, [formData.start, formData.assignee]);
    const preventDateBackspace = (e) => {
        if (e.key === 'Backspace') {
            e.preventDefault();
        }
    };
    return (React.createElement("div", { className: "dialog__section" },
        React.createElement("div", { className: "dialog__header--section" },
            React.createElement("h2", { className: "dialog__title" }, translate('scheduling_details', { namespace }))),
        React.createElement(TechSubToggle, { render: tenant.flags.subcontractorsEnabled, type: assignedTypeToggleValue, onChange: handleAssigneeTypeChange }),
        React.createElement("fieldset", { className: "form__set--vertical--compact" },
            assigneeDropdown,
            React.createElement(VisitStatusDropdown, { statusList: visitStatusList, selectedElement: formData.status, disabled: params.readonly, onChange: handleStatusChange })),
        subcontractorOnlyFields,
        React.createElement("fieldset", { className: "form__set--horizontal--compact" },
            React.createElement("div", { className: "qmb-input--text--x-half form__field", onKeyDown: preventDateBackspace },
                React.createElement(DatePicker, { value: kendoDate, onChange: handleDateChange, disabled: params.readonly }),
                React.createElement("label", { className: "qmb-label" }, translate('scheduled_date', { namespace }))),
            React.createElement("div", { className: "qmb-input--text--x-half form__field", onKeyDown: preventDateBackspace },
                React.createElement(TimePicker, { value: kendoTime, onChange: handleTimeChange, disabled: params.readonly }),
                React.createElement("label", { className: "qmb-label" }, translate('scheduled_time', { namespace })))),
        React.createElement("fieldset", { className: "form__set--horizontal--compact" },
            React.createElement("div", { className: "form__field--x-quarter" },
                React.createElement("span", { className: "qmb-label", style: { width: '100%' } }, translate('duration', { namespace }))),
            React.createElement("div", { className: "qmb-input--number--x-quarter duration-field" },
                React.createElement("input", { type: "number", id: "duration_days", min: "0", value: duration.days, onChange: (e) => handleDurationChange({ days: e.target.value || 0 }), disabled: params.readonly }),
                React.createElement("label", { className: "qmb-label", htmlFor: "duration_days" }, translate('days', { namespace }))),
            React.createElement("div", { className: "qmb-input--number--x-quarter duration-field" },
                React.createElement("input", { type: "number", id: "duration_hours", min: "0", max: "23", value: duration.hours, onChange: (e) => handleDurationChange({ hours: e.target.value || 0 }), disabled: params.readonly }),
                React.createElement("label", { className: "qmb-label", htmlFor: "duration_hours" }, translate('hours', { namespace }))),
            React.createElement("div", { className: "qmb-input--number--x-quarter duration-field" },
                React.createElement("input", { type: "number", id: "duration_mins", min: "0", max: "59", value: duration.minutes, onChange: (e) => handleDurationChange({ minutes: e.target.value || 0 }), disabled: params.readonly }),
                React.createElement("label", { className: "qmb-label", htmlFor: "duration_mins" }, translate('minutes', { namespace })))),
        React.createElement("div", { className: "modal__footer", style: { marginTop: '16px' } },
            AvailabilityContent,
            React.createElement("div", { style: { height: '40px', margin: 'auto' } }),
            React.createElement("button", { type: "button", className: "qmb-button", id: "charge_customer_cancel_btn", onClick: closeModal }, translate('cancel', { namespace })),
            !params.readonly && (React.createElement("button", { type: "button", className: "qmb-button--submit", onClick: () => saveVisit(Object.assign(Object.assign({}, formData), { end: formData.start.add(formData.durationMins, 'minutes') })), disabled: params.readonly }, saving ? React.createElement(Loader, { size: LoaderSizes.small }) : translate('save', { namespace }))))));
}
export default Form;
