/* global google */

import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { status as draggingStatuses } from './Redux/draggingSlice';
import { fetchTechnicianLocations } from './DAL/dataAccess';
import { showFleetTrackingModal, updateDrivers } from './Redux/fleetTrackingSlice';
import { pinContent } from './utils';
import { actions, status } from './Redux/schedulerSlice';
import { dateClient } from '../../utils/date-client.ts';
import ContextEventDispatcher, { ContextEventDispatchEvent } from './Contexts/ContextEventDispatcher.tsx';

const googleScriptUrl =
  'https://maps.googleapis.com/maps/api/js?key=AIzaSyDCeC2og9B6d4Uo5koNx_RZjVVl0uiTqho&callback=initMap';

function GoogleMap({ centerLat, centerLng, visible }) {
  const dispatch = useDispatch();
  const store = useStore();
  const mapRef = useRef(null);
  const mapObjRef = useRef(null);
  const googleMapsLoaded = useRef(false);
  const contextsState = useRef(null);

  const visitsSelector = (state) => state.map.visits;
  const previousVisits = useRef(null);
  const techniciansSelector = (state) => state.scheduler.technicians;
  const previousTechnicians = useRef(null);
  const existingMarkers = useRef([]);
  const targetVisitSelector = (state) => state.edit.targetEvent;
  const previousTargetVisitId = useRef(null);

  const mapEvents = useRef([]);

  const technicianMarkers = useRef([]);

  const fleetTrackingActive = useSelector((state) => state.fleet.active);
  const drivers = useSelector((state) => state.fleet.techs);
  const vehicleSelector = (state) => state.fleet.selectedTech;
  const currentSchedulerAction = (state) => state.scheduler.action;
  const currentSchedulerStatus = (state) => state.scheduler.status;
  const currentDraggingStatus = (state) => state.dragging.status;
  const previousSelectedVehicleId = useRef(null);
  const previousSchedulerEvents = useRef([]);

  const currentClusterer = useRef(null);

  useEffect(() => {
    const eventListener = (event) => {
      contextsState.current = event.detail;
      checkAsignees();
      checkActiveVisits();
      checkEventsGroupedByAssignee();
    };

    document.addEventListener(ContextEventDispatchEvent, eventListener);
    return () => document.removeEventListener(ContextEventDispatchEvent, eventListener);
  }, []);

  const checkEventsGroupedByAssignee = () => {
    if (!contextsState.current?.eventsGroupedByAssignee) return;
    const schedulerEvents = Object.keys(contextsState.current.eventsGroupedByAssignee || {}).flatMap(
      (key) => contextsState.current.eventsGroupedByAssignee[key]
    );
    const previousEvents = previousSchedulerEvents.current || [];
    const schedulerEventsChanged =
      schedulerEvents.length !== previousEvents.length ||
      schedulerEvents.some((x) => !previousEvents.some((y) => y.id === x.id)) ||
      previousEvents.some((x) => !schedulerEvents.some((y) => y.id === x.id));
    if (schedulerEventsChanged) {
      previousSchedulerEvents.current = schedulerEvents;
      checkIfLoadMarkers();
    }
  };

  const checkAsignees = () => {
    // Filter out assignees hidden from map
    if (contextsState.current.assigneesOnMap === 'all') {
      existingMarkers.current
        .filter(({ visit }) => visit.noCluster)
        .forEach(({ marker }) => {
          marker.setMap(mapObjRef.current);
        });
    } else {
      existingMarkers.current
        .filter(({ visit }) => visit.noCluster)
        .forEach(({ visit, marker }) => {
          if (contextsState.current.assigneesOnMap.includes(visit.assignee.key)) {
            marker.setMap(mapObjRef.current);
          } else {
            marker.setMap(null);
          }
        });
    }
  };

  useEffect(() => {
    const loadJS = (src) => {
      const ref = window.document.getElementsByTagName('script')[0];
      const script = window.document.createElement('script');
      script.src = src;
      script.async = true;
      ref.parentNode.insertBefore(script, ref);
    };

    function isScriptAdded(src) {
      const scriptElement = document.querySelector(`script[src="${src}"]`);
      return scriptElement !== null;
    }

    const initMap = async () => {
      if (typeof google === 'object' && typeof google.maps === 'object') {
        googleMapsLoaded.current = true;
        if (mapObjRef.current === null) {
          const map = new google.maps.Map(mapRef.current, {
            center: { lat: centerLat, lng: centerLng },
            zoom: 10,
            mapId: '6b511e86ce5460cb'
          });
          mapObjRef.current = map;
          loadCenterMarker(centerLat, centerLng);
        }
      } else if (!isScriptAdded(googleScriptUrl)) {
        loadJS(googleScriptUrl);
        window.initMap = () => {
          googleMapsLoaded.current = true;
          const map = new google.maps.Map(mapRef.current, {
            center: { lat: centerLat, lng: centerLng },
            zoom: 10,
            mapId: '6b511e86ce5460cb'
          });
          mapObjRef.current = map;
          loadCenterMarker(centerLat, centerLng);
        };
      }
    };

    initMap().then(() => {
      // not waiting 100ms leads to mapObjRef not being set
      setTimeout(() => {
        loadTechniciansAsMarkers(drivers);
      }, 250);
    });
  }, [loadTechniciansAsMarkers, drivers]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshTechnicianLocations();
    }, 2 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [refreshTechnicianLocations, fleetTrackingActive, drivers]);

  const loadCenterMarker = async (lat, lng) => {
    const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');

    const parser = new DOMParser();
    const centerSVGString = `
            <svg version="1.1" height="32" width="32" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32" xml:space="preserve">
<path d="m6.45 4.15-.29-.46c-.26-.42-.71-.67-1.2-.67H1.31v5.07h4.78l.29.47c.02.02.05.04.07.06V4.15z" style="fill:#f1604c"/>
<path d="M31.19 31h-1.5V11.72c0-.83-.67-1.5-1.5-1.5H12.02v.99H7.59c-.91 0-1.75-.37-2.38-.99h-1.4c-.83 0-1.5.67-1.5 1.5V31h-1V9.08h4.23a2.42 2.42 0 0 0 2.05 1.14h3.43V3.16H7a2.398 2.398 0 0 0-2.05-1.14H1.31V1.01c0-.28-.22-.5-.5-.5s-.5.22-.5.5V31.5c0 .04.01.08.02.12.06.22.25.38.48.38h30.38c.28 0 .5-.22.5-.5s-.22-.5-.5-.5zM4.95 3.02c.49 0 .94.25 1.2.67l.29.47h3.57v5.07H7.59c-.49 0-.94-.25-1.2-.67l-.3-.48H1.31V3.02h3.64z" style="fill:#010101;stroke:#010101;stroke-width:2;stroke-miterlimit:10;stroke-opacity:.06"/>
<path style="fill:#f0eff4" class="st2" d="M23.5 22.99h-15c-.28 0-.5-.22-.5-.5V20.1c0-.28.22-.5.5-.5h15c.28 0 .5.22.5.5v2.39c0 .27-.22.5-.5.5zM22.97 24.99H9.03c-.29 0-.53-.22-.53-.5s.24-.5.53-.5h13.95c.29 0 .53.22.53.5s-.25.5-.54.5zM22.97 26.99H9.03c-.29 0-.53-.22-.53-.5s.24-.5.53-.5h13.95c.29 0 .53.22.53.5s-.25.5-.54.5zM22.97 28.99H9.03c-.29 0-.53-.22-.53-.5s.24-.5.53-.5h13.95c.29 0 .53.22.53.5s-.25.5-.54.5zM22.97 30.99H9.03c-.29 0-.53-.22-.53-.5s.24-.5.53-.5h13.95c.29 0 .53.22.53.5s-.25.5-.54.5zM23 14.22v1.39h-5.5v-1.39H23m.5-1H17c-.28 0-.5.22-.5.5v2.39c0 .28.22.5.5.5h6.5c.28 0 .5-.22.5-.5v-2.39c0-.28-.22-.5-.5-.5zM14.5 14.22v1.39H9v-1.39h5.5m.5-1H8.5c-.28 0-.5.22-.5.5v2.39c0 .28.22.5.5.5H15c.28 0 .5-.22.5-.5v-2.39c0-.28-.22-.5-.5-.5z"/>
<path style="fill:#c0476b" class="st3" d="M5.89 3.38s-.01-.01-.02-.01c.01 0 .01.01.02.01zM7 4.16h-.55v-.01 4.47c.27.38.68.6 1.14.6h2.43V4.16H7z"/>
</svg>`;
    const centerSVG = parser.parseFromString(centerSVGString, 'image/svg+xml').documentElement;

    // eslint-disable-next-line no-new
    new AdvancedMarkerElement({
      map: mapObjRef.current,
      position: { lat, lng },
      content: centerSVG
    });
  };

  const checkIfLoadMarkers = () => {
    const schedulerEvents = Object.keys(contextsState.current?.eventsGroupedByAssignee || {}).flatMap(
      (key) => contextsState.current.eventsGroupedByAssignee[key]
    );
    const storeVisits = visitsSelector(store.getState());
    const visits = storeVisits
      .filter((x) => !schedulerEvents.some((y) => y.id === x.id))
      .concat(schedulerEvents.map((event) => ({ ...event, noCluster: true })));
    const technicians = techniciansSelector(store.getState());

    const visitsChanged =
      (previousVisits.current || []).length !== visits.length ||
      visits.some((visit) => {
        const previousVisitDef = (previousVisits.current || []).find((prevVisit) => visit.id === prevVisit.id);

        if (!previousVisitDef) {
          return true;
        }

        const subcontractorAsigned =
          previousVisitDef.assignee?.subcontractor !== undefined && visit.assignee?.subcontractor === undefined;
        const subcontractorChanged = previousVisitDef.assignee?.subcontractor?.id !== visit.assignee?.subcontractor?.id;
        const technicianAsigned =
          previousVisitDef.assignee?.technician !== undefined && visit.assignee?.technician === undefined;
        const technicianChanged =
          previousVisitDef.assignee?.technician?.id !== visit.assignee?.technician?.id ||
          // Shouldn't reach with new assignee stuff, but until refactor keep as precaution
          previousVisitDef.technicianId !== visit.technicianId;
        const schedulerChanged = previousVisitDef.noCluster !== visit.noCluster;

        return (
          subcontractorAsigned || subcontractorChanged || technicianAsigned || technicianChanged || schedulerChanged
        );
      });

    const techniciansChanged = technicians !== previousTechnicians.current;

    if (visitsChanged || techniciansChanged) {
      if (googleMapsLoaded.current) {
        const mergedVisits = [];
        const encounteredKeys = [];

        mergedVisits.push(...mapEvents.current);
        encounteredKeys.push(...mapEvents.current.map((visit) => visit.key));
        mergedVisits.push(...visits.filter((visit) => !encounteredKeys.some((x) => x.replace('-', '_') === visit.key)));

        loadVisitsAsMarkers(mergedVisits);
      }
    }
    previousVisits.current = visits;
    previousTechnicians.current = technicians;
  };

  const checkActiveVisits = () => {
    if (contextsState.current.activeVisit) {
      const targetMarker = existingMarkers.current.find((x) => x.visit.id === contextsState.current.activeVisit);

      if (targetMarker) {
        mapObjRef.current.panTo({ lat: targetMarker?.marker.position.lat, lng: targetMarker?.marker.position.lng });
        mapObjRef.current.setZoom(20);
        focusMarkers(targetMarker.visit.key);
      } else {
        focusMarkers();
      }
    } else {
      focusMarkers();
    }
  };

  const checkNewTargetVisit = () => {
    const targetVisit = targetVisitSelector(store.getState());
    if (targetVisit && targetVisit?.id !== previousTargetVisitId.current) {
      const marker = existingMarkers.current.find((x) => x.visit.key === targetVisit.key)?.marker;
      if (marker) {
        mapObjRef.current.panTo({ lat: marker.position.lat, lng: marker.position.lng });
        mapObjRef.current.setZoom(16);
        focusMarkers(targetVisit.key);
      } else {
        focusMarkers();
      }
    } else {
      focusMarkers();
    }
    previousTargetVisitId.current = targetVisit?.id ?? null;
  };

  const checkNewSelectedVehicle = () => {
    const selectedTech = vehicleSelector(store.getState());
    if (selectedTech && selectedTech?.id !== previousSelectedVehicleId.current) {
      mapObjRef.current.panTo({ lat: selectedTech.lat, lng: selectedTech.lng });
      mapObjRef.current.setZoom(16);
    }
    previousSelectedVehicleId.current = selectedTech?.id ?? null;
  };

  store.subscribe(() => {
    const schedulerAction = currentSchedulerAction(store.getState());
    const schedulerStatus = currentSchedulerStatus(store.getState());
    const draggingStatus = currentDraggingStatus(store.getState());
    if (
      schedulerAction === actions.NONE &&
      schedulerStatus === status.READY &&
      draggingStatus === draggingStatuses.NONE
    ) {
      checkNewTargetVisit();
      checkIfLoadMarkers();
      checkNewSelectedVehicle();
    }
  });

  const HandleDriverMouseClick = (e, tech) => {
    dispatch(showFleetTrackingModal(tech));
  };

  const focusMarkers = (key) => {
    existingMarkers.current.forEach((x) => {
      const opacity = key && x.visit.key !== key ? '0.3' : '1';
      if (x.marker) {
        x.marker.targetElement.style.opacity = opacity;
      }
    });
  };

  const HandleMouseDown = (e, _, visit) => {
    mapObjRef.current.setOptions({
      draggable: false,
      zoomControl: false,
      scrollwheel: false,
      disableDoubleClickZoom: true
    });

    // todo, like in visit panel, once refactor can just pass self instead of re-mapping'
    let mappedVisit;
    if (visit.key.includes('_')) {
      // Old visit object from visit panel
      mappedVisit = {
        key: `${visit.type}-${visit.id}`,
        type: visit.type,
        id: visit.id,
        assignee: visit.assignee,
        start: dateClient(visit.startTime).tz(contextsState.current.timezone),
        end: dateClient(visit.endTime).tz(contextsState.current.timezone),
        title: visit.title,
        subtitle: visit.subtitle,
        status: visit.statusObj,
        building: visit.buildingObj,
        visitable: visit.visitable
      };
    } else {
      // New typed visit structure from scheduler
      mappedVisit = {
        ...visit,
        start: dateClient(visit.start).tz(contextsState.current.timezone),
        end: dateClient(visit.end).tz(contextsState.current.timezone)
      };
    }

    contextsState.current.startDragging(mappedVisit, () => contextsState.current.stopDragging());
    document.addEventListener('mouseup', HandleMouseUp, { once: true });
  };

  const HandleMouseUp = () => {
    mapObjRef.current.setOptions({
      draggable: true,
      zoomControl: true,
      scrollwheel: true,
      disableDoubleClickZoom: true
    });
  };

  const HandleMouseClick = (_, __, visit) => {
    contextsState.current.openVisitModal(visit.id);
  };

  const loadVisitsAsMarkers = async (newVisits) => {
    if (!google.maps) return;
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary('marker');
    const schedulerEvents = Object.keys(contextsState.current.eventsGroupedByAssignee || {}).flatMap(
      (key) => contextsState.current.eventsGroupedByAssignee[key]
    );

    existingMarkers.current
      .filter(({ visit }) => visit.noCluster)
      .forEach(({ marker }) => {
        marker.setMap(null);
      });

    existingMarkers.current = [];

    const newMarkers = [];
    for (let i = 0; i < newVisits.length; i += 1) {
      const visit = { ...newVisits[i] };

      if (!visit.building) continue;

      // Tmp solution until refactor map component with typescript
      if (!(visit.lat && visit.lng)) {
        visit.lat = visit.building.latitude;
        visit.lng = visit.building.longitude;
      }

      if (visit.lat && visit.lng) {
        const min = -0.00004;
        const max = 0.00004;

        const jitterLat = Math.random() * (max - min) + min;
        const jitterLng = Math.random() * (max - min) + min;

        const pin = new PinElement(pinContent(visit));

        const marker = new AdvancedMarkerElement({
          id: `${visit.type}_${visit.id}`,
          position: { lat: visit.lat + jitterLat, lng: visit.lng + jitterLng },
          title: visit.building.name ?? visit.building,
          map: visit.noCluster ? mapObjRef.current : undefined,
          content: pin.element,
          draggable: false
        });

        marker.addListener('click', (e) => {
          HandleMouseClick(e, marker, visit);
        });
        marker.content.addEventListener('mousedown', (e) => {
          HandleMouseDown(e, marker, visit);
        });
        newMarkers.push({ visit, marker });
      }
    }

    const markersToCluster = newMarkers.filter(({ visit }) => !visit.noCluster);

    existingMarkers.current = newMarkers;
    // eslint-disable-next-line no-new
    if (currentClusterer.current) {
      currentClusterer.current.clearMarkers();
      currentClusterer.current.addMarkers(markersToCluster.map((x) => x.marker));
      return;
    }

    currentClusterer.current = new MarkerClusterer({
      markers: newMarkers
        .filter((x) => !schedulerEvents.some((scheEvent) => scheEvent.id === x.id))
        .map((x) => x.marker),
      map: mapObjRef.current,
      renderer: {
        render: ({ count, position }) => {
          // create svg url with fill color
          const originalSvg = window.btoa(`
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve">
            <style type="text/css">
              .st0{opacity:0.2;}
              .st1{fill:#773584;}
              .st2{opacity:0.95;}
            </style>
            <g class="st0">
              <circle class="st1" cx="20" cy="20" r="17"/>
            </g>
            <g class="st2">
              <circle class="st1" cx="20" cy="20" r="14"/>
            </g>
            </svg>`);

          const hoverSvg = window.btoa(`
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve">
              <style type="text/css">
                .st0{opacity:0.3;}
                .st1{fill:#B24F6B;}
                .st2{opacity:0.95;}
              </style>
              <g class="st0">
                <circle class="st1" cx="20" cy="20" r="20"/>
              </g>
              <g class="st2">
                <circle class="st1" cx="20" cy="20" r="14"/>
              </g>
              </svg>
              `);

          // create marker using svg icon
          const clusterMarker = new google.maps.Marker({
            position,
            icon: {
              url: `data:image/svg+xml;base64,${originalSvg}`,
              scaledSize: new google.maps.Size(45, 45)
            },
            label: {
              text: String(count),
              color: 'rgba(255,255,255,0.9)',
              fontSize: '11px',
              fontWeight: '600',
              fontFamily: 'HK Grotesk'
            },
            // adjust zIndex to be above other markers
            zIndex: 1000 + count
          });

          google.maps.event.addListener(clusterMarker, 'mouseover', () => {
            clusterMarker.setIcon({
              url: `data:image/svg+xml;base64,${hoverSvg}`,
              scaledSize: new google.maps.Size(45, 45)
            });
          });

          google.maps.event.addListener(clusterMarker, 'mouseout', () => {
            clusterMarker.setIcon({
              url: `data:image/svg+xml;base64,${originalSvg}`,
              scaledSize: new google.maps.Size(45, 45)
            });
          });

          return clusterMarker;
        }
      }
    });
  };

  const refreshTechnicianLocations = useCallback(async () => {
    if (!fleetTrackingActive || !googleMapsLoaded.current) {
      return;
    }
    const results = await fetchTechnicianLocations();
    dispatch(updateDrivers(results));
  }, [fleetTrackingActive, dispatch]);

  const loadTechniciansAsMarkers = async (techs) => {
    const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');

    for (let i = 0; i < technicianMarkers.current.length; i += 1) {
      technicianMarkers.current[i].marker.setMap(null);
    }

    const newMarkers = [];
    for (let i = 0; i < techs.length; i += 1) {
      const tech = techs[i];

      if (tech.lat && tech.lng) {
        const min = -0.00004;
        const max = 0.00004;

        const jitterLat = Math.random() * (max - min) + min;
        const jitterLng = Math.random() * (max - min) + min;

        const pin = pinContentTech(tech);

        const marker = new AdvancedMarkerElement({
          id: `${tech.name}_${tech.id}`,
          position: { lat: tech.lat + jitterLat, lng: tech.lng + jitterLng },
          map: mapObjRef.current,
          title: `${tech.vehicle_name} Status: ${tech.vehicle_status}`,
          content: pin,
          draggable: false
        });

        marker.addListener('click', (e) => {
          HandleDriverMouseClick(e, tech);
        });

        newMarkers.push({ tech, marker });
      }
    }

    technicianMarkers.current = newMarkers;
  };

  const pinContentTech = (tech) => {
    const parser = new DOMParser();

    const pinSVGString = `
      <svg style="enable-background:new 0 0 19.93 24" xmlns="http://www.w3.org/2000/svg" version="1.1" height="24" width="24" x="0" y="0" viewBox="0 0 24 24" xml:space="preserve">
        <path style="fill: ${tech.color}; stroke-width: 0px" d="M17.19,20.69c-.83,0-1.5-.67-1.5-1.5v-.5h-7v.5c0,.83-.67,1.5-1.5,1.5h-1c-.83,0-1.5-.67-1.5-1.5v-1.26c-.64-.57-1-1.37-1-2.24V6.69c0-1.65,1.35-3,3-3h11c1.65,0,3,1.35,3,3v9c0,.87-.36,1.67-1,2.24v1.26c0,.83-.67,1.5-1.5,1.5h-1ZM17.19,13.69c-.28,0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5ZM7.19,13.69c-.28,0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5ZM16.05,10.69l-.41-1.62c-.07-.28-.29-.38-.49-.38h-5.97c-.17,0-.39.1-.46.37l-.41,1.63h7.72Z"/>
        <path style="fill: #fff; stroke-width: 0px" d="M17.69,4.19c1.38,0,2.5,1.12,2.5,2.5v9c0,.82-.39,1.54-1,2v1.5c0,.55-.45,1-1,1h-1c-.55,0-1-.45-1-1v-1h-8v1c0,.55-.45,1-1,1h-1c-.55,0-1-.45-1-1v-1.5c-.61-.46-1-1.18-1-2V6.69c0-1.38,1.12-2.5,2.5-2.5h11M7.69,11.19h9l-.56-2.24c-.11-.47-.51-.76-.97-.76h-5.97c-.43,0-.83.28-.94.76l-.56,2.24M17.19,15.19c.55,0,1-.45,1-1s-.45-1-1-1-1,.45-1,1,.45,1,1,1M7.19,15.19c.55,0,1-.45,1-1s-.45-1-1-1-1,.45-1,1,.45,1,1,1M17.69,3.19H6.69c-1.93,0-3.5,1.57-3.5,3.5v9c0,.93.36,1.8,1,2.45v1.05c0,1.1.9,2,2,2h1c1.1,0,2-.9,2-2h6c0,1.1.9,2,2,2h1c1.1,0,2-.9,2-2v-1.05c.64-.65,1-1.52,1-2.45V6.69c0-1.93-1.57-3.5-3.5-3.5h0ZM8.97,10.19l.25-1h5.94s.25,1,.25,1h-6.44Z"/>
      </svg>`;

    return parser.parseFromString(pinSVGString, 'image/svg+xml').documentElement;
  };

  return (
    <div
      className="map__figure"
      style={visible ? { flex: 1, visibility: 'visible' } : { flex: 0, visibility: 'hidden' }}>
      <div ref={mapRef} style={{ width: '100%', height: '100%' }} />
    </div>
  );
}

function GoogleMapEventWrapper({ centerLat, centerLng, visible }) {
  return (
    <>
      <GoogleMap centerLat={centerLat} centerLng={centerLng} visible={visible} />
      <ContextEventDispatcher />
    </>
  );
}

export default React.memo(GoogleMapEventWrapper);

GoogleMap.propTypes = {
  centerLat: PropTypes.number,
  centerLng: PropTypes.number,
  visible: PropTypes.bool
};

GoogleMap.defaultProps = {
  centerLat: null,
  centerLng: null,
  visible: true
};

GoogleMapEventWrapper.propTypes = {
  centerLat: PropTypes.number,
  centerLng: PropTypes.number,
  visible: PropTypes.bool
};

GoogleMapEventWrapper.defaultProps = {
  centerLat: null,
  centerLng: null,
  visible: true
};
