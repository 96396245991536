import { dateClient } from './date-client';
export function transformDateToDayJs(date, timezone) {
    const unprocessedDate = new Date(date);
    return dateClient()
        .tz(timezone)
        .year(unprocessedDate.getFullYear())
        .month(unprocessedDate.getMonth())
        .date(unprocessedDate.getDate())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);
}
export function transformDayJstoDate(date) {
    return dateClient().year(date.year()).month(date.month()).date(date.date()).hour(0).minute(0).toDate();
}
