import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import DocumentList from './DocumentList.tsx';

const queryClient = new QueryClient();

function DocumentsList(props) {
  return (
    <QueryClientProvider client={queryClient}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <DocumentList {...props} />
    </QueryClientProvider>
  );
}

export default DocumentsList;

DocumentsList.propTypes = {
  isPrime: PropTypes.bool,
  workOrderId: PropTypes.number,
  inspectionId: PropTypes.number,
  canDelete: PropTypes.bool,
  canCreate: PropTypes.bool
};

DocumentsList.defaultProps = {
  isPrime: false,
  workOrderId: undefined,
  inspectionId: undefined,
  canDelete: false,
  canCreate: false
};
