/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import './Button.scss';

export const ButtonSize = {
  Auto: 'auto',
  Full: 'full'
};

export const ButtonVariant = {
  Primary: 'primary',
  Empty: 'empty',
  BlueAccent: 'blue',
  Highlight: 'highlight',
  GrayAccent: 'gray',
  Outline: 'outline'
};

function Button(
  { children, size = ButtonSize.Auto, variant = ButtonVariant.Empty, width, className, type = 'button', ...props },
  ref
) {
  return (
    <button
      ref={ref}
      type={type}
      style={width ? { width: `${width}px`, display: 'inline-flex', justifyContent: 'center' } : {}}
      className={clsx('qmb-control custom-button', className, {
        'full-size': size === ButtonSize.Full,
        'blue-variant': variant === ButtonVariant.BlueAccent,
        'gray-variant': variant === ButtonVariant.GrayAccent,
        'primary-variant': variant === ButtonVariant.Primary,
        'qmb-control--highlight': variant === ButtonVariant.Highlight,
        'outline-variant': variant === ButtonVariant.Outline
      })}
      {...props}>
      {children}
    </button>
  );
}

export default forwardRef(Button);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
  type: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string
};

Button.defaultProps = {
  className: '',
  type: 'button',
  width: undefined,
  size: ButtonSize.Auto,
  variant: ButtonVariant.Empty
};
