import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table, { TableSize } from '@components/Table';
import { priceListPath, newPriceRulePath, priceRulePath } from 'routes';
import api from '@utils/axios';
import I18n, { translate } from '@utils/i18n';
import ActionButtons from './ActionButtons';

const namespace = 'features.settings.price_lists.show';

function Show({ priceList: initialPriceList, currency, priceFactors, selectedPriceFactorIds, notEditable }) {
  const [priceList, setPriceList] = useState(initialPriceList);
  const [lists, setLists] = useState(priceList.price_rules);
  const [isNameEdit, setIsNameEdit] = useState(false);
  const [priceFactorIds, setPriceFactorIds] = useState(selectedPriceFactorIds);
  const [errorMessage, setErrorMessage] = useState('');

  const onDelete = (priceRule) => {
    return () => {
      api
        .delete(priceRulePath(priceRule.id))
        .then(() => setLists((draft) => draft.filter((list) => priceRule.id !== list.id)));
    };
  };

  const priceRuleValue = (item) => {
    if (item.rule_type === 'fixed') return `${currency}${item.value} `;

    return `${item.value}% `;
  };

  const priceRule = (item) => priceRuleValue(item) + I18n.t(`enums.price_rule.rule_types.${item.rule_type}`);

  const columns = [
    {
      label: translate('applicable_on', { namespace }),
      dataKey: 'rule_application',
      width: 250,
      renderer: ({ item }) => <a href={priceRulePath(item.id)}>{item.as_applicable}</a>
    },
    {
      label: translate('price_rule', { namespace }),
      align: 'left',
      dataKey: 'id',
      renderer: ({ item }) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ margin: 'auto 0' }}>{priceRule(item)}</div>
          {!notEditable && <ActionButtons priceRule={item} onDelete={onDelete(item)} />}
        </div>
      )
    }
  ];

  const onSave = () => {
    api
      .put(priceListPath(priceList.id), { price_list: priceList })
      .then(({ data }) => {
        setPriceList(data.price_list);
        setErrorMessage('');
        setIsNameEdit(false);
      })
      .catch((error) => {
        setErrorMessage(error.response.data);
      });
  };

  const onCheckActive = () => {
    api
      .put(priceListPath(priceList.id), { price_list: { active: !priceList.active } })
      .then(() => setPriceList((draft) => ({ ...draft, active: !draft.active })));
  };

  const onUpdateFactors = (e) => {
    const idNumber = parseInt(e.target.value, 10);
    let newPriceFactors;
    if (e.target.checked) {
      newPriceFactors = [...priceFactorIds, idNumber];
    } else {
      newPriceFactors = priceFactorIds.filter((item) => item !== idNumber);
    }

    api
      .put(priceListPath(priceList.id), { price_list: { proposal_factor_ids: newPriceFactors } })
      .then(() => setPriceFactorIds(newPriceFactors));
  };

  return (
    <div className="workspace__body">
      <div className="workspace__major--detail">
        {notEditable && (
          <div className="qmb-blurb--callout--attention">
            <i className="fa-regular fa-file-circle-exclamation blurb__icon" />
            <div className="blurb__body">{translate('not_editable', { namespace })}</div>
          </div>
        )}
        <div className="workspace__header">
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} className="header__main">
            <h1 className="workspace__title">
              {translate('title', { namespace })}:&nbsp;
              {!isNameEdit && priceList.name}
              {isNameEdit && (
                <span style={{ display: 'inline-block', height: '32px', verticalAlign: 'bottom' }}>
                  <input
                    value={priceList.name}
                    style={{ marginBottom: '0' }}
                    onBlur={onSave}
                    autoFocus
                    onChange={(e) => setPriceList((draft) => ({ ...draft, name: e.target.value }))}
                    type="text"
                  />
                  <span className="k-form-error k-invalid-msg">{errorMessage}</span>
                </span>
              )}
              &nbsp;
              <button
                style={{ backgroundColor: '#EFEFF4' }}
                type="button"
                onClick={() => setIsNameEdit(true)}
                className="qmb-control grey --small">
                <i className="fa-light fa-pencil" />
                {I18n.t('generic.edit')}
              </button>
            </h1>
            {/* <span className="qmb-toggle-switch--check">
              <input
                id="check_active"
                disabled={priceList.is_default}
                type="checkbox"
                checked={priceList.active}
                onChange={onCheckActive}
                readOnly
              />
              <label className="switch__visual" htmlFor="check_active">
                &nbsp;
              </label>
              <label className="qmb-label" htmlFor="check_active">
                {I18n.t('generic.active')}
              </label>
            </span> */}
          </div>
        </div>
        <ul className="workspace__tabs">
          <li className="active">
            <a href="#price_rules" data-toggle="tab" className="tabs__option">
              {translate('price_rules', { namespace })}
            </a>
          </li>
          <li>
            <a href="#configuration" data-toggle="tab" className="tabs__option">
              {translate('configuration', { namespace })}
            </a>
          </li>
        </ul>

        <div className="workspace__tab-container tab-content">
          <article className="workspace__tab-pane active tab-pane" id="price_rules">
            <div style={{ width: '100%' }}>
              {lists.length === 0 && (
                <div style={{ textAlign: 'center', marginTop: '25px', color: '#1967D2' }}>
                  {translate('no_rules', { namespace })}
                </div>
              )}
              {lists.length > 0 && <Table columns={columns} data={lists} size={TableSize.Full} variant="listing" />}
            </div>
            {!notEditable && (
              <div className="qmb-content-actions">
                <div style={{ width: '100%', marginTop: '10px' }} className="content-actions">
                  <a
                    href={newPriceRulePath({ price_list_id: priceList.id })}
                    className="qmb-control"
                    style={{ display: 'block', width: '100%', backgroundColor: '#1967D214', color: '#1967D2' }}>
                    <i className="fa-light fa-circle-plus" />
                    &nbsp;
                    {translate('add_price_rule', { namespace })}
                  </a>
                </div>
              </div>
            )}
          </article>
          <article className="workspace__tab-pane workspace__section tab-pane" id="configuration">
            <div className="qmb-form">
              <h3 className="section__label--section">{translate('price_factors', { namespace })}</h3>
              <p>{translate('price_factors_details', { namespace })}:</p>
              {priceFactors.map((factor) => (
                <label className="qmb-checkbox" key={factor.id}>
                  <input
                    disabled={notEditable}
                    type="checkbox"
                    onChange={onUpdateFactors}
                    checked={priceFactorIds.includes(factor.id)}
                    value={factor.id}
                    id={factor.id}
                  />
                  <span>{factor.description}</span>
                </label>
              ))}
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

Show.propTypes = {
  priceList: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  priceFactors: PropTypes.array.isRequired,
  notEditable: PropTypes.bool.isRequired,
  selectedPriceFactorIds: PropTypes.array.isRequired
};

export default Show;
