import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Window } from '@progress/kendo-react-dialogs';
import { translate } from '@utils/i18n';
import { VisitModalContext } from '../VisitModalContext';
import { Loader, LoaderSizes } from '../../../../components/Loader/Loader';
import { inspectionPath, workOrderPath } from '../../../../routes';
import Error from '../../../../components/Error';
import Details from './details';
import Form from './form';
const namespace = 'features.modals.visit_modal';
function VisitModal() {
    const { visit, modalReady, modalError, saving, closeModal } = useContext(VisitModalContext);
    const [userSelectionLocked, setUserSelectionLocked] = useState(false);
    const setUserSelectionLock = useCallback((preventSelections) => {
        if (userSelectionLocked && !preventSelections)
            setUserSelectionLocked(false);
        if (!userSelectionLocked && preventSelections)
            setUserSelectionLocked(true);
    }, [userSelectionLocked]);
    useEffect(() => {
        var _a;
        (_a = document.querySelector('.k-overlay')) === null || _a === void 0 ? void 0 : _a.addEventListener('click', closeModal);
    }, []);
    const header = useMemo(() => {
        let icon;
        let title;
        let link;
        if (!visit || !modalReady) {
            icon = React.createElement(Loader, { size: LoaderSizes.small });
            title = translate('loading', { namespace });
            link = null;
        }
        else {
            switch (visit.visitable.type) {
                case "WorkOrder" /* VisitableType.SERVICE */:
                    title = 'Work Order';
                    icon = React.createElement("i", { className: "fa-light fa-screwdriver-wrench" });
                    link = workOrderPath(visit.visitable.id);
                    break;
                case "Inspection" /* VisitableType.INSPECTION */:
                default:
                    title = 'Inspection';
                    icon = React.createElement("i", { className: "fa-light fa-clipboard-check" });
                    link = inspectionPath(visit.visitable.id);
                    break;
            }
        }
        return (React.createElement("div", { style: { width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' } },
            React.createElement("span", { className: "dialog__faux-title" },
                icon,
                " ",
                title),
            link && (React.createElement("a", { href: link, target: "_blank", className: "qmb-control--sm", rel: "noreferrer" },
                translate('details_link', { namespace }),
                React.createElement("i", { className: "fa-light fa-arrow-up-right-from-square" })))));
    }, [visit, modalReady]);
    const content = useMemo(() => {
        if (modalError)
            return React.createElement(Error, { error: modalError });
        if (modalReady)
            return (React.createElement(React.Fragment, null,
                React.createElement(Details, null),
                React.createElement("hr", { className: "dialog__split" }),
                React.createElement(Form, { readonly: saving })));
        return (React.createElement("div", { style: { height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' } },
            React.createElement(Loader, { size: LoaderSizes.large })));
    }, [modalReady]);
    return (React.createElement(Window, { modal: true, onClose: closeModal, title: header, minWidth: 240, initialWidth: 480, initialHeight: 640, minHeight: 640, minimizeButton: () => null, maximizeButton: () => null, restoreButton: () => null, className: "qmb-dialog", style: { userSelect: userSelectionLocked ? 'none' : 'auto' }, onResize: (e) => setUserSelectionLock(!e.end) }, content));
}
export default VisitModal;
