import React, { useContext, useMemo, useRef } from 'react';
import { StatusMultiSelectContext } from '@components/Multiselects/StatusMultiSelect/StatusMultiSelectContext';
function StatusTypeSelector(params) {
    const { selectedInspectionStatuses, selectedVisitStatuses, defaultInspectionStatuses, defaultVisitStatuses, setActiveMultiSelector } = useContext(StatusMultiSelectContext);
    const buttonStyle = { justifyContent: 'space-between' };
    const counterStyle = {
        marginRight: '2rem',
        background: '#F0EDF8',
        color: '#3D1B9D',
        boxSizing: 'border-box',
        padding: '.2rem',
        borderRadius: '20%'
    };
    const disabled = useMemo(() => {
        // Inspection Statuses
        const selectedInspectionKeys = new Set(selectedInspectionStatuses.map((status) => status.key));
        const defaultInspectionKeys = new Set(defaultInspectionStatuses.map((status) => status.key));
        const inspectionDisabled = selectedInspectionKeys.size === defaultInspectionKeys.size &&
            [...selectedInspectionKeys].every((key) => defaultInspectionKeys.has(key));
        // Visit Statuses
        const selectedVisitKeys = new Set(selectedVisitStatuses.map((status) => status.key));
        const defaultVisitKeys = new Set(defaultVisitStatuses.map((status) => status.key));
        const visitDisabled = selectedVisitKeys.size === defaultVisitKeys.size &&
            [...selectedVisitKeys].every((key) => defaultVisitKeys.has(key));
        return inspectionDisabled && visitDisabled;
    }, [selectedInspectionStatuses, selectedVisitStatuses, defaultInspectionStatuses, defaultVisitStatuses]);
    const techAnchor = useRef(null);
    const subAnchor = useRef(null);
    const handleInspectionsClick = () => {
        setActiveMultiSelector("Inspection" /* VisitableType.INSPECTION */);
    };
    const handleVisitsClick = () => {
        setActiveMultiSelector("WorkOrder" /* VisitableType.SERVICE */);
    };
    return (React.createElement("div", { className: "qmb-popup--actions--y-max" },
        React.createElement("div", { className: "popup__section--y-fill" },
            React.createElement("button", { className: "qmb-control--menu", type: "button", style: buttonStyle, onClick: handleInspectionsClick },
                React.createElement("span", null, "Inspections"),
                React.createElement("span", { style: counterStyle, ref: techAnchor.current }, selectedInspectionStatuses.length > 0 ? selectedInspectionStatuses.length : null)),
            React.createElement("button", { className: "qmb-control--menu", type: "button", style: buttonStyle, onClick: handleVisitsClick },
                React.createElement("span", null, "Service Visits"),
                React.createElement("span", { style: counterStyle, ref: subAnchor.current }, selectedVisitStatuses.length > 0 ? selectedVisitStatuses.length : null))),
        !disabled && (React.createElement(React.Fragment, null,
            React.createElement("hr", { className: "popup__split" }),
            React.createElement("div", { className: "popup__section" },
                React.createElement("ul", { role: "presentation" },
                    React.createElement("li", null,
                        React.createElement("div", { className: "messageContainer" },
                            React.createElement("button", { type: "button", className: "qmb-control--sm--reset", onClick: params.onReset },
                                React.createElement("i", { className: "fa-light fa-arrow-rotate-left" }),
                                "Reset")))))))));
}
export default StatusTypeSelector;
