import React, { useContext, useState } from 'react';
import { translate } from '@utils/i18n';
import { visitAssigneeIcon, visitAssigneeTitle, visitIcon, visitStatusAndTimeLabel } from '@utils/functions/visit.functions';
import { buildingPath } from '../../../../routes';
import Chip from '../../../../components/Chip/chip';
import RecursiveDetails from './recursive-details';
import { VisitModalContext } from '../VisitModalContext';
import Error from '../../../../components/Error';
function VisitDetails() {
    var _a, _b, _c;
    const namespace = 'features.modals.visit_modal.details';
    const { visit } = useContext(VisitModalContext);
    const [collapsed, setCollapsed] = useState(true);
    const onToggleExpand = () => {
        setCollapsed((old) => !old);
    };
    if (!visit)
        return React.createElement(Error, { error: "Unable to load visit details." });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "dialog__section" },
            React.createElement("ul", { className: "dialog__list--scheduling" },
                React.createElement("li", { className: "dialog__item--title" },
                    React.createElement("a", { href: ((_a = visit.building) === null || _a === void 0 ? void 0 : _a.id) ? buildingPath(visit.building.id) : '#', target: "_blank", rel: "noreferrer" }, (_b = visit.building) === null || _b === void 0 ? void 0 :
                        _b.name,
                        React.createElement("i", { className: "fa-light fa-arrow-up-right-from-square" }))),
                React.createElement("li", { className: "dialog__item" }, (_c = visit.building) === null || _c === void 0 ? void 0 : _c.address),
                React.createElement("li", { className: "dialog__item" },
                    React.createElement(Chip, { icon: visitIcon(visit), label: visitStatusAndTimeLabel(visit) })),
                React.createElement("li", { className: "dialog__item--tech" },
                    React.createElement(Chip, { icon: visitAssigneeIcon(visit), label: visitAssigneeTitle(visit) })))),
        React.createElement("div", { className: "dialog__section" },
            React.createElement("span", { style: {
                    display: 'inline-block',
                    background: '#fff',
                    position: 'absolute',
                    bottom: '1.2rem',
                    right: '1.2rem'
                } },
                React.createElement("button", { type: "button", onClick: onToggleExpand, className: "qmb-control--sm--detail-toggle", style: { position: 'unset', bottom: 0, right: 0 } },
                    collapsed ? translate('expand', { namespace }) : translate('collapse', { namespace }),
                    React.createElement("i", { className: collapsed
                            ? 'fa-light fa-arrow-up-right-and-arrow-down-left-from-center'
                            : 'fa-light fa-arrow-down-left-and-arrow-up-right-to-center' }))),
            React.createElement("div", { className: "dialog__header--section" },
                React.createElement("h2", { className: "dialog__title" }, translate('visit_details', { namespace }))),
            React.createElement("div", { className: `dialog__list details-box ${collapsed ? '--collapsed' : ''}` },
                React.createElement(RecursiveDetails, { details: visit.details, depth: 0 })))));
}
export default React.memo(VisitDetails);
