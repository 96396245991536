const EventClusteringAlgorithm = (events) => {
    if(events.length === 0) return [];

    const sortedEvents = events.sort((a,b) => new Date(a.startTime) - new Date(b.startTime));
    let clusters = []
    let currentCluster = []
    let currentClusterEndTime = null;

    let i = 0;
    do {
        let event = sortedEvents[i];

        if (!event.metadata) event.metadata = {};

        if (currentClusterEndTime == null || new Date(event.startTime) >= new Date(currentClusterEndTime)){
            // Push finished cluster into reserve and start next one
            clusters.push(currentCluster)
            currentCluster = []
            currentClusterEndTime = event.endTime;
        }
        if(new Date(event.startTime) <= new Date(currentClusterEndTime) && new Date(event.endTime) >= new Date(currentClusterEndTime)){
            currentClusterEndTime = event.endTime
        }
        currentCluster.push({...event, metadata: { ...event.metadata } })
        if (i === sortedEvents.length - 1){
            clusters.push(currentCluster)
        }
        i++;
    } while (i < sortedEvents.length)


    clusters.forEach((cluster, index) => {

        let maxConsecutiveRows = 1;
        let currentRow = 1;

        for(let i = 0; i < cluster.length - 1; i++){
            const event = cluster[i];
            const nextEvent = cluster[i+1]
            if (new Date(nextEvent.startTime) <= new Date(event.endTime)){
                currentRow++;
                if (currentRow > maxConsecutiveRows){
                    maxConsecutiveRows = currentRow;
                }
            } else {
                currentRow = 1;
            }
        }

        if (cluster.length === 0) return;


        cluster[0].metadata.cluster = index;
        cluster[0].metadata.subdivision = maxConsecutiveRows;
        cluster[0].metadata.offset = 0;
        cluster[0].metadata.span = 1;

        for(let i = 1; i < cluster.length; i++){

            const prevEvent = cluster[i-1]
            const currEvent = cluster[i]
            const nextEvent = cluster[i+1]

            currEvent.metadata.cluster = index;
            currEvent.metadata.subdivision = maxConsecutiveRows;

            if (prevEvent.metadata.offset + prevEvent.metadata.span === maxConsecutiveRows){

                // find base
                let found = false;
                for (let j = i - 1; j >= 0; j--){
                    const x = cluster[j]
                    if (currEvent.startTime < x.endTime){
                        found = true;
                        currEvent.metadata.offset = (x.metadata.offset + 1) % maxConsecutiveRows;
                        break;
                    }
                }
                if (!found){
                    currEvent.metadata.offset = 0;
                }

            }else{
                currEvent.metadata.offset = prevEvent.metadata.offset +  prevEvent.metadata.span
            }

            if (nextEvent){
                if (nextEvent.startTime > currEvent.endTime){
                    currEvent.metadata.span = maxConsecutiveRows - currEvent.metadata.offset;
                }else{
                    currEvent.metadata.span = 1
                }

            }else{
                if (currEvent.metadata.offset === 0) currEvent.metadata.span = 1;
                else currEvent.metadata.span = maxConsecutiveRows - currEvent.metadata.offset;
            }

        }

    });

    return clusters.reduce((accumulator, cluster) => accumulator.concat(cluster), []);
};

export const debugEventClusteringAlgorithm = () => {
    debugger;


    const eventsGroupedByTech = Object.groupBy(debugData, ({ technicianId }) => technicianId);
    for(const tech in eventsGroupedByTech){
        eventsGroupedByTech[tech] = EventClusteringAlgorithm(eventsGroupedByTech[tech])
        debugger;
    }
    const result = Object.values(eventsGroupedByTech).flatMap(events => events);

    debugger;
}

const debugData =  [
    {
    type: 'Visit',
    id: 5031,
    startTime: '2023-09-28T02:00:00-04:00',
    endTime: '2023-09-28T08:00:00.000-04:00',
    technicianId: 1,
    title: 'Arboleda Apartments',
    subtitle: 'Arboleda Apartments 1550 3rd Ave Walnut Creek CA 94597',
    status: 'complete',
    metadata: {
      cluster: 1,
      subdivision: 1,
      offset: 0,
      span: 1,
      originalStartTime: '2023-09-28T02:00:00-04:00',
      originalEndTime: '2023-09-28T08:00:00.000-04:00'
    }
  },
  {
    type: 'Visit',
    id: 5027,
    startTime: '2023-09-28T12:00:00-04:00',
    endTime: '2023-09-28T16:00:00.000-04:00',
    technicianId: 1,
    title: 'Bill\'s Windsurf Shop',
    subtitle: '12 Ocean Dr. Half Moon Bay CA 94213',
    status: 'pending',
    metadata: {
      cluster: 2,
      subdivision: 1,
      offset: 0,
      span: 1,
      originalStartTime: '2023-09-28T12:00:00-04:00',
      originalEndTime: '2023-09-28T16:00:00.000-04:00'
    }
  },
  {
    type: 'Visit',
    id: 5030,
    startTime: '2023-09-27T21:00:00-04:00',
    endTime: '2023-09-28T03:00:00.000-04:00',
    technicianId: 2,
    title: 'Arboleda Apartments',
    subtitle: 'Arboleda Apartments 1550 3rd Ave Walnut Creek CA 94597',
    status: 'scheduled',
    metadata: {
      cluster: 1,
      subdivision: 1,
      offset: 0,
      span: 1,
      originalStartTime: '2023-09-27T21:00:00-04:00',
      originalEndTime: '2023-09-28T03:00:00.000-04:00'
    }
  },
  {
    type: 'Visit',
    id: 5028,
    startTime: '2023-09-28T04:00:00-04:00',
    endTime: '2023-09-28T09:00:00.000-04:00',
    technicianId: 2,
    title: 'Bill\'s Windsurf Shop',
    subtitle: '12 Ocean Dr. Half Moon Bay CA 94213',
    status: 'scheduled',
    metadata: {
      cluster: 2,
      subdivision: 2,
      offset: 0,
      span: 1,
      originalStartTime: '2023-09-28T04:00:00-04:00',
      originalEndTime: '2023-09-28T09:00:00.000-04:00'
    }
  },
  {
    type: 'Visit',
    id: 5029,
    startTime: '2023-09-28T06:00:00-04:00',
    endTime: '2023-09-28T11:00:00.000-04:00',
    technicianId: 2,
    title: 'Arboleda Apartments',
    subtitle: 'Arboleda Apartments 1550 3rd Ave Walnut Creek CA 94597',
    status: 'pending',
    metadata: {
      cluster: 2,
      subdivision: 2,
      offset: 1,
      span: 1,
      originalStartTime: '2023-09-28T06:00:00-04:00',
      originalEndTime: '2023-09-28T11:00:00.000-04:00'
    }
  },
  {
    type: 'Visit',
    id: 5032,
    startTime: '2023-09-28T12:17:00.000Z',
    endTime: '2023-09-28T15:17:00.000Z',
    technicianId: 2,
    title: 'Arboleda Apartments',
    subtitle: 'Arboleda Apartments 1550 3rd Ave Walnut Creek CA 94597',
    status: 'deleted_by_technician',
    metadata: {
      cluster: 3,
      subdivision: 1,
      offset: 0,
      span: 1,
      originalStartTime: '2023-09-28T12:00:00-04:00',
      originalEndTime: '2023-09-28T15:00:00.000-04:00',
      repositioning: false,
      minuteOffset: 0,
      prevStartTime: '2023-09-28T12:17:00.000Z',
      prevEndTime: '2023-09-28T15:17:00.000Z'
    }
  }
]

export default EventClusteringAlgorithm;
