var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/naming-convention */
import api from '@utils/axios';
import { apiInternalInspectionDocumentsPath, apiInternalInspectionDocumentPath, apiInternalInspectionDocumentTypesPath, apiInternalWorkOrderDocumentPath, apiInternalWorkOrderDocumentsPath, apiInternalWorkOrderDocumentTypesPath, apiInternalInspectionDocumentDeficienciesPath } from '../../../routes';
import { AIDeficiencyReviewStatus } from '../types';
// import { deficiencies as fakeDeficiencies } from '../fakeData';
export function getDocuments(_a) {
    return __awaiter(this, arguments, void 0, function* ({ id, isInspection }) {
        const getPath = isInspection ? apiInternalInspectionDocumentsPath : apiInternalWorkOrderDocumentsPath;
        const { data } = (yield api.get(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        getPath('', {
            work_order_id: id,
            inspection_id: id,
            format: 'json'
        })));
        data.documents = data.documents
            .map((_a) => {
            var doc = __rest(_a, []);
            return Object.assign(Object.assign({}, doc), { deficiencies: (Array.isArray(doc.deficiencies) ? doc.deficiencies : []).map((def) => {
                    var _a;
                    return Object.assign(Object.assign({}, def), { reviewStatus: (_a = def.reviewStatus) !== null && _a !== void 0 ? _a : AIDeficiencyReviewStatus.WaitingReview });
                }) });
        })
            .sort((a, b) => {
            return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
        });
        return data.documents;
    });
}
export function updateDocument(_a) {
    return __awaiter(this, arguments, void 0, function* ({ inspectionId, newDocument }) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        yield api.put(apiInternalInspectionDocumentDeficienciesPath(inspectionId, newDocument.id), {
            document: {
                status: newDocument.status,
                deficiencies: JSON.stringify(newDocument.deficiencies),
                feedback: newDocument.feedback
            }
        });
        return newDocument;
    });
}
export function getDocumentsTypes(_a) {
    return __awaiter(this, arguments, void 0, function* ({ id, isInspection }) {
        const getPath = isInspection ? apiInternalInspectionDocumentTypesPath : apiInternalWorkOrderDocumentTypesPath;
        const { data } = (yield api.get(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        getPath('', {
            work_order_id: id,
            inspection_id: id,
            format: 'json'
        })));
        return data.types;
    });
}
export function deleteDocument(_a) {
    return __awaiter(this, arguments, void 0, function* ({ docId, parentId, isInspection }) {
        const deletePath = isInspection ? apiInternalInspectionDocumentPath : apiInternalWorkOrderDocumentPath;
        const response = yield api.delete(deletePath(parentId, docId));
        // eslint-disable-next-line camelcase
        const responseData = response.data;
        return { id: responseData.id, allowed_send_for_review: responseData.allowed_send_for_review };
    });
}
export function runAI(_a) {
    return __awaiter(this, arguments, void 0, function* ({ docId, inspectionId }) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        yield api.post(apiInternalInspectionDocumentDeficienciesPath(inspectionId, docId));
        return docId;
    });
}
