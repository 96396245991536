import React, { useContext, useMemo } from 'react';
import { blackOrWhiteForeground } from '@utils/colors';
import { assigneeIcon } from '@utils/functions/assignee-functions';
import { CalendarContext } from '../../CalendarContext';
function Visit(params) {
    var _a;
    const dynamicStyle = useMemo(() => {
        var _a;
        return ({
            color: params.highlightStatus ? '#000' : blackOrWhiteForeground((_a = params.visit.assignee.info) === null || _a === void 0 ? void 0 : _a.color)
        });
    }, [params.visit, params.highlightStatus]);
    const { state: { detailed } } = useContext(CalendarContext);
    const typeIcon = useMemo(() => {
        var _a;
        switch ('visitableType' in params.visit ? (_a = params.visit) === null || _a === void 0 ? void 0 : _a.visitableType : params.visit.visitable.type) {
            case "Inspection" /* VisitableType.INSPECTION */:
                return React.createElement("i", { className: "fa-light fa-clipboard-check" });
                break;
            case "WorkOrder" /* VisitableType.SERVICE */:
                return React.createElement("i", { className: "fa-light fa-screwdriver-wrench" });
                break;
            default:
                return React.createElement("i", { className: "fa-light fa-square-question" });
        }
    }, []);
    const rightSideIcon = useMemo(() => {
        if (params.highlightStatus)
            return React.createElement(StatusIcon, { status: params.visit.status });
        return assigneeIcon(params.visit.assignee, 16);
    }, [params.visit.assignee, params.highlightStatus]);
    return (React.createElement("div", { className: `cal__event ${params.highlightStatus ? '--status' : ''} ${detailed ? '--detailed' : ''}`, style: dynamicStyle },
        React.createElement("div", { className: "event__crux" },
            React.createElement("span", { className: "event__type" }, typeIcon),
            React.createElement("span", { className: "event__time" }, params.visit.start.format('hh:mm A'))),
        React.createElement("div", { className: "event__title" }, 'title' in params.visit ? params.visit.title : (_a = params.visit.building) === null || _a === void 0 ? void 0 : _a.name),
        React.createElement("div", { className: "event__indicate" }, rightSideIcon),
        detailed && params.visit.description && React.createElement("div", { className: "event__details" }, params.visit.description)));
}
function StatusIcon({ status }) {
    return (React.createElement("span", { className: "qmb-status", style: { color: status.color } },
        React.createElement("i", { className: status.icon })));
}
export default React.memo(Visit);
