import React, { useContext } from 'react';
import { assigneeIcon, assigneeTitle } from '@utils/functions/assignee-functions';
import { Tooltip } from '@progress/kendo-react-tooltip';
import Chip from '../../../components/Chip/chip';
import { VisitItemContext } from './VisitItemContext';
const namespace = 'features.map_scheduler';
function Visit() {
    var _a, _b;
    const { visit, handleMouseDown, handleMouseUp } = useContext(VisitItemContext);
    return (React.createElement("li", { className: "list__option--visit", onMouseDown: (e) => handleMouseDown(e.nativeEvent), onMouseUp: (e) => handleMouseUp(e.nativeEvent) },
        React.createElement("button", { type: "button" },
            React.createElement("span", { className: "option__header" },
                React.createElement("strong", { className: "option__title" }, (_a = visit.building) === null || _a === void 0 ? void 0 : _a.name),
                React.createElement(Tooltip, { anchorElement: "target", position: "right" },
                    React.createElement("span", { className: "option__type" },
                        React.createElement(TypeIcon, { visit: visit })))),
            React.createElement("span", { className: "option__address" }, (_b = visit.building) === null || _b === void 0 ? void 0 : _b.address),
            React.createElement("span", { className: "option__footer" },
                React.createElement("span", { className: "option__date" },
                    React.createElement("i", { className: visit.status.icon, style: { color: visit.status.color } }),
                    "\u00A0",
                    visit.start.format('M/D/YYYY @ hh:mm a')),
                React.createElement(Chip, { icon: assigneeIcon(visit.assignee, 16), label: assigneeTitle(visit.assignee) })))));
}
function TypeIcon({ visit }) {
    if (visit.visitable.type === "WorkOrder" /* VisitableType.SERVICE */) {
        return React.createElement("i", { className: "fa-light fa-screwdriver-wrench", title: "Work Order" });
    }
    if (visit.visitable.type === "Inspection" /* VisitableType.INSPECTION */) {
        return (React.createElement(React.Fragment, null,
            visit.frequency && React.createElement("small", null, visit.frequency),
            React.createElement("i", { className: "fa-light fa-clipboard-check", title: "Inspection" })));
    }
    return React.createElement("i", { className: "fa-light fa-square-question" });
}
export default Visit;
