import { useMemo, useContext, useRef, useEffect, useState } from 'react';
import { CalendarContext } from '../CalendarContext';
import { actionTypes } from '../reducer';

export default function useEvents() {
  const {
    state: { events, statuses }
  } = useContext(CalendarContext);

  const selectedStatusIds = useMemo(() => Object.keys(statuses).filter((key) => statuses[key]), [statuses]);

  const fullCalendarEventsCacheRef = useRef([]);
  const [rerender, setrerender] = useState(0);

  useEffect(() => {

    // Remove any filtered out events
    const newEventKeys = new Set(events.map((x) => x.key));
    const prevCache = fullCalendarEventsCacheRef.current;
    const newCache = fullCalendarEventsCacheRef.current.filter((cachedEvent) =>
      newEventKeys.has(cachedEvent.key)
    );

    if (newCache.length !== prevCache.length) {
      fullCalendarEventsCacheRef.current = newCache;
      setrerender((prev) => prev + 1);
    }


    events.forEach((event) => {
      const { id } = event;
      const cached = fullCalendarEventsCacheRef.current.find((x) => x.key === event.key);
      const highlightingStatus = selectedStatusIds.includes(event.status?.groupStatus);
      const start = event.start.toDate();
      const end = event.end.toDate();

      // Check if the cached event exists and its computed values are the same
      if (
        cached &&
        cached.highlightingStatus === highlightingStatus &&
        cached?.assignee?.key === event?.assignee?.key &&
        cached?.status?.key === event?.status?.key &&
        cached.start.getTime() === start.getTime() &&
        cached.end.getTime() === end.getTime() &&
        cached.type === (event.isVisit ? 'visit' : event.key?.includes('TimeOff') ? 'timeoff' : 'holiday')
      ) {
        return;
      }

      // Compute a new event object if any of the properties have changed
      let computedEvent;
      if (event?.isVisit === true) {
        computedEvent = {
          id,
          key: event.key,
          type: 'visit',
          data: event,
          start,
          end,
          backgroundColor: highlightingStatus ? `${event.status.color}31` : event.assignee?.info?.color ?? '#fff',
          borderColor: highlightingStatus ? `${event.status.color}31` : event.assignee?.info?.color ?? '#fff',
          editable: true,
          highlightingStatus
        };
      } else if (event?.key?.includes('TimeOff')) {
        computedEvent = {
          id,
          key: event.key,
          type: 'timeoff',
          data: event,
          start,
          end,
          backgroundColor: event.technician?.color,
          borderColor: event.technician?.color,
          editable: false
        };
      } else {
        computedEvent = {
          id,
          key: event.key,
          type: 'holiday',
          data: event,
          start,
          end,
          backgroundColor: '#f5f5f888',
          borderColor: '#f5f5f888',
          editable: false,
          allDay: true,
          display: 'background'
        };
      }

      const index = fullCalendarEventsCacheRef.current.findIndex((x) => x.key === event.key);

      if (index > -1) {
        fullCalendarEventsCacheRef.current[index] = computedEvent;
      } else {
        fullCalendarEventsCacheRef.current.push(computedEvent);
      }
      setrerender((prevState) => prevState + 1);
    });
  }, [events, selectedStatusIds]);

  return { fullCalendarEventsCacheRef, rerender };
}
