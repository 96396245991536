import React, { createContext, useCallback, useMemo, useState } from 'react';
import useGetVisitStatuses from '@hooks/requests/visit-statuses.get.hook';
export const StatusMultiSelectContext = createContext(undefined);
export function StatusMultiSelectProvider(params) {
    const { inspectionStatusParams, visitStatusParams } = useMemo(() => {
        const a = {
            select: 'all',
            filter: {
                type: "Inspection" /* VisitableType.INSPECTION */
            }
        };
        const b = {
            select: 'all',
            filter: {
                type: "WorkOrder" /* VisitableType.SERVICE */
            }
        };
        return { inspectionStatusParams: a, visitStatusParams: b };
    }, []);
    const { data: inspectionStatuses, loading: inspectionStatusesLoading } = useGetVisitStatuses(inspectionStatusParams);
    const { data: visitStatuses, loading: visitStatusesLoading } = useGetVisitStatuses(visitStatusParams);
    // Derived Data
    const initializing = inspectionStatusesLoading || visitStatusesLoading;
    const { selectedInspectionStatuses, defaultInspectionStatuses } = useMemo(() => {
        const selectedSet = new Set(params.selectedInspectionStatusKeys);
        const defaultSet = new Set(params.defaultInspectionStatusKeys);
        return {
            selectedInspectionStatuses: inspectionStatuses.filter((status) => {
                return selectedSet.has(status.key);
            }),
            defaultInspectionStatuses: inspectionStatuses.filter((status) => {
                return defaultSet.has(status.key);
            })
        };
    }, [params.selectedInspectionStatusKeys, inspectionStatuses, params.defaultInspectionStatusKeys]);
    const { selectedVisitStatuses, defaultVisitStatuses } = useMemo(() => {
        const selectedSet = new Set(params.selectedVisitStatusKeys);
        const defaultSet = new Set(params.defaultVisitStatusKeys);
        return {
            selectedVisitStatuses: visitStatuses.filter((status) => {
                return selectedSet.has(status.key);
            }),
            defaultVisitStatuses: visitStatuses.filter((status) => {
                return defaultSet.has(status.key);
            })
        };
    }, [params.selectedVisitStatusKeys, visitStatuses, params.defaultVisitStatusKeys]);
    // State
    const [activeMultiSelector, setActiveMultiSelector] = useState(null);
    const setSelectedInspectionStatuses = useCallback((selections) => {
        params.onInspectionStatusChange(selections.map((x) => x.key));
    }, []);
    const setSelectedVisitStatuses = useCallback((selections) => {
        params.onVisitStatusChange(selections.map((x) => x.key));
    }, []);
    const contextValue = useMemo(() => {
        const value = {
            multiSelectReady: !initializing,
            activeMultiSelector,
            inspectionStatusList: inspectionStatuses,
            visitStatusList: visitStatuses,
            selectedInspectionStatuses,
            selectedVisitStatuses,
            defaultInspectionStatuses,
            defaultVisitStatuses,
            setActiveMultiSelector,
            setSelectedInspectionStatuses,
            setSelectedVisitStatuses
        };
        return value;
    }, [
        initializing,
        inspectionStatuses,
        visitStatuses,
        selectedInspectionStatuses,
        selectedVisitStatuses,
        defaultInspectionStatuses,
        defaultVisitStatuses,
        activeMultiSelector
    ]);
    return React.createElement(StatusMultiSelectContext.Provider, { value: contextValue }, params.children);
}
