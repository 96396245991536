var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import { Loader, LoaderSizes } from '../Loader/Loader';
export default function LoadableButton(_a) {
    var { loading } = _a, props = __rest(_a, ["loading"]);
    return (React.createElement(Button, Object.assign({}, props, { disabled: loading }), loading ? React.createElement(Loader, { size: LoaderSizes.small }) : props.children));
}
LoadableButton.propTypes = {
    children: PropTypes.node.isRequired,
    loading: PropTypes.bool.isRequired
};
