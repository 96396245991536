import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import I18n, { translate } from '@utils/i18n';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { DateTime } from 'luxon';
import {
  status,
  decrementActiveDay,
  incrementActiveDay,
  setActiveDayToToday,
  setStatus,
  addEvent,
  pushToSaveQueue,
  reassignEvent,
  unassignEvent,
  setActiveDay
} from '../Redux/schedulerSlice';
import { status as draggingStatuses, types, types as draggingTypes } from '../Redux/draggingSlice';
import { replaceVisit } from '../Redux/visitsSlice';
import { SchedulerContext } from '../Contexts/SchedulerContext';
import { TimeZoneContext } from '../../../contexts/timezone-context';
import { GlobalDragContext } from '../Contexts/GlobalDragContext';
import { RearrangeContext } from '../Contexts/RearrangeContext';
import { ScheduleAndDispatchContext } from "../Contexts/ScheduleAndDispatchContext";

const namespace = 'features.map_scheduler';

function ActionBar() {
  const dispatch = useDispatch();
  const schedulerStatus = useSelector((state) => state.scheduler.status);
  const globalDragStatus = useSelector((state) => state.dragging.status);
  const globalDragType = useSelector((state) => state.dragging.type);
  const draggingData = useSelector((state) => state.dragging.data);

  const { loading } = useContext(SchedulerContext);
  const { isDragging } = useContext(GlobalDragContext);
  const { isMoving } = useContext(RearrangeContext);

  const renderAction = () => {
    if (loading) return <Spinner title={I18n.t('generic.loading')} />;

    if (isDragging || isMoving) {
      return <Unassign />;
    }

    switch (schedulerStatus) {
      case status.LOADING:
        return <Spinner title={I18n.t('generic.loading')} />;
      case status.SAVING:
        return <Spinner title={schedulerStatus} />;
      case status.READY:
      default:
        return <PaginationActions />;
    }
  };

  const conditionalClasses = () => {
    let result = '';
    if (isDragging || isMoving) result += '--unassign';
    return result;
  };

  const HandleMouseUp = (e) => {
    if (globalDragStatus === draggingStatuses.DRAGGING && globalDragType === types.EVENT) {
      const event = JSON.parse(JSON.stringify(draggingData));
      event.technicianId = null;
      event.technician = null;
      dispatch(unassignEvent({ event }));
      dispatch(replaceVisit(event));
    }
  };

  return (
    <div id="scheduler-header-actions" className={`schedule__actions${conditionalClasses()}`} onMouseUp={HandleMouseUp}>
      <h2 className="pane__title">{translate('scheduler.title', { namespace })}</h2>
      {renderAction()}
    </div>
  );
}

function Spinner({ title }) {
  return (
    <div className="qmb-loading--32--alt" data-title={title}>
      <svg role="img">
        <use href="/map.svg#load-spinner" />
      </svg>
    </div>
  );
}

function PaginationActions() {
  const dispatch = useDispatch();
  const activeDay = DateTime.fromISO(useSelector((state) => state.scheduler.activeDay));
  const [show, setShow] = useState(false);

  const { activeDate, incrementCurrentDate, decrementCurrentDate, setCurrentDate } = useContext(SchedulerContext);
  const { timezone } = useContext(TimeZoneContext);

  function CustomInput(props) {
    const formattedDate = DateTime.fromJSDate(props.value)
      .setZone(timezone)
      .setLocale(I18n.locale)
      .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
    return (
      <span
        className="qmb-control schedule__day"
        onClick={() => setShow(!show)}
        onDoubleClick={() => dispatch(setActiveDayToToday())}>
        {formattedDate}
      </span>
    );
  }

  return (
    <div className="schedule__controls">
      <DatePicker
        onBlur={() => setShow(false)}
        className="border-none"
        value={activeDate.toDate()}
        dateInput={CustomInput}
        toggleButton={() => null}
        show={show}
        onChange={(e) => {
          setCurrentDate(e.value);
          setShow(false);
        }}
      />

      <button className="qmb-control--icon" onClick={() => decrementCurrentDate()}>
        <i className="fa-light fa-angle-left" />
      </button>

      <button className="qmb-control--icon" onClick={() => incrementCurrentDate()}>
        <i className="fa-light fa-angle-right" />
      </button>
    </div>
  );
}

function Unassign() {

  const { setActiveSchedulerEvent } = useContext(SchedulerContext);
  const { activeGlobalDragItem, changeCancelEvent, stopDragging } = useContext(GlobalDragContext);
  const { stopRearranging } = useContext(RearrangeContext);
  const { saveVisit } = useContext(ScheduleAndDispatchContext);

  const handleMouseUp = useCallback(() => {
    changeCancelEvent(() => {});

    const visit = {
      ...activeGlobalDragItem.current,
      assignee: {
        type: activeGlobalDragItem.current.assignee.type,
        info: null,
        key: `${activeGlobalDragItem.current.assignee.type}-unassigned`
      }
    }

    setActiveSchedulerEvent(undefined);
    stopRearranging();
    stopDragging();
    saveVisit(visit);
  }, []);

  return (
    <div className="schedule__controls" onMouseUp={handleMouseUp}>
      <span>Unassign</span>
      <i className="fa-solid fa-recycle" />
    </div>
  );
}

function Error() {
  const dispatch = useDispatch();

  return (
    <>
      <span style={{ color: 'red' }}>An Error Occurred.</span>
      <span style={{ cursor: 'pointer', color: 'red' }} onClick={(e) => dispatch(setStatus(status.READY))}>
        <i className="fa-solid fa-circle-exclamation" />
        <span>Dismiss</span>
      </span>
    </>
  );
}

export default ActionBar;
