/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import luxonPlugin from '@fullcalendar/luxon3';
import adaptivePlugin from '@fullcalendar/adaptive';
import I18n from '@utils/i18n';
import toast, { Toaster } from 'react-hot-toast';
import dayjs from 'dayjs';
import Header from './nested/Header';
import CellHeader from './nested/CellHeader';
import { CalendarContext, CalendarProvider } from './CalendarContext';
import useEvents from './hooks/useEvents';
import CalendarEvent from './nested/TsxUpdated/CalendarEvent.tsx';
import { TimeZoneContextProvider } from '../../contexts/timezone-context.tsx';
import { ModalContext, ModalContextProvider } from '../../contexts/modal-context.tsx';
import { TenantContextProvider } from "../../contexts/tenant-context";


const CalendarComp = React.memo(({ view, onDateRangeChange, timeZone }) => {
  const { openVisitModal, openTimeOffModal } = useContext(ModalContext);
  const {
    actions: { saveVisit },
    state: { detailed }
  } = useContext(CalendarContext);

  const calendarRef = useRef(null);

  const { fullCalendarEventsCacheRef, rerender } = useEvents();

  const onChangeView = useCallback((e) => {
    onDateRangeChange(e);
  }, []);

  const handleEventClick = useCallback((e) => {
    if (e.event.extendedProps.type === 'visit') {
      openVisitModal(e.event.extendedProps.data.id);
    } else if (e.event.extendedProps.type === 'timeoff') {
      openTimeOffModal(e.event.extendedProps.data.id);
    }
  }, []);

  const handleEventChange = useCallback((e) => {
    saveVisit({ ...e.event.extendedProps.data, start: dayjs(e.event.start), end: dayjs(e.event.end) });
  }, []);

  const memoData = useMemo(() => {
    return [...fullCalendarEventsCacheRef.current];
  }, [rerender]);

  useEffect(() => {
    calendarRef.current.requestResize();
  }, [detailed]);

  return (
    <FullCalendar
      // TODO: move in env vars
      schedulerLicenseKey="0879361028-fcs-1723636422"
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, luxonPlugin, adaptivePlugin]}
      ref={calendarRef}
      headerToolbar={{
        left: 'title',
        right: 'prev,today,next dayGridMonth,timeGridWeek,timeGridDay'
      }}
      eventContent={EventWrapper}
      dayCellContent={CellHeaderWrapper}
      initialView={view}
      events={memoData}
      datesSet={onChangeView}
      eventDisplay="block"
      eventStartEditable
      selectable={false}
      eventResizableFromStart={false}
      eventClick={handleEventClick}
      eventChange={handleEventChange}
      contentHeight="auto"
      dragScroll="true"
      showNonCurrentDates={false}
      timeZone={timeZone}
    />
  );
});

CalendarComp.displayName = 'CalendarComp';

CalendarComp.propTypes = {
  view: PropTypes.string.isRequired,
  onDateRangeChange: PropTypes.func.isRequired,
  timeZone: PropTypes.string.isRequired
};

function Calendar() {
  const {
    timeZone,
    state: { errorMessage, view, loading },
    actions: { onDateRangeChange }
  } = useContext(CalendarContext);

  const { activeModal } = useContext(ModalContext);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, {
        position: 'bottom-right',
        autoClose: 3000,
        closeOnClick: true
      });
    }
  }, [errorMessage]);

  return (
    <>
      {activeModal}
      <Toaster position="bottom-right" />
      <Header />
      {loading && (
        <div className="qmb-loading--128--page" data-title={I18n.t('generic.loading')}>
          <svg role="img">
            <use href="/map.svg#load-spinner" />
          </svg>
        </div>
      )}
      <CalendarComp view={view} onDateRangeChange={onDateRangeChange} timeZone={timeZone} />
    </>
  );
}

function EventWrapper(fcProps) {
  return <CalendarEvent fcProps={fcProps} />;
}
function CellHeaderWrapper(fcProps) {
  return <CellHeader fcProps={fcProps} />;
}

function CalendarWrapper(props) {
  return (
    // eslint-disable-next-line react/prop-types
    <TimeZoneContextProvider initialTimezone={props.timeZone}>
      <TenantContextProvider>
        <ModalContextProvider>
          <CalendarProvider {...props}>
            <Calendar />
          </CalendarProvider>
        </ModalContextProvider>
      </TenantContextProvider>
    </TimeZoneContextProvider>
  );
}

export default CalendarWrapper;
