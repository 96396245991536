import React, { createContext, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import useVisitPatch from '@hooks/requests/visit-patch-hook';
import apiClient from '@utils/api-client';
import useTimeOffProcessor from '@hooks/processors/use-time-off-processor';
import useHolidayProcessor from '@hooks/processors/use-holiday-processor-hook';
import { saveCacheToLocalStorage } from '../../helpers/localStorage';
import { actionTypes, reducer } from './reducer';
import { getInitialData, INITIAL_FILTER_STATE } from './helpers/initialData';
import useVisitProcessor from '../../hooks/processors/use-visit-processor.ts';
import { ModalContext } from '../../contexts/modal-context.tsx';
import { apiInternalHolidaysPath, apiInternalTimeOffsPath, apiInternalVisitsPath } from '../../routes';

import { setFilterValue } from "../MapScheduler/Redux/visitsSlice";


const CACHE_KEY = 'kendo/calendar';

export const CalendarContext = createContext({
  state: {},
  actions: {}
});

export function CalendarProvider({
  assetsForSelect,
  territoriesForSelect,
  inspectionTypesForSelect,
  inspectionStatusesForSelect,
  visitStatusesForSelect,
  techniciansForSelect,
  permissions,
  timeZone,
  children
}) {
  const [state, dispatch] = useReducer(
    reducer,
    { technicians: techniciansForSelect, cacheKey: CACHE_KEY },
    getInitialData
  );

  const { range, unselectedTechs, statuses, filters, detailed, selectedTechnicianIds, selectedSubcontractorIds } =
    state;

  const visitStatusesForHighlight = useMemo(
    () => visitStatusesForSelect.filter((status) => !['deleted_by_technician', 'cancelled'].includes(status.value)),
    []
  );

  const { modifiedEventWatch } = useContext(ModalContext);
  const { save: saveVisit, result: savedVisit } = useVisitPatch();
  useEffect(() => {
    if (modifiedEventWatch) {
      dispatch({ type: actionTypes.DATA_CHANGED, event: { ...modifiedEventWatch, isVisit: true } });
    }
  }, [modifiedEventWatch]);

  useEffect(() => {
    if (savedVisit) {
      dispatch({ type: actionTypes.DATA_CHANGED, event: { ...savedVisit, isVisit: true } });
    }
  }, [savedVisit]);

  useEffect(() => {
    if (!range?.startStr) return;

    fetchEvents({ range, filters, selectedTechnicianIds, selectedSubcontractorIds }).then(({ data }) =>
      dispatch({ type: actionTypes.DATA_LOADED, data })
    );
  }, [range, filters, selectedTechnicianIds, selectedSubcontractorIds]);

  useEffect(() => {
    saveCacheToLocalStorage(CACHE_KEY, {
      filters,
      detailed,
      statuses,
      unselectedTechs,
      selectedTechnicianIds,
      selectedSubcontractorIds,
      view: range?.view?.type
    });
  }, [
    filters,
    detailed,
    statuses,
    unselectedTechs,
    selectedTechnicianIds,
    selectedSubcontractorIds,
    range?.view?.type
  ]);

  const onDateRangeChange = (event) => {
    dispatch({ type: actionTypes.DATE_CHANGED, range: event });
  };

  const reloadEvents = () => {
    dispatch({ type: actionTypes.LOADING_CHANGED, loading: true });
    fetchEvents({ range, filters }).then(({ data }) => dispatch({ type: actionTypes.DATA_LOADED, data }));
  };

  const onToggleStatus = (status) => {
    dispatch({ type: actionTypes.STATUS_TOGGLED, field: status });
  };

  const toggleSelectAllStatuses = () => {
    dispatch({ type: actionTypes.SELECT_ALL_STATUSES, visitStatusesForHighlight, inspectionStatusesForSelect });
  };

  const toggleUnselectAllStatuses = () => {
    dispatch({ type: actionTypes.UNSELECT_ALL_STATUSES });
  };

  const toggleUnselectAllInspectionStatuses = () => {
    dispatch({ type: actionTypes.UNSELECT_ALL_INSPECTION_STATUSES });
  };

  const toggleUnselectAllVisitStatuses = () => {
    dispatch({ type: actionTypes.UNSELECT_ALL_VISIT_STATUSES });
  };

  const toggleSelectAllTechs = () => {
    dispatch({ type: actionTypes.SELECT_ALL_TECHS });
  };

  const toggleUnselectAllTechs = () => {
    dispatch({ type: actionTypes.UNSELECT_ALL_TECHS });
  };

  const setTechnicians = (ids) => {
    dispatch({ type: actionTypes.SET_TECHNICIANS, ids });
  };

  const setSubcontractors = (ids) => {
    dispatch({ type: actionTypes.SET_SUBCONTRACTORS, ids });
  };

  const onToggleTech = (id) => {
    return (e) => {
      dispatch({ type: actionTypes.TECH_TOGGLED, checked: e.target.checked, id });
    };
  };

  const onChangeView = () => {
    dispatch({ type: actionTypes.DETAILED_TOGGLED });
  };

  const onResetFilters = () => {
    dispatch({
      type: actionTypes.FILTER_CHANGED,
      filters: { active: filters.active, values: INITIAL_FILTER_STATE.values }
    });
  };

  const { processVisit } = useVisitProcessor();
  const { processTimeOff } = useTimeOffProcessor();
  const { processHoliday } = useHolidayProcessor();
  const abortControllerRef = useRef();

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const fetchEvents = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const { signal } = abortController;

    const visitParams = {
      select: 'all',
      view: 'minimal',
      filter: {
        type:
          filters?.values?.type === ''
            ? undefined
            : [filters?.values?.type === 'Visit' ? 'WorkOrder' : filters?.values?.type],
        range: {
          start: range.startStr,
          end: range.endStr
        },
        buildingIds: filters?.values?.building,
        assets: filters?.values?.asset,
        teamIds: filters?.values?.technicianTeam,
        technicianIds: selectedTechnicianIds.map((id) => (id === null ? 'unassigned' : id)),
        subcontractorIds: selectedSubcontractorIds.map((id) => (id === null ? 'unassigned' : id)),
        territoryIds: filters?.values?.territory,
        tagIds: filters?.values?.tags,
        inspectionTypeIds: filters?.values?.inspectionType,
        inspectionStatuses: filters?.values?.inspectionStatusKeys ?? [],
        visitStatuses: filters?.values?.visitStatusKeys ?? [],
        accountIds: filters?.values?.account
      }
    };

    const timeOffParams = {
      select: 'all',
      filter: {
        technicianIds: selectedTechnicianIds.map((id) => (id === null ? 'unassigned' : id)),
        range: {
          start: range.startStr,
          end: range.endStr
        }
      }
    };

    const holidayParams = {
      select: 'all',
      filter: {
        range: {
          start: range.startStr,
          end: range.endStr
        }
      }
    };

    return Promise.all([
      apiClient.get(apiInternalVisitsPath(), {
        params: visitParams,
        signal
      }),
      apiClient.get(apiInternalTimeOffsPath(), {
        params: timeOffParams,
        signal
      }),
      apiClient.get(apiInternalHolidaysPath(), {
        params: holidayParams,
        signal
      })
    ]).then(([visitsResponse, timeOffsResponse, holidaysResponse]) => {
      const visits = visitsResponse.data.data;
      const timeOffs = timeOffsResponse.data.data;
      const holidays = holidaysResponse.data.data;

      const combinedEvents = [
        ...visits.map((visit) => ({ ...processVisit(visit), isVisit: true })),
        ...timeOffs.map((timeOff) => processTimeOff(timeOff)),
        ...holidays.map((holiday) => processHoliday(holiday))
      ];

      return { data: { events: combinedEvents } };
    });
  };

  const onChangeSearchFilter = (fieldName) => {
    return (e) => {
      dispatch({ type: actionTypes.FILTER_CHANGED, value: e.sender.value(), field: fieldName });
    };
  };

  const handleMultiSelectFilterChange = (fieldName) => {
    return (value) => {
      dispatch({ type: actionTypes.FILTER_CHANGED, value, field: fieldName });
    };
  };

  const onChangeReactFilter = (fieldName) => {
    return (value) => {
      dispatch({ type: actionTypes.FILTER_CHANGED, value, field: fieldName });
    };
  };

  const onToggleFilter = (fieldName) => {
    return () => {
      dispatch({ type: actionTypes.FILTER_TOGGLED, field: fieldName });
    };
  };

  const onTypeFilterChange = () => {
    return (value) => {
      dispatch({ type: actionTypes.FILTER_CHANGED, value, field: 'type' });
      dispatch({ type: actionTypes.FILTER_CHANGED, value: '', field: 'inspectionStatus' });
      dispatch({ type: actionTypes.FILTER_CHANGED, value: '', field: 'inspectionType' });
      dispatch({ type: actionTypes.FILTER_CHANGED, value: '', field: 'visitStatus' });
    };
  };

  const onTypeFilterToggle = () => {
    return () => {
      dispatch({ type: actionTypes.FILTER_TOGGLED, field: 'type' });
      dispatch({ type: actionTypes.FILTER_CHANGED, value: '', field: 'inspectionStatus' });
      dispatch({ type: actionTypes.FILTER_CHANGED, value: '', field: 'inspectionType' });
      dispatch({ type: actionTypes.FILTER_CHANGED, value: '', field: 'visitStatus' });
    };
  };

  const contextValue = useMemo(() => {
    return {
      assetsForSelect,
      territoriesForSelect,
      inspectionTypesForSelect,
      inspectionStatusesForSelect,
      visitStatusesForSelect,
      visitStatusesForHighlight,
      techniciansForSelect,
      permissions,
      timeZone,
      state,
      actions: {
        onDateRangeChange,
        onToggleStatus,
        toggleSelectAllStatuses,
        toggleUnselectAllStatuses,
        toggleUnselectAllInspectionStatuses,
        toggleUnselectAllVisitStatuses,
        toggleSelectAllTechs,
        toggleUnselectAllTechs,
        onToggleTech,
        setTechnicians,
        setSubcontractors,
        onChangeView,
        onResetFilters,
        onChangeSearchFilter,
        handleMultiSelectFilterChange,
        onChangeReactFilter,
        onToggleFilter,
        onTypeFilterChange,
        onTypeFilterToggle,
        reloadEvents,
        saveVisit
      }
    };
  }, [state, dispatch]);

  return <CalendarContext.Provider value={contextValue}>{children}</CalendarContext.Provider>;
}

CalendarProvider.propTypes = {
  assetsForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  territoriesForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  inspectionTypesForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  inspectionStatusesForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  visitStatusesForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  techniciansForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  permissions: PropTypes.shape({
    settings: PropTypes.bool,
    techs: PropTypes.bool,
    statuses: PropTypes.bool,
    hasService: PropTypes.bool
  }).isRequired,
  timeZone: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired
};

CalendarProvider.defaultProps = {
  assetsForSelect: [],
  territoriesForSelect: [],
  inspectionTypesForSelect: [],
  inspectionStatusesForSelect: [],
  visitStatusesForSelect: [],
  techniciansForSelect: []
};
