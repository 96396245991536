import { StatusMultiSelectContext, StatusMultiSelectProvider } from '@components/Multiselects/StatusMultiSelect/StatusMultiSelectContext';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Loader, LoaderSizes } from '@components/Loader/Loader';
import { Popup } from '@progress/kendo-react-popup';
import StatusTypeSelector from '@components/Multiselects/StatusMultiSelect/StatusTypeSelector';
import StatusMultiSelectList from '@components/Multiselects/StatusMultiSelect/StatusMultiSelectList';
import { TenantContext } from '../../../contexts/tenant-context';
function StatusMultiSelect() {
    var _a, _b;
    const { multiSelectReady, selectedInspectionStatuses, selectedVisitStatuses, inspectionStatusList, visitStatusList, activeMultiSelector, defaultInspectionStatuses, defaultVisitStatuses, setActiveMultiSelector, setSelectedInspectionStatuses, setSelectedVisitStatuses } = useContext(StatusMultiSelectContext);
    const { tenant } = useContext(TenantContext);
    const rootAnchor = useRef(null);
    const popup = useRef(null);
    const subPopup = useRef(null);
    const [open, setOpen] = useState(false);
    const [isSubanchorReady, setIsSubanchorReady] = useState(false);
    useEffect(() => {
        if (open && popup.current && activeMultiSelector !== null) {
            setIsSubanchorReady(true);
        }
        else {
            setIsSubanchorReady(false);
        }
    }, [open, popup.current, activeMultiSelector]);
    const handleBodyClick = useCallback((e) => {
        var _a, _b, _c;
        if (popup.current && ((_a = popup.current._popup) === null || _a === void 0 ? void 0 : _a.contains(e.target)))
            return;
        if (rootAnchor.current && ((_b = rootAnchor.current) === null || _b === void 0 ? void 0 : _b.contains(e.target)))
            return;
        if (subPopup.current && ((_c = subPopup.current._popup) === null || _c === void 0 ? void 0 : _c.contains(e.target)))
            return;
        setActiveMultiSelector(null);
        setOpen(false);
    }, []);
    useEffect(() => {
        document.body.addEventListener('click', handleBodyClick);
        return () => {
            document.body.removeEventListener('click', handleBodyClick);
        };
    }, []);
    const content = useMemo(() => {
        var _a, _b;
        if (!multiSelectReady)
            return React.createElement(Loader, { size: LoaderSizes.small });
        const newSelectedServiceVisits = ((_a = tenant === null || tenant === void 0 ? void 0 : tenant.flags) === null || _a === void 0 ? void 0 : _a.hasService) ? selectedVisitStatuses : [];
        const totalSelections = selectedInspectionStatuses.length + (((_b = tenant === null || tenant === void 0 ? void 0 : tenant.flags) === null || _b === void 0 ? void 0 : _b.hasService) ? selectedVisitStatuses.length : 0);
        const inspectionStatusPreviews = selectedInspectionStatuses.slice(0, 5).map((status) => {
            return (React.createElement("span", { className: "event__status", key: `multiselect-preview-inspection-status-${status.key}` },
                React.createElement("span", { className: " qmb-status" },
                    React.createElement("i", { title: status.label, className: status.icon, style: { color: status.color } }))));
        });
        const visitStatusPreviews = newSelectedServiceVisits.slice(0, 5).map((status) => {
            return (React.createElement("span", { className: "event__status", key: `multiselect-preview-visit-status-${status.key}` },
                React.createElement("span", { className: " qmb-status" },
                    React.createElement("i", { title: status.label, className: status.icon, style: { color: status.color } }))));
        });
        const overflow = totalSelections > 5 ? (React.createElement("span", { key: "status-multiselect-overflow", className: "control__count" },
            "+",
            totalSelections - 5)) : null;
        return (React.createElement("span", { key: "multiselect-preview-content-icons", className: "control__collection" }, [...inspectionStatusPreviews, ...visitStatusPreviews, overflow]));
    }, [multiSelectReady, selectedVisitStatuses, selectedInspectionStatuses, tenant]);
    const onReset = useCallback(() => {
        setSelectedInspectionStatuses(defaultInspectionStatuses);
        setSelectedVisitStatuses(defaultVisitStatuses);
        setOpen(false);
    }, [defaultInspectionStatuses, defaultVisitStatuses]);
    const activeMultiSelectorComponent = useMemo(() => {
        if (activeMultiSelector === "Inspection" /* VisitableType.INSPECTION */)
            return (React.createElement(StatusMultiSelectList, { onChange: setSelectedInspectionStatuses, selectedStatuses: selectedInspectionStatuses, statusList: inspectionStatusList, searchPlaceholder: "Search Inspection Statuses", defaultOptions: defaultInspectionStatuses }));
        if (activeMultiSelector === "WorkOrder" /* VisitableType.SERVICE */)
            return (React.createElement(StatusMultiSelectList, { onChange: setSelectedVisitStatuses, selectedStatuses: selectedVisitStatuses, statusList: visitStatusList, searchPlaceholder: "Search Service Visit Statuses", defaultOptions: defaultVisitStatuses }));
        return null;
    }, [
        activeMultiSelector,
        selectedInspectionStatuses,
        inspectionStatusList,
        selectedVisitStatuses,
        visitStatusList,
        defaultInspectionStatuses,
        defaultVisitStatuses
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement(React.Fragment, null,
            React.createElement("label", { className: "qmb-label", htmlFor: "statusButton" }, "Status"),
            React.createElement("button", { name: "statusButton", className: "filter__value", type: "button", ref: rootAnchor, onClick: () => {
                    setOpen(!open);
                    setActiveMultiSelector(null);
                } }, content)),
        React.createElement(Popup, { anchor: rootAnchor.current, show: open, ref: popup, id: "status-parent-popup" },
            ((_a = tenant === null || tenant === void 0 ? void 0 : tenant.flags) === null || _a === void 0 ? void 0 : _a.hasService) && (React.createElement(React.Fragment, null,
                React.createElement(StatusTypeSelector, { onReset: onReset }),
                React.createElement(Popup, { ref: subPopup, anchor: document.getElementById('status-parent-popup'), show: activeMultiSelector !== null, anchorAlign: { horizontal: 'right', vertical: 'top' }, popupAlign: { horizontal: 'left', vertical: 'top' } }, activeMultiSelectorComponent))),
            !((_b = tenant === null || tenant === void 0 ? void 0 : tenant.flags) === null || _b === void 0 ? void 0 : _b.hasService) && (React.createElement(StatusMultiSelectList, { onChange: setSelectedInspectionStatuses, selectedStatuses: selectedInspectionStatuses, statusList: inspectionStatusList, searchPlaceholder: "Search Inspection Statuses", defaultOptions: defaultInspectionStatuses })))));
}
function StatusMultiSelectWrapper(params) {
    return (React.createElement(StatusMultiSelectProvider, { onInspectionStatusChange: params.onInspectionStatusChange, onVisitStatusChange: params.onVisitStatusChange, selectedInspectionStatusKeys: params.selectedInspectionStatusKeys, selectedVisitStatusKeys: params.selectedVisitStatusKeys, defaultInspectionStatusKeys: params.defaultInspectionStatusKeys, defaultVisitStatusKeys: params.defaultVisitStatusKeys },
        React.createElement(StatusMultiSelect, null)));
}
export default StatusMultiSelectWrapper;
